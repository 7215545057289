import React, {Component} from 'react';
import {
    MachineRegistration,
    SelectOffer,
    CustomerRegistration,
} from '../index';
import {Link} from "react-router-dom";
import './Step.css';

class Step extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // this.toggleSteps();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if(prevProps.currentStep !== this.props.currentStep)
            // this.toggleSteps();
    }

    toggleSteps() {
        const step = document.querySelector('.step.-active[data-step-no="' + this.props.stepNo +'"]');

        document.querySelectorAll('.steps').forEach(p => {
            if(p.getAttribute('data-step-no') === this.props.currentStep)
                return;
            p.classList.remove('-active');
            p.classList.remove('-opened');
            p.querySelector('.step__body').style.maxHeight = null;
        })

        if(step !== null) {
            let stepBody = step.querySelector('.step__body');
            let bodyHeight = stepBody.scrollHeight;
            stepBody.style.maxHeight = bodyHeight + 'px';

            setTimeout(() => {
                step.classList.add('-opened');
            }, 200);
        }

        // console.log('step() ...')
    }

    render() {
        let circleContent = this.props.stepNo;
        if(this.props.currentStep > this.props.stepNo) {
            circleContent = <img src={require('../../assets/images/icons/checked.svg')} alt="-"/>;
        }

        let circleClasses = 'step__circle';
        if(this.props.currentStep >= this.props.stepNo)
            circleClasses += ' -active';

        let stepClasses = 'step';
        if(this.props.currentStep === this.props.stepNo) {
            stepClasses += ' -active';
        }

        return (
            <div className={stepClasses} data-step-no={this.props.stepNo}>
                <div className="step__line">
                    <button className={circleClasses}
                            onClick={() => this.props.nextStep(this.props.stepNo)}
                            disabled={false}
                    >
                        {circleContent}
                    </button>
                    <div className="step__lineHr"/>
                </div>
                <div className="step__rightCol">
                    <button className="step__header"
                            onClick={() => this.props.nextStep(this.props.stepNo)}
                            disabled={false}
                    >
                        <h2 className="step__title">{this.props.label}</h2>
                    </button>
                    <div className="step__body">
                        <div className="step__content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
            // <div className="step">
            //     <div className="step__line">
            //         <div className={circleClasses}>{circleContent}</div>
            //         <div className="step__lineHr"/>
            //     </div>
            //     {step}
            // </div>
        )
    }
}

export default Step;
