import React from "react";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { persistStore, persistReducer, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const initialState = {
    promotion: false,
    config: false,
    lang: 'de',
    id_shop_gear: false,
    gearName: '',
    machine: {},
    logs: {
        serialNumber: [],
        proofOfPurchaseManual: {
            date: [],
            tradePartner: [],
            retailerStore: [],
            invoiceNumber: [],
            invoiceTotalValue: [],
        },
        proofOfPurchaseOcr: [],
    },

    purchase: false,
    cart: {
        discounts: [],
        items: [],
        capsuleCount: 0
    },
    cartSum: '',
    step: 1,
    allProducts: {},
    autoAdded:[],
    customer: false,
    lastOrderTotal: 0,
    currency: {},

    lastValidStep: 1,
    csAuthorization: false,
    csGear: false,
    sessionUuid: null,
}

const SET_PROMO = 'setPromo';

const END_PROMO = 'endPromo';

const SET_LANG = 'setLang';

const GET_IN_TOUCH_POPUP = 'getInTouchPopup';

const SHOP_GEAR = 'shopGear';

const PURCHASE = 'purchase';

const CART = 'cart';

const STEP = 'step';

const MACHINE = 'machine';

const ALL_PRODUCTS = 'allProducts';

const CUSTOMER = 'customer';

const LAST_ORDER_TOTAL = 'lastOrderTotal';

const SET_OCR_LOGS = 'setOcrLogs';

const SET_CURRENCY = 'setCurrency';

const SET_LAST_VALID_STEP = 'setLastValidStep';

const SET_CS_AUTHORIZATION = 'setCsAuthorization';
const SET_CS_GEAR = 'setCsGear';

const SET_CONFIGURATION = 'setConfiguration';

const CLEAR_STORE = 'clearStore';


const reducer = (state = initialState, action) => {
    // console.log('action', action);
    switch (action.type) {
        case SET_PROMO:
            return {
                ...initialState,
                promotion: action.promotion,
                config: action.config,
                sessionUuid: action.sessionUuid,
                lang: action.lang
            }
        case SET_LANG:
            return {
                ...state,
                lang: action.lang
            }
        case GET_IN_TOUCH_POPUP:
            return {
                ...state,
                getInTouchPopup: action.getInTouchPopup
            }
        case SHOP_GEAR:
            return {
                ...state,
                id_shop_gear: action.id_shop_gear,
                gearName: action.gearName
            }
        case MACHINE:
            return {
                ...state,
                machine: action.machine
            }

        case PURCHASE:
            return {
                ...state,
                purchase: action.purchase
            }

        case REHYDRATE:
            if(action.payload && action.payload.cartSum) {
                return {
                    ...state,
                    cart: JSON.parse(action.payload.cartSum)
                }
            }else{
                return state;
            }


        case CART:
            return {
                ...state,
                cart: action.cart,
                cartSum: action.cartSum
            }

        case STEP:
            return {
                ...state,
                step: action.step
            }

        case ALL_PRODUCTS:
            return {
                ...state,
                allProducts: action.allProducts,
                autoAdded: action.autoAdded
            }

        case CUSTOMER:
            return {
                ...state,
                customer: action.customer
            }

        case LAST_ORDER_TOTAL:
            return {
                ...state,
                lastOrderTotal: action.lastOrderTotal
            }

        case END_PROMO:
            return {
                ...state,
                promotion: action.promotion
            }

        case SET_OCR_LOGS:
            return {
                ...state,
                ocrLogs: action.ocrLogs
            }

        case SET_CURRENCY:
            return {
                ...state,
                currency: action.currency
            }

        case SET_LAST_VALID_STEP:
            return {
                ...state,
                lastValidStep: action.lastValidStep,
            }

        case SET_CS_AUTHORIZATION:
            return {
                ...state,
                csAuthorization: action.csAuthorization,
            }

        case SET_CS_GEAR:
            return {
                ...state,
                csGear: action.csGear,
            }

        case SET_CONFIGURATION:
            return {
                ...state,
                config: action.config,
                // lang: action.lang,
            }

        case CLEAR_STORE:
            return {
                ...initialState,
                config: state.config,
                lang: state.lang,
            }

        default:
            return state;
    }
}


// const store = createStore(reducer, applyMiddleware(thunk));
// export default store;


const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = createStore(persistedReducer)
export const persistor = persistStore(store)


export const setPromo = (promotion, config, uuid, lang = null) => {
    let configObj = {};
    for(let c in config) {
        let item = config[c];
        configObj[item.name] = JSON.parse(item.value);
    }

    if(!lang) {
        lang = 'en';
        if(configObj.multilingual_front_office.value == 'on') {
            if(configObj.multilingual_front_office.options.language)
                lang = configObj.multilingual_front_office.options.language;

        }
    }


    // console.log('CONFIG: ', configObj);

    return {
        type: SET_PROMO,
        promotion,
        config: configObj,
        sessionUuid: uuid,
        lang
    }
}

export const endPromo = () => ({
    type: END_PROMO,
    promotion: false
})

export const showPopup = (popup, show) => {
    let action = {};
    action.type = popup;
    action[popup] = show;

    return action
}

export const setLang = (lang) =>  ({
    type: SET_LANG,
    lang
})

export const setShopGear = (id_shop_gear, gearName) => ({
    type: SHOP_GEAR,
    id_shop_gear,
    gearName
})

export const setPurchase = (purchase) => ({
    type: PURCHASE,
    purchase
})

export const setStep = (step) => ({
    type: STEP,
    step
})

export const setMachine = (machine) => ({
    type: MACHINE,
    machine
})

export const allProducts = (allProducts,autoAdded) => ({
    type: ALL_PRODUCTS,
    allProducts,
    autoAdded
})

export const setOcrLogs = (ocrLogs) => ({
    type: SET_OCR_LOGS,
    ocrLogs,
})

export const updateCart = (cart) => {
    let {allProducts} = store.getState();

    let capsuleCount = 0;


    cart.items.map(item => {
        if(!allProducts[item.itemId]) {
            console.log('id ', item.itemId);
        }
        capsuleCount += item.value * allProducts[item.itemId].capsuleCount;
    })

    cart.capsuleCount = capsuleCount;

    return {
        type: CART,
        cart,
        cartSum: JSON.stringify(cart)
    }
}

export const updateCustomer = (customer) => ({
    type: CUSTOMER,
    customer
})

export const setLastOrderTotal = (lastOrderTotal) => ({
    type: LAST_ORDER_TOTAL,
    lastOrderTotal
})

export const setCurrency = (currency) => ({
    type: SET_CURRENCY,
    currency
})

export const setLastValidStep = (lastValidStep) => ({
    type: SET_LAST_VALID_STEP,
    lastValidStep,
})

export const setCsAuthorization = (csAuthorization) => ({
    type: SET_CS_AUTHORIZATION,
    csAuthorization,
})

export const setCsGear = (csGear) => ({
    type: SET_CS_GEAR,
    csGear,
})

export const setConfiguration = (config, lang) => ({
    type: SET_CONFIGURATION,
    config,
    // lang,
})


export const clearStore = () => ({
    type: CLEAR_STORE,
})

export const translate = (name, ...args) => {
    const lang = store.getState().lang;
    let file = null;
    try {
        file = require('../assets/translations/'+lang+'-all.json');
    }
    catch (e) {
        file = require('../assets/translations/en-all.json')
        console.log("Create file for: " + lang + ' lang!');
    }

    const params = name.toString().split('.');
    let slug = file;

    params.forEach(s => {
        // console.log(slug);
        if(typeof slug !== "undefined")
            slug = slug[s];
        else
            slug = name;
    })

    if(!slug)
        slug = '';

    args.map(arg => {
        slug = slug.toString().replace('{arg}', arg);
    })
    slug = slug.toString().replaceAll('{arg}', '');



    return slug;
}
