import React from "react";
import axios from 'axios';


const apiUrl = process.env.REACT_APP_API_URL;


export const getPromotions = () => {
    console.log(process.env)
    return fetch(apiUrl + 'promotions').then((r) => r.json())
}

export const getPromo = (id) => {
    return fetch(apiUrl + 'promotion/' + id).then((r) => r.json())
}

export const getCurrency = (lang) => {
    return fetch(apiUrl + 'get-currency/' + lang).then((r) => r.json())
}

export const validateSerialNumber = (serialNumber) => {
    return fetch(apiUrl + 'validate-serial-number', {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: "serial-number=" + encodeURIComponent(serialNumber)
    }).then((r) => r.json())
}

export const validatePromotionCode = (promotionCode, promotionId) => {
    return fetch(apiUrl + 'validate-promotion-code', {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: "promotion_code=" + encodeURIComponent(promotionCode) + "&promotion_id=" + encodeURIComponent(promotionId)
    }).then((r) => r.json())
}

export const serialNumberOcr = (content) => {
    return fetch(apiUrl + 'serial-number-ocr', {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: "content=" + encodeURIComponent(content)
    }).then((r) => r.json())
}

export const axiosSerialNumberOcr = (content, config = {}) => {
    return axios.post(
        apiUrl + 'serial-number-ocr',
        "content=" + encodeURIComponent(content),
        {
            ...config,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then(res => res);
}

export const pdfOcr = (content) => {
    return fetch(apiUrl + 'preview', {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: "content=" + encodeURIComponent(content)
    }).then((r) => r.json())
}

export const pdfOcrAxios = (content, config = {}) => {
    return axios.post(
        apiUrl + 'preview',
        "content=" + encodeURIComponent(content),
        {
            ...config,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then(res => res);
}

export const invoiceOcr = (content) => {
    return fetch(apiUrl + 'serial-number-ocr?scan=1', {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: "content=" + encodeURIComponent(content)
    }).then((r) => r.json())
}

export const invoiceOcrAxios = (content, config = {}) => {
    return axios.post(
        apiUrl + 'serial-number-ocr?scan=1',
        "content=" + encodeURIComponent(content),
        {
            ...config,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then(res => res);
}

export const getShops = () => {
    return fetch(apiUrl + 'shops').then((r) => r.json())
}

export const getProducts = (id_shop_gear, id_shop_promotion, pruchase_date, lang) => {
    return fetch(apiUrl + 'get-products', {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: "id_shop_gear=" + encodeURIComponent(id_shop_gear) + "&id_shop_promotion=" + encodeURIComponent(id_shop_promotion) + "&lang=" + encodeURIComponent(lang) + "&purchase_date=" + encodeURIComponent(pruchase_date)
    }).then((r) => r.json())
}

export const getCsProducts = (lang, categoryId = null) => {
    return fetch(apiUrl + 'cs/get-products/' + lang + (categoryId ? '/' + categoryId : ''), {
        headers: {
            Accept: 'application/json',
        },
    }).then((r) => r.json())
}

export const finalize = (order) => {
    return fetch(apiUrl + 'finalize', {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(order)
    }).then((r) => r.json())
}

export const getCityByPostcode = (data, config = {}) => {
    return axios.post(
        apiUrl + 'get-city',
        data,
        {
            ...config,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
        .then(res => res);
}


export const getPostCodeDetails = async (postCode) => {
    return axios.get(
        `https://api.postcodeapi.nu/v2/postcodes/${postCode}`,
        {
            headers: {
                Accept: 'application/json',
                'X-Api-Key': 'VE3k95LH4O2r4NiJ6yzU3kwxXgUkhYOaVWO17ERh',
            }
        });
    // .then(res => res);
}


export const getShopText = (data) => {
    return axios.post(
        apiUrl + 'get-shop-text',
        data,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        }
    )
}



export const getConfiguration = () => {
    return axios.get(apiUrl + 'get-configuration', {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.data)
        .catch(res => res.response.data);
}



export const getCorpSubscriptionConfig = () => {
    return axios.get(apiUrl + 'cs/index', {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        }
    )
}


export const csCheckEmail = (email, lang, config = {}) => {
    return axios.post(apiUrl + 'cs/check-email',
        "email=" + encodeURIComponent(email) + "&lang=" + lang,
        {
            ...config,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => res.data)
}


export const csCheckCode = (code, config = {}) => {
    return axios.post(apiUrl + 'cs/check-code',
        "code=" + encodeURIComponent(code),
        {
            ...config,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        }).then(res => res.data)
}


export const getCsGear = (token, lang) => {
    return axios.get(apiUrl + 'cs/get-gear?token=' + token + `&lang=${lang}`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        }
    ).then(res => res.data)
}


export const csFinalize = (data, token, config = {}) => {
    return axios.post(apiUrl + 'cs/save-subscription?token=' + token,
        JSON.stringify(data),
        {
            ...config,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(res => res.data)
}

