import React, {Component} from 'react';
import {Button, CoffeePopup, CoffeeSetPopup, PlusMinusButtons} from "../index";
import './GearItem.css';
import { connect } from 'react-redux';
import {updateCart, translate as t} from "../../actions/store";
import GearPopup from "../popups/GearPopup";

const mapStateToProps = (state) => ({
    cart: state.cart,
    cartSum: state.cartSum,
    allProducts: state.allProducts,
    currency: state.currency,
    lang: state.lang,
})

const mapDispatchToProps = (dispatch) => ({
    updateCart: (cart) => dispatch(updateCart(cart))
})



class GearItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
            gear: props.item.machines[0],
            description: {},
            // color: props.item.color_mask[0] ?? null,
        }
    }

    componentDidMount() {
        this.setState({
            description: this.shortText(this.getText(this.props.item.corporate_subscription_plan_description)),
        })

        if(!this.props.gear) {
            this.setState({
                gear: this.props.item.machines[0],
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(this.props.lang !== prevProps.lang) {
            this.setState({
                description: this.shortText(this.getText(this.props.item.corporate_subscription_plan_description)),
            })
        }

        if(this.props.currency !== prevProps.currency) {
            console.log('cur: ', this.props.currency)
        }
    }

    setGear(gear) {
        this.setState({gear})
        this.props.setGear(null);
    }

    shortText(text: string, count: number = 170) {
        let shortText = '';
        const arr = text.split(' ');

        if(text.length > count) {
            shortText = text.substr(0, count);
            const idx = shortText.lastIndexOf(' ');
            shortText = shortText.substr(0, idx);

            shortText += ' ...';
            return {
                text: shortText,
                hasMore: true,
            };
        }

        return {
            text,
            hasMore: false,
        };
    }

    toggleText() {
        if(this.state.description.hasMore) {
            this.setState({
                description: {
                    text: this.getText(this.props.item.corporate_subscription_plan_description),
                    hasMore: false,
                },
            })
        }
        else {
            this.setState({
                description: this.shortText(this.getText(this.props.item.corporate_subscription_plan_description)),
            })
        }
    }

    getText(str) {
        try {
            const obj = JSON.parse(str);
            const key = Object.keys(obj).find(key => key.startsWith(this.props.lang))

            let text = '';
            if(key) {
                text = obj[key];
            }

            return text;
        }
        catch (err) {
            return str;
        }
    }

    render() {
        const item = this.props.item;
        console.log('cur2: ', this.props.currency)
        return (
            <div className="gearItem">
                <div className="gearItem__header">
                    <div className="gearItem__thumb">
                        <img src={this.state.gear?.filename} alt={this.state.gear?.name}/>
                    </div>
                    <h3 className="gearItem__title">{item.corporate_subscription_plan_name}</h3>
                </div>
                <div className="gearItem__body">
                    <p
                        className="gearItem__subtitle"
                        style={{cursor: this.state.description?.hasMore ? 'pointer' : 'normal'}}
                        dangerouslySetInnerHTML={{__html: this.state.description.text}}
                        onClick={() => this.toggleText()}
                    />

                    <div className="gearItem__price">
                        <h5 className="gearItem__priceLabel">{t('gear.price_label')}&nbsp;</h5>
                        <h5 className="gearItem__priceValue">{this.props.currency.prefix}{Number(item.corporate_subscription_plan_price).toFixed(2) ?? 0}{this.props.currency.postfix}</h5>
                    </div>

                    <div className="gearItem__addon">{t('gear.addon', item.corporate_subscription_plan_subrebate_price)}</div>
                    <div className="gearItem__inStoresFrom">{t('gear.in_stores_from', Number(item.corporate_subscription_plan_machine_price).toFixed(2) ?? 0)}</div>


                    <div className="coffeeItem__link">
                        <button className="link -primary"
                                onClick={() => this.setState({showPopup: true})}>{t('read_more')}</button>
                    </div>

                    <div className="gearColors">
                        {/*<h4 className="gearColors__title">{t(`gear.colors.${this.state.color}`)}</h4>*/}
                        <h4 className="gearColors__title">{this.state.gear?.corporate_subscription_plan_machine_variant ?? '-'}</h4>
                        <div className="gearColors__items">
                            {item.machines.map((gear, idx) => {
                                return (
                                    <button key={idx} className={`gearColor ${this.state.gear?.id_shop_gear === gear.id_shop_gear ? '-active' : ''}`}
                                            style={{backgroundColor: gear.corporate_subscription_plan_machine_color}}
                                            onClick={() => this.setGear(gear)}/>
                                )
                            })}
                        </div>
                    </div>

                    <div className="gearItem__bullets" dangerouslySetInnerHTML={{__html: this.getText(item.corporate_subscription_plan_bullets)}}/>
                    {/*<div className="gearItem__bullets">*/}
                    {/*    <ul>*/}
                    {/*        <li>trallaalla sis sjsjjs</li>*/}
                    {/*        <li>tralaalla sis sjsjjs</li>*/}
                    {/*        <li>trallaalla sis sjsjjs</li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}


                    {/*<div className="gearColors">*/}
                    {/*    /!*<h4 className="gearColors__title">{t(`gear.colors.${this.state.color}`)}</h4>*!/*/}
                    {/*    <h4 className="gearColors__title">{this.state.color.shop_product_name}</h4>*/}
                    {/*    <div className="gearColors__items">*/}
                    {/*        {item.color_mask.map(color => (*/}
                    {/*            <button className={`gearColor -code-${color.shop_product_color} ${this.state.color === color ? '-active' : ''}`}*/}
                    {/*                    onClick={e => this.setColor(color)}/>*/}
                    {/*        ))}*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <div className="gearItem__button">
                        <Button class="-primary -sm" label={t('buy_now')}
                                disabled={!this.state.gear || this.state.gear.id_shop_gear === this.props.gear?.id_shop_gear}
                                onClick={() => this.props.setGear(this.state.gear)}/>
                    </div>
                </div>


                {this.state.showPopup && (
                    <GearPopup
                        item={item}
                        gear={this.state.gear}
                        show={this.state.showPopup}
                        onClose={() => this.setState({showPopup: false})}/>
                )}
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(GearItem);
