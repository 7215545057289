import React, {Component} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import {connect} from 'react-redux';
import {CustomerRegistration, Error404, GetInTouch, MachineRegistration, OrderSummary, StepCustom} from "../index";
import {clearStore, setCurrency, setStep, showPopup, translate as t} from "../../actions/store";
import {STEP_1, STEP_2, STEP_3, STEP_4} from "../../actions/utils";
import SelectOffer from "../selectOffer/SelectOffer";
import * as actions from "../../actions/actions";

const mapDispatchToProps = dispatch => ({
    setStep: (step) => dispatch(setStep(step)),
    showPopup: (popup, show) => dispatch(showPopup(popup, show)),
    setCurrency: (currency) => dispatch(setCurrency(currency)),
    clearStore: () => dispatch(clearStore()),
})

const mapStateToProps = state => ({
    step: state.step,
    promotion: state.promotion,
    withCapsules: state.withCapsules,
    // machine: state.machine,
    // cart: state.cart,
    // customer: state.customer,
    lastValidStep: state.lastValidStep,
    currency: state.currency,
    lang: state.lang,
})

class FunnelCustom extends Component {
    constructor(props) {
        super(props);


        const step = this.getStepByUrl();
        if(!this.props.promotion) {
            this.props.history.push('/');
        }
        else if(this.canShowStep(step)) {
            this.props.setStep(step)
        }
        else {
            this.props.setStep(this.getNextStep())
            this.props.history.push('/shop/'+this.getUrlByStep(this.getNextStep()));
        }


        this.sessionTimeout = null;
    }

    componentDidMount() {
        actions.getCurrency(this.props.lang ?? 'en').then((r) => {
            if(r.status == 'success') {
                this.props.setCurrency(r.data);
            }
        })
    }

    getStepByUrl() {
        const url = this.props.match.url;

        if(url.includes(STEP_1)) {
            return 1;
        }
        if(url.includes(STEP_2)) {
            return 2;
        }
        if(url.includes(STEP_3)) {
            return 3;
        }
        if(url.includes(STEP_4)) {
            return 4;
        }

        return 1;
    }

    getUrlByStep(step) {
        switch (step) {
            case 1: return STEP_1;
            case 2: return STEP_2;
            case 3: return STEP_3;
            case 4: return STEP_4;
        }
    }

    handleShowStep(stepNo) {
        if(stepNo !== this.props.step) {
            if(this.canShowStep(stepNo)) {
                this.props.setStep(stepNo)
                this.props.history.push('/shop/'+this.getUrlByStep(stepNo));
            }
            else {
                this.props.setStep(this.getNextStep())
                this.props.history.push('/shop/'+this.getUrlByStep(this.getNextStep()));
            }
        }
    }

    canShowStep(stepNo) {
        return stepNo-1 <= this.props.lastValidStep;
    }

    getNextStep() {
        return this.props.lastValidStep + 1;
    }

    handleSession() {
        clearTimeout(this.sessionTimeout);
        this.sessionTimeout = setTimeout(() => {
            this.props.history.push('/');
            this.props.clearStore();
        }, 1000 * (60 * 10)) // 10min
    }

    render() {
        if(!this.props.promotion) {
            return null;
        }
        return (
            <div className="main">
                <div className="wrapper-bg formContainer">
                    <div className="steps">
                        {this.props.step >= 1 ? (
                            <MachineRegistration stepNo={1}
                                                 currentStep={this.props.step}
                                                 showStep={step => this.handleShowStep(step)}
                                                 keepSession={() => this.handleSession()}
                            />
                        ) : (
                            <StepCustom label={t('s_machine._title')}
                                        stepNo={1}
                                        currentStep={this.props.step}
                                        showStep={() => null}/>
                        )}

                        {this.props.step >= 2 ? (
                            <SelectOffer stepNo={2}
                                         currentStep={this.props.step}
                                         showStep={step => this.handleShowStep(step)}
                                         keepSession={() => this.handleSession()}
                            />
                        ) : (
                            <StepCustom label={t('s_offer._title')}
                                        stepNo={2}
                                        currentStep={this.props.step}
                                        showStep={() => null}/>
                        ) }

                        {this.props.step >= 3 ? (
                            <CustomerRegistration stepNo={3}
                                                  currentStep={this.props.step}
                                                  showStep={step => this.handleShowStep(step)}
                                                  keepSession={() => this.handleSession()}
                            />
                        ) : (
                            <StepCustom label={t('s_customer._title')}
                                        stepNo={3}
                                        currentStep={this.props.step}
                                        showStep={() => null}/>
                        ) }

                        {this.props.step >= 4 ? (
                            <OrderSummary stepNo={4}
                                          currentStep={this.props.step}
                                          showStep={step => this.handleShowStep(step)}
                                          keepSession={() => this.handleSession()}
                            />
                        ) : (
                            <StepCustom label={t('s_summary._title')}
                                        stepNo={4}
                                        currentStep={this.props.step}
                                        showStep={() => null}/>
                        ) }


                        {/*<StepCustom label={t('s_customer._title')}*/}
                        {/*            stepNo={3}*/}
                        {/*            currentStep={this.props.step}*/}
                        {/*            showStep={() => this.handleShowStep(3)}/>*/}

                        {/*<StepCustom label={t('s_summary._title')}*/}
                        {/*            stepNo={4}*/}
                        {/*            currentStep={this.props.step}*/}
                        {/*            showStep={() => this.handleShowStep(4)}/>*/}
                    </div>


                    <div className="wrapper">
                        {this.props.step < 4 ? (
                            <div className="funnel__getInTouch">
                                <GetInTouch onClick={() => this.props.showPopup('getInTouchPopup', true)} />
                            </div>
                        ) : <></>}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FunnelCustom);
