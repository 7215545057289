import React, {Component} from 'react';
import './Popups.css';
import {Button, GetInTouch, Popup} from "../index";
import {translate as t} from "../../actions/store";

class CartPopup extends Component {

    render() {
        return (
            <Popup show={this.props.show}
                   onClose={() => this.props.onClose()}
                   showGetInTouch={this.props.showGetInTouch ?? true}
                   modifierClass="-sm">
                <div className="popup__header">
                    <h3 className="popup__title">{t('basket')}</h3>
                </div>
                <div className="popup__body">
                    {this.props.children}
                </div>
            </Popup>
        );
    }
}

export default CartPopup;
