import TagManager from 'react-gtm-module'


const ENV_PROD = process.env.ENV_PROD ?? 'false';


export const showPage = (data) => {
    const args = {
        dataLayer: {
            ...data,
            isEnvironmentProd: ENV_PROD.toString(),
        },
        dataLayerName: 'PageDataLayer',
    }

    TagManager.dataLayer(args);
}
