import React, {Component} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import {connect} from 'react-redux';
import {
    CorpSubscriptionAuthorization,
    CorpSubscriptionOffer,
    CorpSubscriptionCustomerRegistration,
    CustomerRegistration,
    Error404,
    GetInTouch,
    MachineRegistration,
    OrderSummary,
    StepCustom, CorpSubscriptionSummary
} from "../index";
import {
    clearStore,
    setCurrency,
    setLang,
    setStep,
    showPopup,
    setConfiguration,
    translate as t
} from "../../actions/store";
import * as utils from "../../actions/utils";
import SelectOffer from "../selectOffer/SelectOffer";
import * as actions from "../../actions/actions";
import './CorpSubscriptionFunnel.css';
import CorpSubscriptionSelectOffer from "../corpSubscriptionSelectOffer/CorpSubscriptionSelectOffer";


const mapDispatchToProps = dispatch => ({
    setStep: (step) => dispatch(setStep(step)),
    showPopup: (popup, show) => dispatch(showPopup(popup, show)),
    setCurrency: (currency) => dispatch(setCurrency(currency)),
    clearStore: () => dispatch(clearStore()),
    setLang: (lang) => dispatch(setLang(lang)),
    setConfiguration: (config, lang) => dispatch(setConfiguration(config, lang)),
})

const mapStateToProps = state => ({
    step: state.step,
    promotion: state.promotion,
    withCapsules: state.withCapsules,
    // machine: state.machine,
    // cart: state.cart,
    // customer: state.customer,
    lastValidStep: state.lastValidStep,
    currency: state.currency,
    lang: state.lang,
    config: state.config,
})


class CorpSubscriptionFunnel extends Component {
    constructor(props) {
        super(props);


        const step = this.getStepByUrl();
        if (this.canShowStep(step)) {
            this.props.setStep(step)
        } else {
            this.props.setStep(this.getNextStep())
            this.props.history.push(this.getUrlByStep(this.getNextStep()));
        }


        this.sessionTimeout = null;
    }

    componentDidMount() {
        // actions.getCurrency(this.props.lang ?? 'en').then((r) => {
        //     if (r.status == 'success') {
        //         // console.log('set currency: ', r.data)
        //         this.props.setCurrency(r.data);
        //     }
        // })

        actions.getConfiguration().then(res => {
            if (res.status === 'success') {
                const config = res.data;
                let configObj = {};
                for (let c in config) {
                    let item = config[c];
                    configObj[item.name] = JSON.parse(item.value);
                }



                let lang = 'en';
                if(configObj.multilingual_front_office?.value == 'on') {
                    if(configObj.multilingual_front_office.options.language)
                        lang = configObj.multilingual_front_office.options.language;

                }

                this.props.setConfiguration(configObj, lang);
            }
        })
    }

    getStepByUrl() {
        const url = this.props.match.url;

        if (url.includes(utils.CORP_SUBSCRIPTION_STEP_5)) {
            return 5;
        }
        if (url.includes(utils.CORP_SUBSCRIPTION_STEP_4)) {
            return 4;
        }
        if (url.includes(utils.CORP_SUBSCRIPTION_STEP_3)) {
            return 3;
        }
        if (url.includes(utils.CORP_SUBSCRIPTION_STEP_2)) {
            return 2;
        }
        if (url.includes(utils.CORP_SUBSCRIPTION_STEP_1)) {
            return 1;
        }


        return 1;
    }

    getUrlByStep(step) {
        switch (step) {
            case 1:
                return utils.CORP_SUBSCRIPTION_STEP_1;
            case 2:
                return utils.CORP_SUBSCRIPTION_STEP_2;
            case 3:
                return utils.CORP_SUBSCRIPTION_STEP_3;
            case 4:
                return utils.CORP_SUBSCRIPTION_STEP_4;
            case 5:
                return utils.CORP_SUBSCRIPTION_STEP_5;
        }
    }

    handleShowStep(stepNo) {
        if (stepNo !== this.props.step) {
            if (this.canShowStep(stepNo)) {
                this.props.setStep(stepNo)
                this.props.history.push(this.getUrlByStep(stepNo));
            } else {
                this.props.setStep(this.getNextStep())
                this.props.history.push(this.getUrlByStep(this.getNextStep()));
            }
        }
    }

    canShowStep(stepNo) {
        return stepNo - 1 <= this.props.lastValidStep;
    }

    getNextStep() {
        return this.props.lastValidStep + 1;
    }

    handleSession() {
        clearTimeout(this.sessionTimeout);
        this.sessionTimeout = setTimeout(() => {
            this.props.history.push('/');
            this.props.clearStore();
        }, 1000 * (60 * 10)) // 10min
    }

    render() {
        return (
            <div className="main">
                <div className="wrapper-bg formContainer">
                    <div className="steps">
                        {this.props.step >= 1 ? (
                            <CorpSubscriptionAuthorization stepNo={1}
                                                           currentStep={this.props.step}
                                                           showStep={step => this.handleShowStep(step)}
                                                           keepSession={() => this.handleSession()}
                            />
                        ) : (
                            <StepCustom label={t('cs_authorization._title')}
                                        stepNo={1}
                                        currentStep={this.props.step}
                                        showStep={() => null}/>
                        )}

                        {this.props.step >= 2 ? (
                            <CorpSubscriptionOffer stepNo={2}
                                                   currentStep={this.props.step}
                                                   showStep={step => this.handleShowStep(step)}
                                                   keepSession={() => this.handleSession()}
                            />
                        ) : (
                            <StepCustom label={t('cs_offer._title')}
                                        stepNo={2}
                                        currentStep={this.props.step}
                                        showStep={() => null}/>
                        )}

                        {this.props.step >= 3 ? (
                            <CorpSubscriptionSelectOffer stepNo={3}
                                                   currentStep={this.props.step}
                                                   showStep={step => this.handleShowStep(step)}
                                                   keepSession={() => this.handleSession()}
                            />
                        ) : (
                            <StepCustom label={t('cs_s_offer._title')}
                                        stepNo={3}
                                        currentStep={this.props.step}
                                        showStep={() => null}/>
                        )}

                        {this.props.step >= 4 ? (
                            <CorpSubscriptionCustomerRegistration stepNo={4}
                                                                  currentStep={this.props.step}
                                                                  showStep={step => this.handleShowStep(step)}
                                                                  keepSession={() => this.handleSession()}
                            />
                        ) : (
                            <StepCustom label={t('cs_customer._title')}
                                        stepNo={4}
                                        currentStep={this.props.step}
                                        showStep={() => null}/>
                        )}

                        {this.props.step >= 5 ? (
                            <CorpSubscriptionSummary stepNo={5}
                                                     currentStep={this.props.step}
                                                     showStep={step => this.handleShowStep(step)}
                                                     keepSession={() => this.handleSession()}
                            />
                        ) : (
                            <StepCustom label={t('cs_summary._title')}
                                        stepNo={5}
                                        currentStep={this.props.step}
                                        showStep={() => null}/>
                        )}

                    </div>


                    {/*<div className="wrapper">*/}
                    {/*    {this.props.step < 4 ? (*/}
                    {/*        <div className="funnel__getInTouch">*/}
                    {/*            <GetInTouch onClick={() => this.props.showPopup('getInTouchPopup', true)} />*/}
                    {/*        </div>*/}
                    {/*    ) : <></>}*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorpSubscriptionFunnel);
