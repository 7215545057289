import React, {Component} from 'react';
import './Popups.css';
import {Button, GetInTouch, PlusMinusButtons, Popup} from "../index";
import { connect } from 'react-redux';
import {translate as t} from "../../actions/store";

const mapStateToProps = (state) => ({
    allProducts: state.allProducts,
    currency: state.currency,
})

class CoffeeSetPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inCartValue: props.inCartValue,
        }

        this.cupSizes = [];

        let cs = props.item.cupsSizes.split(" ");
        for(let i in cs) {
            let size = cs[i];
            switch(size) {
                case 'xs':
                    this.cupSizes.push({
                        img: require('../../assets/images/icons/cup-25.svg'),
                        label: 25,
                    });
                    return;
                case 's':
                    this.cupSizes.push({
                        img: require('../../assets/images/icons/cup-25.svg'),
                        label: 40,
                    });
                    return;
                case 'm':
                    this.cupSizes.push({
                        img: require('../../assets/images/icons/cup-25.svg'),
                        label: 110,
                    });
                    return;
                default:


            }
        }

        this.productsInSer = [];

        for(let i in props.item.productsInSer) {
            // let id = parseInt(props.item.productsInSer[i]);
            if("undefined" !== typeof props.allProducts[i]) {
                this.productsInSer.push(props.allProducts[i]);
            }
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.itemCartConfig.value !== this.state.inCartValue)
            this.setState({inCartValue: this.props.itemCartConfig.value})
    }

    toggleSection(element) {
        const section = element.target.closest('.coffeePopup__section')
        const sectionBody = section.querySelector('.coffeePopup__sectionBody')
        if(section.classList.contains('-active')) {
            section.classList.remove('-active');
            sectionBody.style.maxHeight = 0;
        }
        else {
            section.classList.add('-active');
            sectionBody.style.maxHeight = sectionBody.scrollHeight + 'px';
        }
    }

    render() {
        const item = this.props.item;
        const itemCartConfig = this.props.itemCartConfig;

        let intensityBox = [];
        for (let i = 0; i < 13; i++) {
            let active = i < item.intensity ? '-active' : '';
            intensityBox.push(<span key={i} className={active}/>);
        }

        // if(!item.text) {
        //     item.text = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa maiores, voluptatem! reiciendis tempora vel? Aliquam ducimus natus sunt. Dolore natus, necessitatibus non reiciendis sunt veniam'
        // }
        //
        // if(!item.subtitle) {
        //     item.subtitle = ' Aspernatur error maiores modi quo reiciendis tempora vel?'
        // }

        // console.log('coffee set: ', item)


        return (
            <Popup show={this.props.show} onClose={() => this.props.onClose()} showGetInTouch={false}>
                <div className="coffeePopup">
                    <div className="coffeePopup__col -center --toMdHide">
                        <div className="coffeePopup__thumb -coffeeSet">
                            <img src={item.img} alt={item.title}/>
                        </div>
                        {/*<div className="coffeeItem__intensity">*/}
                        {/*    <div className="coffeeItem__intensityBox">*/}
                        {/*        {intensityBox.map(item => item)}*/}
                        {/*    </div>*/}
                        {/*    <span className="coffeeItem__intensityText">{t('coffee.intensity')} {item.intensity}</span>*/}
                        {/*</div>*/}
                        {/*<h5 className="coffeePopup__cupSizesTitle">{t('coffee.cup_size')}:</h5>*/}
                        {/*<div className="coffeePopup__cupSizes">*/}
                        {/*    {this.cupSizes.map((size, key) => (*/}
                        {/*        <div key={key} className="coffeePopup__cupSize">*/}
                        {/*            <img src={size.img} alt={size.label}/>*/}
                        {/*            <span>{size.label} ml</span>*/}
                        {/*        </div>*/}
                        {/*    ))}*/}
                        {/*</div>*/}
                        {this.productsInSer ? (
                            <>
                                <h5 className="coffeePopup__similarItemsTitle">{t('coffee.selection_includes')}</h5>
                                <div className="coffeePopup__similarItems -coffeeSet">
                                    {this.productsInSer.map(item => (
                                        <div key={item.id} className="coffeePopup__similarItem">
                                            <img src={item.img} alt={item.title} />
                                            <h6 className="coffeePopup__similarItemTitle">{item.title}</h6>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : <></>}
                    </div>
                    <div className="coffeePopup__col">
                        <div className="coffeePopup__thumb --fromMdHide -coffeeSet">
                            <img src={item.img} alt={item.title}/>
                        </div>
                        <h2 className="coffeePopup__title">{item.title}</h2>
                        <h3 className="coffeePopup__subtitle">{item.subtitle}</h3>
                        <div className="coffeePopup__price"><span>{this.props.currency.prefix}{item.price}{this.props.currency.postfix}</span></div>
                        <div className="coffeePopup__button">
                            {this.state.inCartValue >= itemCartConfig.min ? (
                                <PlusMinusButtons id={item.id} />
                            ) : (
                                <Button class="-primary -sm" disabled={this.props.disableAdd} label={t('buy_now')} onClick={() => this.props.onChange(item.step)}/>
                            )}
                        </div>
                        {/*<div className="coffeeItem__intensity --fromMdHide">*/}
                        {/*    <div className="coffeeItem__intensityBox">*/}
                        {/*        {intensityBox.map(item => item)}*/}
                        {/*    </div>*/}
                        {/*    <span className="coffeeItem__intensityText">{t('coffee.intensity')} {item.intensity}</span>*/}
                        {/*</div>*/}
                        <div className="coffeePopup__lead">
                            <div className="coffeePopup__par1" dangerouslySetInnerHTML={{__html: item.text}}/>
                        </div>

                        {this.productsInSer ? (
                            <div className="--fromMdHide">
                                <h5 className="coffeePopup__similarItemsTitle">{t('coffee.selection_includes')}</h5>
                                <div className="coffeePopup__similarItems -coffeeSet">
                                    {this.productsInSer.map(item => (
                                        <div key={item.id} className="coffeePopup__similarItem">
                                            <img src={item.img} alt={item.title} />
                                            <h6 className="coffeePopup__similarItemTitle">{item.title}</h6>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : <></>}

                        {/*<div className="--fromMdHide">*/}
                        {/*    <h5 className="coffeePopup__cupSizesTitle">{t('coffee.cup_size')}:</h5>*/}
                        {/*    <div className="coffeePopup__cupSizes">*/}
                        {/*        {this.cupSizes.map((size, key) => (*/}
                        {/*            <div key={key} className="coffeePopup__cupSize">*/}
                        {/*                <img src={size.img} alt={size.label}/>*/}
                        {/*                <span>{size.label} ml</span>*/}
                        {/*            </div>*/}
                        {/*        ))}*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="coffeePopup__sections">*/}
                        {/*    {item.sections.map((section, key) => (*/}
                        {/*        <div key={key} className="coffeePopup__section">*/}
                        {/*            <button className="coffeePopup__sectionHeader" onClick={o => this.toggleSection(o)}>*/}
                        {/*                <span>{t('coffee.section.title.' + section.title)}</span>*/}
                        {/*                <img src={require('../../assets/images/icons/arrow-down.svg')} alt={t('expand')} className="coffeePopup__sectionIcon"/>*/}
                        {/*            </button>*/}
                        {/*            <div className="coffeePopup__sectionBody">*/}
                        {/*                <div className="coffeePopup__par1" dangerouslySetInnerHTML={{__html: section.text}}/>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    ))}*/}
                        {/*</div>*/}
                        <GetInTouch onClick={() => null}/>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default connect(mapStateToProps)(CoffeeSetPopup);
