import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {translate as t} from '../../actions/store';
import {GetInTouch, Button} from "../index";
import {showPopup} from "../../actions/store";
import {connect} from "react-redux";
import './Popups.css';

const mapDispatchToProps = (dispatch) => ({
    showPopup: (popup, show) => dispatch(showPopup(popup, show))
})

const popupContainer = document.getElementById('popupContainer');

class FilterPopup extends Component {
    constructor(props) {
        super(props);

        let showGetInTouch = true;
        if(props.showGetInTouch === false)
            showGetInTouch = false;

        this.state = {
            showGetInTouch,
            popupContentStyle: null,
            popupStyle: null,
        };

        this.popupRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.show !== this.props.show) {
            if(this.props.show) {
                document.querySelector('body').classList.add('-overflow-hidden');

                // console.log(this.popupRef.current)

                const activePopups = document.querySelectorAll('.popup[data-no]').length;
                this.popupRef.current.setAttribute('data-no', activePopups)

                if(activePopups > 0) {
                    const prev = document.querySelector('.popup[data-no="'+(activePopups-1)+'"]')
                    if(prev)
                        prev.classList.remove('-active')
                }

                let marginLeft, marginTop;
                if(window.outerWidth < 768) {
                    marginLeft = 5;
                    marginTop = 5;
                }
                else {
                    marginLeft = 40;
                    marginTop = 20;
                }
                this.setState({
                    popupContentStyle: {
                        // marginLeft: (activePopups - 1) * marginLeft + 'px',
                        // marginTop: (activePopups - 1) * marginTop + 'px',
                    },
                    popupStyle: {
                        zIndex: (activePopups - 1) + 10000,
                    }
                })
            }
            this.toggleBodyOverflow();
        }
    }

    handleClose() {
        const no = this.popupRef.current.getAttribute('data-no');
        const prev = document.querySelector('.popup[data-no="'+(no-1)+'"]')
        if(prev) {
            prev.classList.add('-active');
        }
        this.popupRef.current.removeAttribute('data-no');
        this.popupRef.current.style.zIndex = null;
        // console.log('data ', no)

        this.props.onClose();
    }

    onClickOverlay(e) {
        // e.preventDefault();
        if(e.currentTarget === e.target)
            this.handleClose();
    }

    toggleBodyOverflow() {
        let removeOverflow = true;

        document.querySelectorAll('.popup').forEach(e => {
            if(e.classList.contains('-active'))
                removeOverflow = false;
        })

        if(removeOverflow)
            document.querySelector('body').classList.remove('-overflow-hidden');
    }

    toggleSort(element) {
        element = element.currentTarget;
        element.classList.toggle('-active');
        // this.props.changeSort ...
    }

    toggleSortItems() {
        let body = document.querySelector('.filterPopup__sortBody');
        body.classList.toggle('-active');
    }

    render() {
        let popupClass = 'popup';
        if(this.props.show) {
            popupClass += ' -active';
        }
        if(this.props.modifierClass)
            popupClass += ' ' + this.props.modifierClass;

        return ReactDOM.createPortal(
            <div className={popupClass}
                 onClick={e => this.onClickOverlay(e)}
                 style={this.state.popupStyle}
                 ref={this.popupRef}
            >
                <div className="popup__content" style={this.state.popupContentStyle}>
                    <div className="popup__scrollArea">
                        <button className="filterPopup__reset" onClick={() => this.props.onClickReset()}>{t('reset_all')}</button>
                        <div className="filterPopup">
                            <div className="popup__body">

                                <div className="filterPopup__title">{t('filter.title')}</div>
                                <div className="filterPopup__box">
                                    {this.props.filterOptions.map(filter => (
                                        <div key={filter.value} className="filterPopup__filter">
                                            <div className="filterPopup__name">{filter.name}</div>
                                            <div className="filterPopup__items">
                                                {filter.options.map(option => {
                                                    const isActive = this.props.filters[filter.value]?.includes(option.value);
                                                    if(typeof option.img !== "undefined") {
                                                        const className = 'filterPopup__item' + (isActive ? ' -active' : '');
                                                        return (
                                                            <div key={option.value} className={className} onClick={() => this.props.onChangeFilter(filter.value, option.value)}>
                                                                <div className="filterPopup__itemThumb">
                                                                    {option.img ? <img src={option.img} alt={option.name}/> : <></>}
                                                                </div>
                                                                <div className="filterPopup__itemLabel">{option.name}</div>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        const className = 'filterPopup__option' + (isActive ? ' -active' : '');
                                                        return (
                                                            <div key={option.value} className={className} onClick={() => this.props.onChangeFilter(filter.value, option.value)}>
                                                                {option.name}
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="filterPopup__submit">
                                    <Button class="-secondary" label={t('filter.see') + ' ' + this.props.resultsCount + ' ' + t('filter.results')} onClick={() => this.handleClose()}/>
                                </div>
                            </div>
                        </div>

                        {this.state.showGetInTouch ? (
                            <div className="popup__footer" hidden={!this.state.showGetInTouch}>
                                <GetInTouch onClick={() => this.props.showPopup('getInTouchPopup', true)}/>
                            </div>
                        ) : <></>}
                    </div>
                    <button className="popup__close" onClick={() => this.handleClose()}>
                        <img src={require('../../assets/images/icons/close.svg')} alt="x"/>
                    </button>
                </div>
            </div>,
            popupContainer
        )

        // return (
        //     <div className={popupClass} onClick={e => this.onClickOverlay(e)}>
        //         <div className="popup__content" style={this.state.popupContentStyle}>
        //             <div className="popup__scrollArea">
        //                 {this.props.children}
        //
        //                 {this.state.showGetInTouch ? (
        //                     <div className="popup__footer" hidden={!this.state.showGetInTouch}>
        //                         <GetInTouch onClick={() => this.props.showPopup('getInTouchPopup', true)}/>
        //                     </div>
        //                 ) : <></>}
        //             </div>
        //             <button className="popup__close" onClick={() => this.props.onClose()}>
        //                 <img src={require('../../assets/images/icons/close.svg')} alt="x"/>
        //             </button>
        //         </div>
        //     </div>
        // );
    }
}

export default connect(null, mapDispatchToProps)(FilterPopup);
