import React, {Component} from 'react';
import './Popups.css';
import {Button, GetInTouch, Popup} from "../index";
import {translate as t} from "../../actions/store";

class CsOfferInfoPopup extends Component {
    render() {
        return (
            <Popup show={this.props.show} onClose={() => this.props.onClose()} showGetInTouch={false}>
                <div className="popup__header">
                    <h3 className="popup__title">{t('p_cs_info.title')}</h3>
                </div>

                <div className="popup__body">
                    <div className="proofOfPurchasePopup">
                        <p className="par-2" dangerouslySetInnerHTML={{__html: t('p_cs_info.text')}}/>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default CsOfferInfoPopup;
