import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import {
    Header,
    Footer,
    Funnel,
    CorpSubscriptionFunnel,
    FunnelCustom,
    FindSerialNumberPopup,
    GetInTouchPopup,
    OrderFinalized,
    ProofOfPurchasePopup,
    EditSerialNumberPopup,
    Error404
} from '../index';
import './App.css';
import PromotionLoader from "./PromotionLoader";
import {store, persistor} from "../../actions/store";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import * as utils from "../../actions/utils";
import TagManager from 'react-gtm-module'
import Payment from "../payment/Payment";
import CorpSubscriptionPayment from "../payment/CorpSubscriptionPayment";



class App extends Component {
    render() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <div className="App">
                        <Switch>
                            {/*<Route exact path="/" component={PromotionLoader} />*/}
                            {/*<Route exact path="/shop/nespresso/set-promo/id/:id/lang/:lang" component={PromotionLoader}/>*/}
                            {/*<Route exact path="/set-promo/:id" component={PromotionLoader} />*/}
                            <Route path='*'>
                                <Header/>
                                <Switch>
                                    {/*<Route path="/shop" component={Funnel} />*/}



                                    {/*<Route exact path="/shop" component={FunnelCustom} />*/}
                                    {/*<Route exact path={"/shop/"+utils.STEP_1} component={FunnelCustom}/>*/}
                                    {/*<Route exact path={"/shop/"+utils.STEP_2} component={FunnelCustom}/>*/}
                                    {/*<Route exact path={"/shop/"+utils.STEP_3} component={FunnelCustom}/>*/}
                                    {/*<Route exact path={"/shop/"+utils.STEP_4} component={FunnelCustom}/>*/}

                                    {/*<Route exact path="/order-payment/:id" component={Payment} />*/}

                                    {/*<Route exact path="/order-finalized" component={OrderFinalized} />*/}



                                    <Route exact path={utils.CORP_SUBSCRIPTION_STEP_1} component={CorpSubscriptionFunnel}/>
                                    <Route exact path={utils.CORP_SUBSCRIPTION_STEP_2} component={CorpSubscriptionFunnel}/>
                                    <Route exact path={utils.CORP_SUBSCRIPTION_STEP_3} component={CorpSubscriptionFunnel}/>
                                    <Route exact path={utils.CORP_SUBSCRIPTION_STEP_4} component={CorpSubscriptionFunnel}/>
                                    <Route exact path={utils.CORP_SUBSCRIPTION_STEP_5} component={CorpSubscriptionFunnel}/>
                                    <Route exact path="/order-finalized" component={OrderFinalized} />

                                    {/*<Route exact path="/stay-home/payment/:token" component={CorpSubscriptionPayment}   />*/}


                                    {/*<Route exact path="/" component={PromotionLoader} />*/}
                                    <Route path="*" component={Error404}/>
                                </Switch>
                                <Footer/>

                                <div id="popups">
                                    <GetInTouchPopup />
                                </div>
                            </Route>
                        </Switch>
                    </div>
                </Router>
            </PersistGate>
        </Provider>
    )
  }
}

export default App;
