import React from "react";

import * as actions from '../../actions/actions';
import {setPromo, setLang, translate as t, setCurrency} from "../../actions/store";
import {connect} from "react-redux";

const mapDispatchToProps = (dispatch) => ({
    setPromo: (promotion, config, uuid) => dispatch(setPromo(promotion, config, uuid)),
    setPromoWithLang: (promotion, config, uuid, lang) => dispatch(setPromo(promotion, config, uuid, lang)),
    setLang: (lang) => dispatch(setLang(lang)),
    setCurrency: (currency) => dispatch(setCurrency(currency)),
})

class PromotionLoader extends React.Component {

    constructor(props) {
        super(props);



        this.state = {
            promotions: [],
            loaded: false
        }
    }

    setPromotion(id, lang = null) {

        if(lang) {
            const langArr = lang.split('_');
            lang = langArr[0];
        }


        // console.log('id', id, 'lang', lang);

        actions.getPromo(id).then((r) => {
            if(r.status == 'success') {

                if(lang) {
                    this.props.setPromoWithLang(r.data[0], r.config, r.uuid, lang);
                }else{
                    this.props.setPromo(r.data[0], r.config, r.uuid);
                }

                this.props.history.push('/shop/machine-registration');

            }
        })

        // actions.getCurrency(this.props.lang).then((r) => {
        //     if(r.status == 'success') {
        //         this.props.setCurrency(r.data);
        //     }
        // })

        // this.props.setLang(lang ?? 'en');
    }

    componentDidMount() {

        if(this.props.match.params.id) {
            this.setPromotion(this.props.match.params.id, this.props.match.params.lang)
        }else{

            actions.getPromotions().then((r) => {

                let promotions = this.state.promotions;
                if(r.status == 'success') {
                    promotions = r.data;

                    // set language in welcome page
                    let configObj = {};
                    for(let c in r.config) {
                        let item = r.config[c];
                        configObj[item.name] = JSON.parse(item.value);
                    }
                    this.props.setLang(configObj.multilingual_front_office?.options?.language ?? 'en');
                }

                this.setState({
                    loaded: true,
                    promotions,
                })

            })

        }
    }

    render() {
        // if(this.state.loaded) {
        //     if(this.state.promotions.length > 0)
        //         return this.state.promotions.map((value, index) => <button key={index} onClick={() => this.setPromotion(value.id_shop_promotion)}>{value.name}</button>)
        //     else
        //         return <p>No current promotions</p>
        // }
        //
        //
        // return <p>Loading Promo</p>
        return (
            <div className="welcome">
                <img src={require('../../assets/images/logo-white.svg')} alt="Nespresso" className="welcome__logo"/>

                <div className="welcome__buttons">
                    {this.state.loaded ? (
                        <>
                            {this.state.promotions.map((value, index) => <button key={index} onClick={() => this.setPromotion(value.id_shop_promotion)}>{value.name} <span>➔</span></button>)}
                        </>
                    ) : <div className="welcome__text">{t('welcome.loading')}</div>}
                </div>

                <div className="welcome__text">NESPRESSO 2020</div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(PromotionLoader);
