import React, {Component} from 'react';
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import {connect} from 'react-redux';
import nl from 'date-fns/locale/nl';
import "react-datepicker/dist/react-datepicker.css";
import './Forms.css';
import moment from "moment";

registerLocale('nl', nl)

const mapStateToProps = (state) => ({
    lang: state.lang,
})

class FormDatePicker extends Component {

    reformatDate(date) {
        if(date.indexOf('/') != -1) {
            let _p = date.split('/');
            return _p.reverse().join('-')
        }
    }

    constructor(props) {
        super(props);

        let valid = true;

        if(props.isValid === false)
            valid = false; // custom

        let disabled = false;
        if(props.disabled === true)
            disabled = true;

        let value = new Date();
        if(props.value != null)
            value = props.value;

        this.required = typeof props.required === "undefined" ? true : props.required;

        this.state = {
            class: 'formGroup -datepicker' + (this.props.disabled ? ' -disabled' : ''),
            value: props.value,
            invalidFeedback: props.invalidFeedback,
            isValid: valid,
            disabled,
            touched: props.value ? true : false,
            loading: props.loading ?? false,
        };

        this.datePicker = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.invalidFeedback != this.state.invalidFeedback) {
            this.setState({
                invalidFeedback: this.props.invalidFeedback
            })
        }

        if(this.props.isValid != this.state.isValid) {
            this.setState({
                isValid: this.props.isValid
            })
        }

        if(this.props.disabled != this.state.disabled) {
            this.setState({
                disabled: this.props.disabled
            })
        }

        if(this.props.selected != this.state.selected) {
            this.setState({
                selected: this.props.selected
            })
        }

        if(this.props.touched != this.state.touched) {
            if(this.props.touched) {
                this.setState({
                    touched: this.props.touched,
                })
            }
            // this.setState({
            //     touched: this.props.touched,
            // })
        }

        if(this.props.loading != this.state.loading) {
            this.setState({
                loading: this.props.loading
            });
        }

        if(this.props.value != this.state.value) {
            this.setState({
                value: this.props.value
            });
        }
    }

    onFocusInput(addClass) {
        let classes = 'formGroup -datepicker';
        if(this.state.disabled)
            classes += ' -disabled';

        this.setState({class: addClass ? this.state.class + ' -focused' : classes,})

        if(this.props.onFocus)
            this.props.onFocus();
    }

    onBlurInput() {
        this.onFocusInput(false);
        if(!this.state.touched)
            this.setState({touched: true})
        if(this.props.onBlur)
            this.props.onBlur();
    }

    onChangeInput(value) {
        this.setState({value: value}, () => {
            this.props.onChange(value);
            this.datePicker.current.setOpen(false);
        });
    }

    onClickGroup() {
        this.datePicker.current.setOpen(true);
    }

    render() {

        let formGroupClasses = this.state.class;
        if(this.state.disabled)
            formGroupClasses += ' -disabled';

        if(this.state.touched && !this.state.isValid && this.required)
            formGroupClasses += ' -invalid';

        if(this.state.touched && this.state.isValid && this.required)
            formGroupClasses += ' -valid';

        return (
            <div className={formGroupClasses}>
                <div className="formGroup__field" onClick={() => this.onClickGroup()}>
                    <label htmlFor={this.props.name}>{this.props.label}</label>

                    <DatePicker selected={this.state.value?new Date(this.state.value.toString().split('/').reverse().join('-')):null}
                                value={this.state.value?new Date(this.state.value.toString().split('/').reverse().join('-')):null}
                                placeholderText="DD/MM/YYYY"
                                dateFormat="dd/MM/yyyy"
                                locale={this.props.lang}
                                maxDate={this.props.maxDate ?? null}
                                ref={this.datePicker}
                                onBlur={() => this.onBlurInput()}
                                onChange={e => this.onChangeInput(e)}
                                onFocus={() => this.onFocusInput(true)}
                    />

                    {this.state.loading ? (
                        <div className="formGroup__loading">
                            <img src={require('../../assets/images/icons/preloader2.gif')} alt="..."/>
                        </div>
                    ) : null}
                </div>


                {this.state.invalidFeedback?<span className="formGroup__invalidFeedback">{this.state.invalidFeedback}</span>:<></>}
            </div>
        );
    }
}

export default connect(mapStateToProps)(FormDatePicker);
