import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {StepCustom, Button, FormCheckbox, FormInput, FormSelect, Step} from "../index";
import { connect } from 'react-redux';
import {updateCustomer, translate as t, setLastValidStep} from "../../actions/store";
import {createMask, createRegex, isEmailValid} from "../../actions/utils";
import IMask from "imask";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../actions/actions';
import './CorpSubscriptionCustomerRegistration.css';


const mapDispatchToProps = (dispatch) => ({
    updateCustomer: (customer) => dispatch(updateCustomer(customer)),
    setLastValidStep: step => dispatch(setLastValidStep(step)),
})


const mapStateToProps = (state) => ({
    customer: state.customer,
    lang: state.lang,
    config: state.config,
    csAuthorization: state.csAuthorization,
})


class CorpSubscriptionCustomerRegistration extends Component {
    constructor(props) {
        super(props);

        this.zipCode = {
            pattern: createRegex(props.config.custom_postcode_format.options.format),
            mask: createMask(props.config.custom_postcode_format.options.format),
            length: props.config.custom_postcode_format.options.format.length,
        };
        this.phone = {
            pattern: /^[0-9]{0,15}$/,
            mask: /^[0-9]+$/,
            length: 15,
            // pattern: createRegex(props.config.custom_phone_number_format.value),
            // mask: createMask(props.config.custom_phone_number_format.value),
            // length: props.config.custom_phone_number_format.value.length,
        };

        this.flatNumber = {
            pattern: createRegex(props.config.custom_flat_number.options.format),
            mask: createMask(props.config.custom_flat_number.options.format),
            length: props.config.custom_flat_number.options.format.length,
        }
        this.floor = {
            pattern: createRegex(props.config.custom_floor_number.options.format),
            mask: createMask(props.config.custom_floor_number.options.format),
            length: props.config.custom_floor_number.options.format.length,
        }

        this.membershipNumber = {
            pattern: /^[0-9]{3,7}$/,
            mask: /^[0-9]+$/,
            length: 7,
            // pattern: createRegex(props.config.membership_number_mask.value),
            // mask: createMask(props.config.membership_number_mask.value),
            // length: props.config.membership_number_mask.value.length,
        }

        // console.log(this.zipCode);


        this.flatNumberRef = new React.createRef();
        this.flatNumberMask = null;
        this.floorRef = new React.createRef();
        this.floorMask = null;
        this.phoneRef = new React.createRef();
        this.phoneMask = null;
        this.zipCodeRef = new React.createRef();
        this.zipCodeMask = null;

        this.deliveryZipCodeRef = new React.createRef();
        this.deliveryZipCodeMask = null;
        this.deliveryFlatNumberRef = new React.createRef();
        this.deliveryFlatNumberMask = null;
        this.deliveryFloorRef = new React.createRef();
        this.deliveryFloorMask = null;
        this.deliveryPhoneRef = new React.createRef();
        this.deliveryPhoneMask = null;

        this.membershipNumberRef = new React.createRef();
        this.membershipNumberMask = null;


        this.state = props.customer?props.customer:{
            companyName: '',
            taxId: '',
            title: null,
            name: '',
            surname: '',

            address: '',
            address2: '',
            zipCode: '',
            city: '',

            membershipNumber: '',

            // street: '',
            // flatNumber: '',
            // floor: '',

            country: null,
            phone: '',
            email: '',
            // reEmail: '',
            lang: {
                label: t('country.at'),
                value: 'at',
            },

            delivery: {
                companyAddress: false,
                companyName: '',
                title: null,
                name: '',
                surname: '',
                zipCode: '',
                city: '',
                address: '',
                address2: '',

                // street: '',
                // flatNumber: '',
                // floor: '',
                // phone: '',
            },

            zipCodeInvalidFeedback: t('s_customer.zip_code') + t('is_required'),

            companyAddress: false,
            customDeliverAddress: false,

            receiveBenefitsEmail: false,
            receiveBenefitsPhone: false,
            termsAndConditions: false,
            privacyPolicy: false,

            receivePost: false,

            forceTouched: false,
        }

        this.titleOptions = [
            {
                label: t('male_title'),
                value: 'M',
            },
            {
                label: t('female_title'),
                value: 'F',
            },
        ]

        this.countries = [
            // {
            //     label: 'Poland',
            //     value: 'pl',
            // },
            // {
            //     label: 'Germany',
            //     value: 'de',
            // },
            {
                label: t('country.at'),
                value: 'at',
            },
        ]

        this.langs = [
            {
                label: 'DE',
                value: 'de',
            },
            {
                label: 'EN',
                value: 'en',
            },
        ];
    }

    componentDidMount() {

        // if(this.flatNumberRef) {
        //     const maskOptions = {
        //         mask: this.flatNumber.mask,
        //         lazy: false,
        //         overwrite: true,
        //         autofix: true,
        //     };
        //     this.flatNumberMask = IMask(this.flatNumberRef.current.input.current, maskOptions);
        //     this.flatNumberMask.on('accept', () => {
        //         this.setState({flatNumber: this.flatNumberMask.value})
        //     })
        // }
        // if(this.floorRef) {
        //     const maskOptions = {
        //         mask: this.floor.mask,
        //         lazy: false,
        //         overwrite: true,
        //         autofix: true,
        //     };
        //     this.floorMask = IMask(this.floorRef.current.input.current, maskOptions);
        //     this.floorMask.on('accept', () => {
        //         this.setState({floor: this.floorMask.value})
        //     })
        // }
        if(this.membershipNumberRef) {
            const maskOptions = {
                mask: this.membershipNumber.mask,
                lazy: false,
                overwrite: true,
                autofix: true,
            };
            this.membershipNumberMask = IMask(this.membershipNumberRef.current.input.current, maskOptions);
            this.membershipNumberMask.on('accept', () => {
                this.setState({membershipNumber: this.membershipNumberMask.value})
            })
        }
        if(this.phoneRef) {
            const maskOptions = {
                mask: this.phone.mask,
                lazy: false,
                overwrite: true,
                autofix: true,
            };
            this.phoneMask = IMask(this.phoneRef.current.input.current, maskOptions);
            this.phoneMask.on('accept', () => {
                this.setState({phone: this.phoneMask.value})
            })
        }
        if(this.zipCodeRef) {
            const maskOptions = {
                mask: this.zipCode.mask,
                lazy: false,
                overwrite: true,
                autofix: true,
            };
            this.zipCodeMask = IMask(this.zipCodeRef.current.input.current, maskOptions);
            this.zipCodeMask.on('accept', () => {
                this.setState({zipCode: this.zipCodeMask.value})
            })
        }
        if(this.deliveryZipCodeRef) {
            const maskOptions = {
                mask: this.zipCode.mask,
                lazy: false,
                overwrite: true,
                autofix: true,
            };
            this.deliveryZipCodeMask = IMask(this.deliveryZipCodeRef.current.input.current, maskOptions);
            this.deliveryZipCodeMask.on('accept', () => {
                this.setState({
                    delivery: {
                        ...this.state.delivery,
                        zipCode: this.deliveryZipCodeMask.value
                    }
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.updateCustomer(this.state);

        if(prevState !== this.state) {
            this.props.keepSession();
        }
    }


    nextStep() {
        if(this.validateStep()) {
            console.log('valid=next step')
            this.props.showStep(this.props.stepNo + 1)
        }
    }

    validateStep() {
        let valid = true;

        if(this.state.companyAddress) {
            if(!this.state.companyName) {
                valid = false;
                console.log('not valuid company name')
            }
        }
        else {
        }

        // if(!this.state.taxId) {
        //     valid = false;
        //     console.log('not valuid taxid', this.state.taxId)
        //
        // }

        if(!this.state.title) {
            valid = false;
            console.log('not valuid title')
        }
        if(!this.state.name) {
            valid = false;
            console.log('not valuid name')

        }
        if(!this.state.surname ) {
            valid = false;
            console.log('not valuid surname')

        }

        if(!this.state.zipCode || !this.state.zipCode.match(this.zipCode.pattern)) {
            valid = false;
            console.log('invalid zipcode')
        }
        if(!this.state.city) {
            valid = false;
            console.log('not valuid city')

        }
        // if(!this.state.street ) {
        //     valid = false;
        //     console.log('not valuid street')
        //
        // }
        // if(this.props.config.custom_flat_number.options.obligatory == 'on' && !this.state.flatNumber) {
        //     valid = false;
        //     console.log('not valuid flatnumber')
        //
        // }
        // if(this.props.config.custom_floor_number.options.obligatory == 'on' && !this.state.floor ) {
        //     valid = false;
        //     console.log('not valuid floor')
        //
        // }
        if(!this.state.address) {
            valid = false;
        }
        // if(!this.state.address2) {
        //     valid = false;
        // }
        if(!this.state.lang ) {
            valid = false;
            console.log('not valid lang')

        }
        if(this.state.phone && !this.state.phone.match(this.phone.pattern)) {
            valid = false;
        }
        if(!this.state.email
            || this.props.csAuthorization.email === this.state.email
            || this.emailDomain(this.state.email) === this.emailDomain(this.props.csAuthorization.email)
            || !isEmailValid(this.state.email)) {
            valid = false;
            console.log('not valuid email',this.emailDomain(this.state.email) !== this.emailDomain(this.props.csAuthorization.email))
        }
        // if(this.props.config.additional_email.value == 'on' && this.state.reEmail !== this.state.email) {
        //     valid = false;
        //     console.log('not valuid email')
        //
        // }
        if(!this.state.termsAndConditions) {
            valid = false;
        }
        if(!this.state.privacyPolicy) {
            valid = false;
        }


        if(this.state.membershipNumber && !this.state.membershipNumber.match(this.membershipNumber.pattern)) {
            valid = false;
        }



        // delivery
        if(this.state.customDeliverAddress) {
            // console.log('not valuid customdevl')

            if(this.state.delivery.companyAddress) {
                if(!this.state.delivery.companyName) {
                    valid = false;
                }
            }
            else {
                if(!this.state.delivery.title) {
                    valid = false;
                }
            }


            if(!this.state.delivery.name) {
                valid = false;
            }
            if(!this.state.delivery.surname) {
                valid = false;
            }
            if(!this.state.delivery.zipCode || !this.state.delivery.zipCode.match(this.zipCode.pattern)) {
                valid = false;
            }
            if(!this.state.delivery.city) {
                valid = false;
            }
            if(!this.state.delivery.address) {
                valid = false;
            }
            // if(!this.state.delivery.address2) {
            //     valid = false;
            // }
            // if(!this.state.delivery.street ) {
            //     valid = false;
            // }
            // if(this.props.config.custom_floor_number.options.obligatory == 'on' && !this.state.delivery.floor) {
            //     valid = false;
            // }
            // if(this.props.config.custom_flat_number.options.obligatory == 'on' && !this.state.delivery.flatNumber) {
            //     valid = false;
            // }
            // if(!this.state.delivery.phone || !this.state.delivery.phone.match(this.phone.pattern)) {
            //     valid = false;
            // }
        }

        if(valid) {
            this.props.setLastValidStep(this.props.stepNo);
        }

        // this.setState({forceTouched: true})


        return valid;
    }

    isFlatNumberValid() {
        if(this.state.flatNumber.replaceAll('_', '').replaceAll('-', '').length > 0) {
            return this.state.flatNumber.match(this.flatNumber.pattern);
        }
        else {
            return !(this.props.config.custom_flat_number.options.obligatory == 'on');
        }
    }

    isFloorValid() {
        if(this.state.floor.replaceAll('_', '').replaceAll('-', '').length > 0) {
            return this.state.floor.match(this.floor.pattern);
        }
        else {
            return !(this.props.config.custom_floor_number.options.obligatory == 'on');
        }
    }

    isDeliveryFlatNumberValid() {
        if(this.state.delivery.flatNumber.replaceAll('_', '').replaceAll('-', '').length > 0) {
            return this.state.delivery.flatNumber.match(this.flatNumber.pattern);
        }
        else {
            return !(this.props.config.custom_flat_number.options.obligatory == 'on');
        }
    }

    isDeliveryFloorValid() {
        if(this.state.delivery.floor.replaceAll('_', '').replaceAll('-', '').length > 0) {
            return this.state.delivery.floor.match(this.floor.pattern);
        }
        else {
            return !(this.props.config.custom_floor_number.options.obligatory == 'on');
        }
    }

    emailDomain = (email) => {
        return email.split('@')[1];
    }

    render() {
        return (
            <StepCustom label={t('cs_customer._title')}
                        stepNo={this.props.stepNo}
                        currentStep={this.props.currentStep}
                        showStep={step => this.props.showStep(step)}
                        // nextStep={step => this.props.nextStep(step)}
            >

                <div className="customerRegistration">
                    {/*<div className="customerRegistration__header">*/}
                    {/*    <p className="par-2 --semiBold">{t('s_customer.text1')}</p>*/}
                    {/*</div>*/}


                    <form action="">

                        <div className="grid">
                            <FormSelect name="title" label={'* '+t('s_customer.title')}
                                        isValid={this.state.title?.value?.length > 0}
                                        invalidFeedback={t('is_required', t('s_customer.title')) }
                                        options={this.titleOptions}
                                        selected={this.state.title}
                                        touched={this.state.forceTouched}
                                        onChange={v => this.setState({title: v})}
                            />
                        </div>

                        <div className="grid">
                            <div>
                                <p
                                    className="par-3"
                                    dangerouslySetInnerHTML={{__html: t('s_customer.membership_number_info')}}
                                />
                                <FormInput name="membership_number" label={t('s_customer.membership_number')} type="text"
                                           value={this.state.membershipNumber}
                                           isValid={this.state.membershipNumber.length > 0 ? this.state.membershipNumber.match(this.membershipNumber.pattern) : true}
                                           invalidFeedback={t('incorrect', t('s_customer.membership_number'))}
                                           touched={this.state.forceTouched}
                                           ref={this.membershipNumberRef}
                                           required={false}
                                />
                            </div>
                        </div>


                        <div className="grid">
                            <FormInput name="surname" label={`* ${t('s_customer.surname')}`} type="text"
                                       invalidFeedback={t('is_required', t('s_customer.surname'))}
                                       value={this.state.surname}
                                       isValid={this.state.surname.length > 0}
                                       touched={this.state.forceTouched}
                                // onChange={v => this.setState({surname: v})}
                                       onChange={v => !v.match(/\d/) ? this.setState({surname: v}) : null}
                            />
                            <FormInput name="name" label={`* ${t('s_customer.name')}`} type="text"
                                       invalidFeedback={t('is_required', t('s_customer.name'))}
                                       value={this.state.name}
                                       isValid={this.state.name.length > 0}
                                       touched={this.state.forceTouched}
                                       // onChange={v => this.setState({name: v})}
                                       onChange={v => !v.match(/\d/) ? this.setState({name: v}) : null}
                            />
                        </div>

                        <div className="grid -full">
                            <FormInput name="address" label={`* ${t('s_customer.address')}`} type="text"
                                       invalidFeedback={t('is_required', t('s_customer.address'))}
                                       value={this.state.address}
                                       isValid={this.state.address.length > 0}
                                       touched={this.state.forceTouched}
                                       onChange={v => this.setState({address: v})}
                            />
                        </div>
                        <div className="grid -full">
                            <FormInput name="address2" label={`${t('s_customer.address2')}`} type="text"
                                       invalidFeedback={t('is_required', t('s_customer.address2'))}
                                       value={this.state.address2}
                                       isValid={true}
                                       touched={this.state.forceTouched}
                                       onChange={v => this.setState({address2: v})}
                                       required={false}
                            />
                        </div>

                        <div className="grid">
                            <FormInput name="zip_code" label={`* ${t('s_customer.zip_code2')}`} type="text"
                                       value={this.state.zipCode}
                                       isValid={this.state.zipCode.length > 0 && this.state.zipCode.match(this.zipCode.pattern)}
                                       invalidFeedback={t('incorrect', t('s_customer.zip_code2'))}
                                       touched={this.state.forceTouched}
                                       ref={this.zipCodeRef}
                            />
                            <FormInput name="city" label={`* ${t('s_customer.city2')}`} type="text"
                                       invalidFeedback={t('is_required', t('s_customer.city2'))}
                                       value={this.state.city}
                                       isValid={this.state.city.length > 0}
                                       touched={this.state.forceTouched}
                                       onChange={v => this.setState({city: v})}
                            />
                        </div>
                        {/*<div className="grid">*/}
                        {/*    <FormInput name="street" label={`* ${t('s_customer.street')}`} type="text"*/}
                        {/*               invalidFeedback={t('is_required', t('s_customer.street'))}*/}
                        {/*               value={this.state.street}*/}
                        {/*               isValid={this.state.street.length > 0}*/}
                        {/*               touched={this.state.forceTouched}*/}
                        {/*               onChange={v => this.setState({street: v})}*/}
                        {/*    />*/}
                        {/*    <div className="grid -fromXs">*/}
                        {/*        {this.props.config.custom_flat_number.value == 'on' ? (*/}
                        {/*            <FormInput name="flat_number" label={'* '+t('s_customer.flat_number')} type="text"*/}
                        {/*                       value={this.state.flatNumber}*/}
                        {/*                       // isValid={this.isFlatNumberValid()}*/}
                        {/*                       isValid={this.props.config.custom_flat_number.options.obligatory == 'on' && !!this.state.flatNumber}*/}
                        {/*                       invalidFeedback={t('incorrect', t('s_customer.flat_number'))}*/}
                        {/*                       touched={this.state.forceTouched}*/}
                        {/*                       onChange={v => v.match(/^([a-zA-Z]|\d)*$/) && v.length <= this.flatNumber.length ? this.setState({flatNumber: v}) : null}*/}
                        {/*                       // ref={this.flatNumberRef}*/}
                        {/*            />*/}
                        {/*        ) : null}*/}
                        {/*        {this.props.config.custom_floor_number.value == 'on' ? (*/}
                        {/*            <FormInput name="floor" label={t('s_customer.floor')} type="text"*/}
                        {/*                       value={this.state.floor}*/}
                        {/*                       // isValid={this.isFloorValid()}*/}
                        {/*                       isValid={!!this.floor}*/}
                        {/*                       invalidFeedback={t('incorrect', t('s_customer.floor'))}*/}
                        {/*                       touched={this.state.forceTouched}*/}
                        {/*                       required={false}*/}
                        {/*                       onChange={v => v.match(/^([a-zA-Z]|\d)*$/) && v.length <= this.floor.length ? this.setState({floor: v}) : null}*/}
                        {/*                // ref={this.floorRef}*/}
                        {/*            />*/}
                        {/*        ) : null}*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="grid -full">*/}
                        {/*    <FormSelect name="country" label={'* '+t('s_customer.country')}*/}
                        {/*                isValid={this.state.country?.value?.length > 0}*/}
                        {/*                invalidFeedback={t('is_required', t('s_customer.country')) }*/}
                        {/*                isSearchable={true}*/}
                        {/*                options={this.countries}*/}
                        {/*                selected={this.state.country}*/}
                        {/*                touched={this.state.forceTouched}*/}
                        {/*                onChange={v => this.setState({country: v})}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        <div className="grid -full">
                            <FormSelect name="lang" label={'* '+t('s_customer.country')}
                                        isValid={this.state.lang?.value?.length > 0}
                                        invalidFeedback={t('is_required', t('s_customer.country')) }
                                        isSearchable={true}
                                        options={this.countries}
                                        selected={this.state.lang}
                                        touched={this.state.forceTouched}
                                        onChange={v => this.setState({lang: v})}
                            />
                        </div>

                        <div className="grid -full">
                            <FormInput name="phone" label={`${t('s_customer.phone2')}`} type="text"
                                       value={this.state.phone}
                                       isValid={this.state.phone.length > 0 ? this.state.phone.match(this.phone.pattern) : true}
                                       invalidFeedback={t('incorrect', t('s_customer.phone2')) }
                                       touched={this.state.forceTouched}
                                       ref={this.phoneRef}
                                       required={false}
                            />
                        </div>
                        {/*<div className="grid -full">*/}
                        {/*    <p className="par-3" dangerouslySetInnerHTML={{__html: t('cs_customer.email_text')}}/>*/}
                        {/*</div>*/}
                        <div className="grid -full -mb4">
                            <FormInput name="email" label={`* ${t('s_customer.email')}`} type="email"
                                       invalidFeedback={this.emailDomain(this.state.email) === this.emailDomain(this.props.csAuthorization.email) ? t('cs_authorization.email_must_be_private') : t('is_required', t('s_customer.email'))}
                                       value={this.state.email}
                                       isValid={this.state.email.length > 0 && this.props.csAuthorization.email !== this.state.email && isEmailValid(this.state.email) && this.emailDomain(this.state.email) !== this.emailDomain(this.props.csAuthorization.email)}
                                       touched={this.state.forceTouched}
                                       onChange={v => this.setState({email: v})}
                            />
                            {/*{this.props.config.additional_email.value == 'on' ? (*/}
                            {/*    <FormInput name="re_email" label={`* ${t('s_customer.re_email')}`} type="email"*/}
                            {/*               invalidFeedback={this.props.csAuthorization.email === this.state.email ? t('cs_authorization.email_must_be_private') : t('is_required', t('s_customer.re_email')) }*/}
                            {/*               value={this.state.reEmail}*/}
                            {/*               isValid={this.state.reEmail.length > 0 && this.state.reEmail == this.state.email && this.props.csAuthorization.email !== this.state.email}*/}
                            {/*               touched={this.state.forceTouched}*/}
                            {/*               onChange={v => this.setState({reEmail: v})}*/}
                            {/*    />*/}
                            {/*) : null}*/}
                        </div>


                        <div className="grid -mb4">
                            <FormCheckbox value={this.state.customDeliverAddress}
                                          isValid={true}
                                          onChange={v => this.setState({customDeliverAddress: v})}>
                                {t('s_customer.anotherAddress')}
                            </FormCheckbox>
                        </div>




                        <div hidden={!this.state.customDeliverAddress}>
                            <div className="grid -mb4">
                                <FormCheckbox value={this.state.delivery.companyAddress}
                                              isValid={true}
                                              onChange={v => this.setState({
                                                  delivery: {
                                                      ...this.state.delivery,
                                                      companyAddress: v
                                                  }
                                              })}>
                                    {t('s_customer.companyAddress')}
                                </FormCheckbox>
                            </div>

                            {this.state.delivery.companyAddress ? (
                                <>
                                    <div className="grid">
                                        <FormInput name="delivery_company_name" label={`* ${t('s_customer.company_name')}`} type="text"
                                                   value={this.state.delivery.companyName}
                                                   isValid={this.state.delivery.companyName.length > 0}
                                                   touched={this.state.forceTouched}
                                                   onChange={v => this.setState({
                                                       delivery: {
                                                           ...this.state.delivery,
                                                           companyName: v
                                                       }
                                                   })}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="grid">
                                        <FormSelect name="delivery_title" label={t('s_customer.title')}
                                                    isValid={this.state.delivery.title?.value?.length > 0}
                                            // invalidFeedback="Trade partner is required"
                                                    options={this.titleOptions}
                                                    selected={this.state.delivery.title}
                                                    touched={this.state.forceTouched}
                                                    onChange={v => this.setState({
                                                        delivery: {
                                                            ...this.state.delivery,
                                                            title: v
                                                        }
                                                    })}
                                        />
                                    </div>
                                </>
                            )}

                            <div className="grid">
                                <FormInput name="delivery_surname" label={`* ${t('s_customer.surname')}`} type="text"
                                           value={this.state.delivery.surname}
                                           isValid={this.state.delivery.surname.length > 0}
                                           touched={this.state.forceTouched}
                                           onChange={v => this.setState({
                                               delivery: {
                                                   ...this.state.delivery,
                                                   surname: v
                                               }
                                           })}
                                />
                                <FormInput name="delivery_name" label={`* ${t('s_customer.name')}`} type="text"
                                           value={this.state.delivery.name}
                                           isValid={this.state.delivery.name.length > 0}
                                           touched={this.state.forceTouched}
                                           onChange={v => this.setState({
                                               delivery: {
                                                   ...this.state.delivery,
                                                   name: v
                                               }
                                           })}
                                />
                            </div>

                            <div className="grid">
                                <FormInput name="delivery_zip_code" label={`* ${t('s_customer.zip_code')}`} type="text"
                                           value={this.state.delivery.zipCode}
                                           isValid={this.state.delivery.zipCode.length > 0 && this.state.delivery.zipCode.match(this.zipCode.pattern)}
                                           invalidFeedback={t('incorrect', t('s_customer.zip_code'))}
                                           touched={this.state.forceTouched}
                                           ref={this.deliveryZipCodeRef}
                                />
                                <FormInput name="delivery_city" label={`* ${t('s_customer.city')}`} type="text"
                                           value={this.state.delivery.city}
                                           isValid={this.state.delivery.city.length > 0}
                                           touched={this.state.forceTouched}
                                           onChange={v => this.setState({
                                               delivery: {
                                                   ...this.state.delivery,
                                                   city: v
                                               }
                                           })}
                                />
                            </div>
                            <div className="grid -full">
                                <FormInput name="delivery_address" label={`* ${t('s_customer.address')}`} type="text"
                                           value={this.state.delivery.address}
                                           isValid={this.state.delivery.address.length > 0}
                                           touched={this.state.forceTouched}
                                           onChange={v => this.setState({
                                               delivery: {
                                                   ...this.state.delivery,
                                                   address: v
                                               }
                                           })}
                                />
                            </div>
                            <div className="grid -full">
                                <FormInput name="delivery_address2" label={`${t('s_customer.address2')}`} type="text"
                                           value={this.state.delivery.address2}
                                           isValid={true}
                                           touched={this.state.forceTouched}
                                           onChange={v => this.setState({
                                               delivery: {
                                                   ...this.state.delivery,
                                                   address2: v
                                               }
                                           })}
                                           required={false}
                                />
                            </div>
                        </div>


                        {/*<div className="grid">*/}
                        <FormCheckbox value={this.state.termsAndConditions}
                                      customClass="-green"
                                      isValid={this.state.termsAndConditions}
                                      invalidFeedback={'Terms and conditions are required'}
                                      touched={this.state.forceTouched}
                                      onChange={v => this.setState({termsAndConditions: v})}>
                            <a href={t('cs_terms_and_conditions_url')} target="_blank"
                               onClick={e => e.stopPropagation()}
                               className="--brown" dangerouslySetInnerHTML={{__html: t('cs_customer.terms')}}/>
                        </FormCheckbox>
                        {/*</div>*/}
                        {/*<div className="grid -mb4">*/}
                        <FormCheckbox value={this.state.privacyPolicy}
                                      customClass="-green"
                                      isValid={this.state.privacyPolicy}
                                      invalidFeedback={'Privacy policy is required'}
                                      touched={this.state.forceTouched}
                                      onChange={v => this.setState({privacyPolicy: v})}>
                            <a href={t('legal_url')} target="_blank"
                               onClick={e => e.stopPropagation()}
                               className="--brown" dangerouslySetInnerHTML={{__html: t('cs_customer.privacy')}}/>
                        </FormCheckbox>
                        {/*</div>*/}

                        <input type="hidden" value={this.state.receivePost}/>


                        <div className="customerRegistration__requiredInfo --mb3">
                            <p className="par-4">{t('required_info')}</p>
                        </div>

                            <div className="customerRegistration__infoHeader" dangerouslySetInnerHTML={{__html: t('s_customer.info_header')}}/>

                            <div className="customerRegistration__info">
                                <div className="customerRegistration__infoText">
                                    <img src={require('../../assets/images/icons/egifcard.png')} alt="-"/>
                                    <span dangerouslySetInnerHTML={{__html: t('s_customer.exclusive_offers')}}/>
                                </div>
                                <div className="customerRegistration__infoText">
                                    <img src={require('../../assets/images/icons/present.png')} alt="-"/>
                                    <span dangerouslySetInnerHTML={{__html: t('s_customer.gifts')}}/>
                                </div>
                                <div className="customerRegistration__infoText">
                                    <img src={require('../../assets/images/icons/beans.png')} alt="-"/>
                                    <span dangerouslySetInnerHTML={{__html: t('s_customer.latest_news')}}/>
                                </div>
                                <div className="customerRegistration__infoText">
                                    <img src={require('../../assets/images/icons/nieuws.png')} alt="-"/>
                                    <span dangerouslySetInnerHTML={{__html: t('s_customer.events')}}/>
                                </div>
                            </div>

                        {/*<div className="grid">*/}
                        <div className="customerRegistration__text --mb4">
                            <p dangerouslySetInnerHTML={{__html: t('s_customer.info1')}}/>
                            {/*<p>*/}
                            {/*    <span dangerouslySetInnerHTML={{__html: t('s_customer.info2')}}/>*/}
                            {/*    {!this.state.receivePost ? (*/}
                            {/*        <p className="--brown">{t('s_customer.not_receive_post')}</p>*/}
                            {/*    ) : (*/}
                            {/*        <>*/}
                            {/*            &nbsp;<a href="" className="--brown"*/}
                            {/*                     onClick={e => {*/}
                            {/*                         e.preventDefault();*/}
                            {/*                         this.setState({receivePost: false})*/}
                            {/*                     }}>{t('click_here')}</a>*/}
                            {/*        </>*/}
                            {/*    )}*/}
                            {/*</p>*/}

                            {/*<p dangerouslySetInnerHTML={{__html: t('s_customer.info3')}}/>*/}
                        </div>
                        {/*</div>*/}

                        {/*<div className="grid">*/}
                            <FormCheckbox value={this.state.receiveBenefitsEmail}
                                          customClass="-green"
                                          isValid={true}
                                          onChange={v => this.setState({receiveBenefitsEmail: v})}>
                                <div dangerouslySetInnerHTML={{__html: t('s_customer.text2')}}/>
                            </FormCheckbox>
                        {/*</div>*/}

                        {/*<div className="grid">*/}
                            <FormCheckbox value={this.state.receiveBenefitsPhone}
                                          customClass="-green"
                                          isValid={true}
                                          onChange={v => this.setState({receiveBenefitsPhone: v})}>
                                <div dangerouslySetInnerHTML={{__html: t('s_customer.text3')}}/>
                            </FormCheckbox>

                        <FormCheckbox value={this.state.receivePost}
                                      customClass="-green"
                                      isValid={true}
                                      onChange={v => this.setState({receivePost: v})}>
                            <div dangerouslySetInnerHTML={{__html: t('s_customer.text3_1')}}/>
                        </FormCheckbox>
                        {/*</div>*/}

                    </form>

                </div>

                <div className="step__nav">
                    <Button class="-primary -outline" label={t('back')} disabled={false} onClick={() => this.props.showStep(this.props.stepNo - 1)}/>

                    <Button class="-primary"
                            label={t('continue')}
                            disabled={!this.validateStep()}
                            onClick={() => this.nextStep()}
                            tooltip={this.validateStep() ? null : t('fill_all_fields')}
                    />
                </div>


                {/*<button onClick={() => this.props.nextStep()}>continue</button>*/}

            </StepCustom>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorpSubscriptionCustomerRegistration);
