import React, {Component} from 'react';
import './Forms.css';
import Select from "react-select";

const selectStyles = {
    control: () => (
        {
            display: 'flex',
            paddingLeft: '0.93rem',
            paddingRight: '0.93rem',
        }
    ),
    valueContainer: (provider, state) => (
        {
            ...provider,
            padding: '0',
        }
    ),
    indicatorsContainer: (provider, state) => (
        {
            ...provider,
            padding: 0,
        }
    ),
    indicatorSeparator: (provider, state) => (
        {
            display: 'none'
        }
    ),
    dropdownIndicator: (provider, state) => (
        {
            ...provider,
            padding: 0,
        }
    ),
    placeholder: (provider, state) => (
        {
            ...provider,
            fontSize: '0.875em',
            lineHeight: 1,
            color: '#000000',
        }
    ),
    input: (provider, state) => (
        {
            ...provider,
            padding: 0,
            lineHeight: 1,
        }
    ),
    menu: (provider, state) => (
        {
            ...provider,
            top: 'calc(100% + 0.3rem)',
            fontSize: '0.875em',
            color: '#000000',
            borderColor: 'blue',
            borderRadius: '8px',
        }
    ),
};

class FormSelect extends Component {
    constructor(props) {
        super(props);

        let valid = true;

        if(props.isValid === false)
            valid = false

        let disabled = false;
        if(props.disabled === true)
            disabled = true;

        this.required = typeof props.required === "undefined" ? true : props.required;

        this.state = {
            class: 'formGroup -select' + (this.props.disabled ? ' -disabled' : ''),
            value: props.value,
            invalidFeedback: props.invalidFeedback,
            isValid: valid,
            disabled,
            selected: props.selected,
            touched: props.selected ? true : false,
            loading: props.loading ?? false,
        };

        this.select = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.invalidFeedback != this.state.invalidFeedback) {
            this.setState({
                invalidFeedback: this.props.invalidFeedback
            })
        }

        if(this.props.isValid != this.state.isValid) {
            this.setState({
                isValid: this.props.isValid
            })
        }

        if(this.props.disabled != this.state.disabled) {
            this.setState({
                disabled: this.props.disabled
            })
        }

        if(this.props.selected != this.state.selected) {
            this.setState({
                selected: this.props.selected
            })
        }

        if(this.props.touched != this.state.touched) {
            if(this.props.touched) {
                this.setState({
                    touched: this.props.touched,
                })
            }
            // this.setState({
            //     touched: this.props.touched,
            // })
        }

        if(this.props.loading != this.state.loading) {
            this.setState({
                loading: this.props.loading
            });
        }
    }

    onFocusInput(addClass) {
        let classes = 'formGroup -select';
        if(this.state.disabled)
            classes += ' -disabled';

        this.setState({ class: addClass ? this.state.class + ' -focused' : classes})

        if(this.props.onFocus)
            this.props.onFocus();
    }

    onBlurInput() {
        this.onFocusInput(false);
        if(this.props.onBlur)
            this.props.onBlur();
        this.setState({touched: true}, () => {
            this.select.current.blur()
        })
    }

    onChangeInput(value) {
        this.setState({value: value});
        this.props.onChange(value);
    }

    onClickGroup() {
        this.select.current.focus();
    }

    render() {

        let formGroupClasses = this.state.class;
        if(this.state.disabled)
            formGroupClasses += ' -disabled';

        if(!this.state.isValid && this.state.touched && this.required)
            formGroupClasses += ' -invalid';

        if(this.state.isValid && this.state.touched && this.required)
            formGroupClasses += ' -valid';

        if(this.state.selected !== null)
            formGroupClasses += ' -active';

        return (
            <div className={formGroupClasses}>
                <div className="formGroup__field" onClick={() => this.onClickGroup()}>
                    <label htmlFor={this.props.name}>{this.props.label}</label>

                    <Select styles={selectStyles}
                            placeholder={null}
                            openMenuOnFocus={true}
                            isSearchable={this.props.isSearchable ?? false}
                            options={this.props.options}
                            value={this.state.selected}
                            ref={this.select}
                            onBlur={() => this.onBlurInput()}
                            onChange={e => this.onChangeInput(e)}
                            onFocus={() => this.onFocusInput(true)}
                    />

                    {this.state.loading ? (
                        <div className="formGroup__loading">
                            <img src={require('../../assets/images/icons/preloader2.gif')} alt="..."/>
                        </div>
                    ) : null}
                </div>

                {this.state.invalidFeedback?<span className="formGroup__invalidFeedback">{this.state.invalidFeedback}</span>:<></>}
            </div>
        );
    }
}

export default FormSelect;
