import React, {Component} from 'react';
import './Buttons.css';
import ReactTooltip from "react-tooltip";

class Button extends Component {
    constructor(props) {
        super(props);

        this.state = {
            btnClasses: this.props.class ? 'btn ' + this.props.class : 'btn',
            disabled: props.disabled
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.disabled != this.state.disabled) {
            this.setState({
                disabled: this.props.disabled
            })
        }
    }

    render() {
        return (
            <span
                data-tip={this.props.tooltip ?? ''}
                data-tip-disable={!this.props.tooltip}
            >
                <button className={this.state.btnClasses}
                        disabled={this.state.disabled}
                        style={{...this.props.style}}
                        onClick={e => this.props.onClick(e)}
                >
                    {this.props.label ?? this.props.children}
                </button>
                {!!this.props.tooltip && <ReactTooltip place="top" effect="solid"/>}
            </span>
        );
    }
}

export default Button;
