import React, {Component} from 'react';
import {translate as t} from "../../actions/store";
import {Alert} from "../index";

class CartValidationAlert extends Component {
    constructor(props) {
        super(props);
    }

    cartValidation() {
        let value = 0;
        this.props.cart.items.map(i => {
            value += i.value;
        })

        return {
            isValid: value >= this.props.csGear.corporate_subscription_plan_subrebate_price,
            type: 'error',
            text: t('tip.capsules_minimum_count', this.props.csGear.corporate_subscription_plan_subrebate_price),
        };
    }

    render() {
        const cartValidation = this.cartValidation();
        let cartAlert = null;
        if(!cartValidation.isValid && this.props.cart.items.length > 0)
        {
            cartAlert = <div className="cartValidationAlert">
                <Alert className={`-${cartValidation.type}`}>{cartValidation.text}</Alert>
            </div>

        }

        return cartAlert;
    }
}

export default CartValidationAlert;
