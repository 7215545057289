import React, {Component} from "react";
import './Step.css';


class StepCustom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stepClass: 'step',
        }

        this.stepRef = React.createRef();
    }

    componentDidMount() {
        this.handleStepChange()

        const observer  = new MutationObserver(list => {
            const body = this.stepRef.current.querySelector('.step__body');
            const bodyContent = this.stepRef.current.querySelector('.step__bodyContent');

            if(this.props.stepNo === this.props.currentStep) {
                // body.style.maxHeight = bodyContent.scrollHeight + 'px';
            }
            else {
                // body.style.maxHeight = 0;
            }
        })

        observer.observe(this.stepRef.current, {attributes: true, childList: true, subtree: true})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.currentStep !== this.props.currentStep) {
            this.handleStepChange();
        }
    }

    handleStepChange() {
        const body = this.stepRef.current.querySelector('.step__body');
        const bodyContent = this.stepRef.current.querySelector('.step__bodyContent');

        if(this.props.stepNo === this.props.currentStep) {
            // body.style.maxHeight = bodyContent.scrollHeight + 'px';
            this.setState({
                stepClass: 'step -active',
            }, () => {
                setTimeout(() => {
                    this.stepRef.current.classList.add('-opened');
                }, 600);
            })
        }
        else {
            // body.style.maxHeight = 0;
            this.setState({
                stepClass: 'step',
            })
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }

    render() {
        let circleContent = this.props.stepNo;
        if(this.props.currentStep > this.props.stepNo) {
            circleContent = <img src={require('../../assets/images/icons/checked.svg')} alt="-"/>
        }

        let circleClass = 'step__circle';
        if(this.props.currentStep >= this.props.stepNo) {
            circleClass += ' -active';
        }
        const circle = <div className={circleClass}>
            {circleContent}
        </div>


        return (
            <div className={this.state.stepClass} ref={this.stepRef}>
                <div className="step__header">
                    <div className="wrapper-bg">
                        <div className="wrapper">
                            <div className="step__headerContent" onClick={() => this.props.showStep(this.props.stepNo)}>
                                {circle}
                                <h1 className="step__title" dangerouslySetInnerHTML={{__html: this.props.label}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="step__body">
                    <div className="wrapper-bg">
                        <div className="wrapper">
                            <div className="step__bodyContent">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StepCustom;
