import React, {Component} from 'react';
import './Popups.css';
import {GetInTouch, Popup} from "../index";
import {translate as t} from "../../actions/store";

class FindSerialNumberPopup extends Component {

    render() {
        return (
            <Popup show={this.props.show} onClose={() => this.props.onClose()}>
                <div className="popup__header">
                    <h3 className="popup__title">{t('p_find_serial.title')}</h3>
                </div>

                <div className="popup__body">
                    <div className="findSerialNumberPopup">
                        <div className="findSerialNumberPopup__thumb">
                            <img src={require('../../assets/images/machine.png')} alt="Machine"/>
                        </div>
                        <div className="findSerialNumberPopup__content">
                            <div className="findSerialNumberPopup__text">
                                <p className="par-2" dangerouslySetInnerHTML={{__html: t('p_find_serial.text1')}}/>
                            </div>
                            <img src={require('../../assets/images/find-code.png')} alt="Code" className="findSerialNumberPopup__img"/>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default FindSerialNumberPopup;
