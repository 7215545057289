import React, {Component} from 'react';
import './Popups.css';
import {Button, GetInTouch, Popup} from "../index";
import { connect } from 'react-redux'
import { showPopup, translate as t} from "../../actions/store";

const mapDispatchToProps = (dispatch) => ({
    showPopup: (popup, show) => dispatch(showPopup(popup, show))
})

const mapStateToProps = (state) => ({
    getInTouchPopup: state.getInTouchPopup
})

class GetInTouchPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            getInTouchPopup: props.getInTouchPopup
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.getInTouchPopup != this.state.getInTouchPopup) {
            this.setState({
                getInTouchPopup: this.props.getInTouchPopup
            })
        }
    }

    render() {
        return (
            <Popup show={this.state.getInTouchPopup} onClose={() => this.props.showPopup('getInTouchPopup', false)} showGetInTouch={false}>
                <div className="popup__header">
                    <h3 className="popup__title">{t('p_get_in_touch.title')}</h3>
                </div>

                <div className="popup__body">
                    <div className="getInTouchPopup">
                        <div className="getInTouchPopup__text">
                            <p className="par-2" dangerouslySetInnerHTML={{__html: t('p_get_in_touch.text')}}/>
                        </div>

                        <div className="getInTouchPopup__grid">
                            {/*<Button class="-secondary" label="Start a chat" style={{width: '100%'}} onClick={() => null}/>*/}
                            <Button class="-secondary" label={t('p_get_in_touch.button')} style={{width: '100%'}} onClick={() => window.location = 'mailto:promonl@nespresso.com'}/>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetInTouchPopup);
