import React, {Component} from 'react';
import IMask from 'imask';
import './Forms.css';

class FormInputWithFile extends Component {
    constructor(props) {
        super(props);

        let valid = true;

        // if(props.isValid === false)
        //     valid = false

        let disabled = false;
        if(props.disabled === true)
            disabled = true;

        this.state = {
            class: 'formGroup -file' + (this.props.disabled ? ' -disabled' : ''),
            value: props.value,
            invalidFeedback: props.invalidFeedback,
            // isValid: valid,
            disabled,
            touched: !!props.value,
            loading: props.loading,
            isFilled: false
        };

        this.scannedValue = props.scannedValue;

        this.input = React.createRef();
        this.fileInput = React.createRef();
        this.inputMask = null;
        this.acceptFileTypes = 'image/png,image/jpg,image/jpeg,image/bmp';
    }

    componentDidMount() {
        if(this.input.current != null) {
            const maskOptions = {
                mask: '*******************',
                lazy: false,
                // autofix: true,
            };
            this.inputMask = IMask(this.input.current, maskOptions);
            this.onChangeInput(this.inputMask.value)
            this.inputMask.on('accept', () => {
                this.setState({touched: true}, () => {
                    this.onChangeInput(this.inputMask.value)
                });
                // console.log('accept mask')
            })
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.invalidFeedback != this.state.invalidFeedback) {
            this.setState({
                invalidFeedback: this.props.invalidFeedback
            })
        }

        // if(this.props.isValid != this.state.isValid) {
        //     this.setState({
        //         isValid: this.props.isValid
        //     })
        // }

        if(this.props.disabled != this.state.disabled) {
            this.setState({
                disabled: this.props.disabled,
                class: !this.props.disabled ? this.state.class.replace('-disabled', '') : this.state.class
            })
        }

        if(this.props.value != this.state.value) {
            // console.log('update!')
            this.setState({
                value: this.props.value
            }, () => {
                if(this.props.scannedValue != this.scannedValue) {
                    this.inputMask.updateValue();
                    this.scannedValue = this.props.scannedValue;
                }
                // setTimeout(() => this.inputMask.updateValue(), 100);
                // console.log('tralalal')
                // this.inputMask.updateValue();
                // console.log(this.inputMask.updateValue())
                // this.inputMask.value = this.props.value;
                // console.log(this.inputMask)
            });
        }

        if(this.props.touched != this.state.touched) {
            if(this.props.touched) {
                this.setState({
                    touched: this.props.touched,
                })
            }
        }


        if(this.props.loading != this.state.loading) {
            this.setState({
                loading: this.props.loading
            });
        }
    }

    onFocusInput(addClass) {
        let classes = 'formGroup -file';
        if(this.state.disabled)
            classes += ' -disabled';


        this.setState({
            class: addClass ? this.state.class + ' -focused' : classes,
            // touched: true
        })

        if(this.props.onFocus)
            this.props.onFocus();
    }

    onBlurInput() {
        this.onFocusInput(false);
        if(!this.state.touched)
            this.setState({touched: true})
        if(this.props.onBlur)
            this.props.onBlur();
    }

    onChangeInput(value) {
        this.setState({
            value: value,
            // touched: true,
        }, () => {
            this.props.onChange(value, this.inputMask.unmaskedValue === this.inputMask.value);
        }, () => {
            // this.inputMask.updateValue();
        });
    }

    onClickGroup(e) {
        this.input.current.focus();
    }

    handleUpload = (e) => {
        const file = e.target.files[0];
        if(file?.type && this.acceptFileTypes.includes(file.type)) {
            this.props.onFileUpload(e);
        }
    }

    render() {

        let formGroupClasses = this.state.class;
        if(this.props.disabled)
            formGroupClasses += ' -disabled';

        if(this.state.touched && !this.props.isValid)
            formGroupClasses += ' -invalid';

        if(this.state.touched && this.props.isValid)
            formGroupClasses += ' -valid';

        // if(this.state.loading)
        //     formGroupClasses += ' -loading';

        return (
            <div className={formGroupClasses}>
                <div className="formGroup__field" onClick={e => this.onClickGroup(e)}>
                    <label htmlFor={this.props.name}>{this.props.label}</label>
                    <input id={this.props.name} name={this.props.name} type={this.props.type}
                           value={this.state.value}
                           ref={this.input}
                           disabled={this.props.disabled}
                           onChange={e => this.onChangeInput(e.target.value)}
                           onBlur={() => this.onBlurInput()}
                           onFocus={() => this.onFocusInput(true)}
                    />
                    {this.props.progress ? (
                        <div className="formGroup__progress" style={{
                            width: this.props.progress + '%',
                            opacity: this.props.progress === 100 ? 0 : 1,
                        }} />
                    ) : null}

                    {this.props.enableFile ? (
                        <>
                            <button className="formGroup__fileButton" onClick={e => {
                                e.stopPropagation()
                                this.fileInput.current.click()
                            }}>
                                {this.state.loading ? (
                                    <img src={require('../../assets/images/icons/preloader2.gif')} alt="..."/>
                                ) : (
                                    <img src={require('../../assets/images/icons/camera.svg')} alt="upload"/>
                                )}
                            </button>
                            <input type="file" name="file" ref={this.fileInput} hidden
                                   onClick={e => e.stopPropagation()}
                                   onChange={this.handleUpload}
                                   id={this.props.name + '_file'}
                                   accept={this.acceptFileTypes}
                            />
                        </>
                    ) : null}



                </div>

                {this.state.invalidFeedback?<span className="formGroup__invalidFeedback">{this.state.invalidFeedback}</span>:<></>}
            </div>
        );
    }
}


export default FormInputWithFile;
