import React, {Component} from "react";
import {OrderSuccessPopup, PlusMinusButtons, Button} from "../index";
import './CartSummary.css';
import { connect } from 'react-redux';
import {updateCart, setStep, translate as t} from "../../actions/store";

const mapStateToProps = (state) => ({
    allProducts: state.allProducts,
    autoAdded: state.autoAdded,
    cart: state.cart,
    cartSum: state.cartSum,
    currency: state.currency,
    promotion: state.promotion,
    step: state.step,
})

const mapDispatchToProps = (dispatch) => ({
    updateCart: (cart) => dispatch(updateCart(cart)),
    setStep: (step) => dispatch(setStep(step)),
})


class CartSummary extends Component {
    constructor(props) {
        super(props);


        this.state = {
            cart: props.cart,
            cartSum: props.cartSum,
            sum: this.getTotalPrice(props.cart, props.allProducts),
        }

        this.showPacketsInSummary = true;
        // console.log('currency: ', props.currency)
    }


    canFinish() {
        return this.state.cart.items.length > 0
            && this.state.cart.capsuleCount >= this.props.promotion.shop_promotion_minimal_capsules
            && this.getItemsPrice() >= this.props.promotion.shop_promotion_minimal_order_value
    }


    getTotalPrice(cart, products) {
        let sum = 0.00;

        for(let i in cart.items) {
            let price = products[cart.items[i].itemId]?.price;
            if(price) {
                sum += (price * cart.items[i].value)
            }
        }

        for(let i in cart.discounts) {
            let price = products[cart.discounts[i].itemId]?.price;
            if(price) {
                sum += (price * cart.discounts[i].value)
            }
        }

        return sum;
    }

    getItemsPrice() {
        let sum = 0.00;
        for(let i in this.state.cart.items) {
            let price = this.props.allProducts[this.state.cart.items[i].itemId].price;
            sum += (price * this.state.cart.items[i].value)
        }
        return sum;
    }

    checkAutoAdded() {
        let capsCount = 0;
        for(let i in this.state.cart.items) {
            const item = this.state.cart.items[i];
            capsCount += item.value * this.props.allProducts[item.itemId].capsuleCount;
        }


        for(let iii in this.props.autoAdded) {
            if(capsCount >= this.props.autoAdded[iii].capsules && this.state.sum >= this.props.autoAdded[iii].price) {
                for(let ii in this.props.autoAdded[iii].products) {
                    let id = this.props.autoAdded[iii].products[ii];
                    let index = this.state.cart.discounts.findIndex(i => i.itemId === id);
                    if(index == -1) {
                        this.state.cart.discounts.push({
                            itemId: id,
                            value: 1
                        })
                    }

                }
            }

            if(capsCount < this.props.autoAdded[iii].capsules || this.getItemsPrice() < this.props.autoAdded[iii].price) {
                for(let ii in this.props.autoAdded[iii].products) {
                    let id = this.props.autoAdded[iii].products[ii];
                    let index = this.state.cart.discounts.findIndex(i => i.itemId === id);
                    if(index > -1) {
                        this.state.cart.discounts.splice(index, 1);
                    }

                }
            }
        }

        this.props.updateCart(this.state.cart);
    }

    componentDidMount() {
        this.checkAutoAdded()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.cartSum != this.state.cartSum) {
            this.setState({
                cart: this.props.cart,
                cartSum: this.props.cartSum,
                sum: this.getTotalPrice(this.props.cart, this.props.allProducts)
            }, () => {
                this.checkAutoAdded();
            })
        }

    }

    removeItem(index) {
        this.state.cart.items.splice(index, 1);
        this.props.updateCart(this.state.cart);
    }

    getDiscounts() {
        let discountsAry = [];

        this.state.cart.discounts.map(i => {
            const item = this.props.allProducts[i.itemId];
            if (item){
                discountsAry.push(item)
            }
        })

        if (discountsAry.length > 0) {
            discountsAry.sort((a, b) => a.price < b.price ? 1 : -1);

            discountsAry.filter(item => Number(item.price) === 0).map(item => {
                const index = discountsAry.indexOf(item);
                discountsAry.splice(index, 1);
                discountsAry.push(item)
            })

        }

        return discountsAry;
    }

    getPacketsFromCart() {
        const packets = [];

        this.state.cart.items.map(item => {
            const product = this.props.allProducts[item.itemId];
            if(product?.step === 1) {
                packets.push(product);
            }
        })

        return packets;
    }

    render() {
        let className = 'cartSummary';

        if(this.props.scrollable)
            className += ' -scrollable';
        if(this.props.additionalClass)
            className += ' ' + this.props.additionalClass;

        let totalCapsules = 0;
        this.state.cart.items.forEach(i => {
            const item = this.props.allProducts[i.itemId];
            if(item.step > 1) {
                totalCapsules += i.value;
            }
            else {
                totalCapsules += parseInt(item.capsuleCount);
                // console.log('pss..:', item)
            }
        });

        const packets = this.getPacketsFromCart();

        let discount = <></>;

        // console.log('can finish header: ', this.canFinish())

        if(this.state.cart.discounts.length > 0 || packets.length > 0) {
            discount = <><div className="cartSummary__header">
                <h3 className="cartSummary__title">{t('cart_summary.your_benefits')}</h3>
            </div>
                <div className="cartSummary__items">
                    {this.getDiscounts().map(item => {
                        return (
                            <div key={item.itemId} className="summaryItem">
                                <div className="summaryItem__thumb">
                                    <img src={item.img} alt={item.title}/>
                                </div>
                                <div className="summaryItem__content">
                                    <div className="summaryItem__header">
                                        <h4 className="summaryItem__title">{this.props.currency.prefix}{Number(item.price).toFixed(2)}{this.props.currency.postfix} {item.title}</h4>
                                    </div>
                                    <div className="summaryItem__footer">
                                        <div className="summaryItem__price">{this.props.currency.prefix}{Number(item.price).toFixed(2)}{this.props.currency.postfix}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {this.showPacketsInSummary && packets.map(item => {
                        const index = this.state.cart.items.findIndex(i => i.itemId === item.id);
                        return (
                            <div key={item.itemId} className="summaryItem">
                                <div className="summaryItem__thumb">
                                    <img src={item.img} alt={item.title}/>
                                </div>
                                <div className="summaryItem__content">
                                    <div className="summaryItem__header">
                                        <h4 className="summaryItem__title">{item.title}</h4>
                                        <button className="summaryItem__remove" onClick={() => this.removeItem(index)}>
                                            <img src={require('../../assets/images/icons/close.svg')} alt="x" className=""/>
                                        </button>
                                    </div>
                                    {item.step > 1 ? (
                                        <div className="summaryItem__text">{item.value / 10} {t('sleeves')} ({item.value} {t('capsules_total')})</div>
                                    ) : (
                                        <div className="summaryItem__text">({item.capsuleCount} {t('capsules_total')})</div>
                                    )}
                                    <div className="summaryItem__footer">
                                        <div className="summaryItem__value">
                                            <PlusMinusButtons id={item.id} />
                                        </div>
                                        <div className="summaryItem__price">
                                            {/*{item.promoPrice?.promotion_price !== null ? (*/}
                                            {/*    <span className="coffeeItem__promoPrice">{this.props.currency.prefix}{item.promoPrice?.promotion_price}{this.props.currency.postfix}</span>*/}
                                            {/*) : null}*/}
                                            <span>{this.props.currency.prefix}{Number(item.price).toFixed(2)}{this.props.currency.postfix}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div></>
        }

        let cartSummary = <div className="cartBox__total">
            <span className="cartBox__name">{t('cart_summary.total_price')}</span>
            <span className="cartBox__price">{this.props.currency.prefix}{this.state.sum.toFixed(2)}{this.props.currency.postfix}</span>
        </div>

        if(this.props.hideSummary) {
            cartSummary = <></>
        }

        return (
            <div className={className}>
                {this.state.cart.items.length || this.state.cart.discounts.length > 0 ? (
                    <>


                        <div className="cartSummary__header">
                            <h3 className="cartSummary__title">{this.state.cart.items.length} {t('cart_summary.original_sleeves')}</h3>
                            <h5 className="cartSummary__subtitle">({t('cart_summary.capsules_total', totalCapsules)})</h5>
                        </div>
                        <div className="cartSummary__items">
                            {this.state.cart.items.map((item, index) => {
                                const fullItem = this.props.allProducts[item.itemId];

                                if(this.showPacketsInSummary && packets.find(i => i.id === fullItem.id)) {
                                    return null;
                                }

                                if(fullItem)
                                    return (
                                        <div key={item.itemId} className="summaryItem">
                                            <div className="summaryItem__thumb">
                                                <img src={this.props.allProducts[item.itemId].img} alt={this.props.allProducts[item.itemId].title}/>
                                            </div>
                                            <div className="summaryItem__content">
                                                <div className="summaryItem__header">
                                                    <h4 className="summaryItem__title">{this.props.allProducts[item.itemId].title}</h4>
                                                    <button className="summaryItem__remove" onClick={() => this.removeItem(index)}>
                                                        <img src={require('../../assets/images/icons/close.svg')} alt="x" className=""/>
                                                    </button>
                                                </div>
                                                {fullItem.step > 1 ? (
                                                    <div className="summaryItem__text">{item.value / 10} {t('sleeves')} ({item.value} {t('capsules_total')})</div>
                                                ) : (
                                                    <div className="summaryItem__text">({t('capsules_total', fullItem.capsuleCount)})</div>
                                                )}
                                                <div className="summaryItem__footer">
                                                    <div className="summaryItem__value">
                                                        <PlusMinusButtons id={item.itemId} />
                                                    </div>
                                                    <div className="summaryItem__price">
                                                        {/*{this.props.allProducts[item.itemId].promoPrice?.promotion_price !== null ? (*/}
                                                        {/*    <span className="coffeeItem__promoPrice">{this.props.currency.prefix}{this.props.allProducts[item.itemId].promoPrice?.promotion_price}{this.props.currency.postfix}</span>*/}
                                                        {/*) : null}*/}
                                                        <span>{this.props.currency.prefix}{Number(this.props.allProducts[item.itemId].price).toFixed(2)}{this.props.currency.postfix}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                            })}
                        </div>

                        {discount}


                        {/*<p className="cartSummary__info" dangerouslySetInnerHTML={{__html: t('cart_info')}}/>*/}

                    </>
                ) : (
                    <div className="cartSummary__empty">
                        <h4 className="cartSummary__head">{t('cart_summary.empty.title')}</h4>
                        <p className="cartSummary__text">{t('cart_summary.empty.text')}</p>
                    </div>
                )}
                {this.state.cart.items.length > 0 ? cartSummary : null}


                {this.state.cart.items.length > 0 && this.props.step === 2 ? (
                    <div className="cartSummary__submit">
                        <Button class="-primary -sm"
                                disabled={!this.canFinish()}
                                label={t('continue')}
                                onClick={() => this.props.setStep(3)}
                        />
                    </div>
                ) : null}

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartSummary);
