import React, {Component} from 'react';
import {
    Step,
    StepCustom,
    FormInputWithFile,
    Button,
    ButtonClose,
    Edit,
    UploadBox,
    FindSerialNumberPopup,
    FormInput,
    FormSelect,
    FormDatePicker,
    GetInTouch,
    ProofOfPurchasePopup,
    Popup,
} from '../index';
import {Link} from "react-router-dom";
import './MachineRegistration.css';
import * as actions from '../../actions/actions';
import {connect} from "react-redux";
import {setMachine, setPromo, setPurchase, setShopGear, setOcrLogs, translate as t, setLastValidStep} from "../../actions/store";
import moment from "moment";
import IMask from "imask";
import * as GMT from "../../actions/GTM";

const mapDispatchToProps = (dispatch) => ({
    setShopGear: (id_shop_gear, gearName) => dispatch(setShopGear(id_shop_gear, gearName)),
    setPurchase: (purchase) => dispatch(setPurchase(purchase)),
    setMachine: (machine) => dispatch(setMachine(machine)),
    setOcrLogs: (logs) => dispatch(setOcrLogs(logs)),
    setLastValidStep: step => dispatch(setLastValidStep(step)),
})

const mapStateToProps = (state) => ({
    machine: state.machine,
    gearName: state.gearName,
    promotion: state.promotion,
    config: state.config,
    lang: state.lang,
    ocrLogs: state.ocrLogs,
})

class MachineRegistration extends Component {
    constructor(props) {
        super(props);

        this.POP_VALIDATION_OCR = props.config.pop_validation_ocr;

        // console.log('machine registration constructor', props.lang)

        this.state = {
            serialNumber: "undefined" !== typeof props.machine.serialNumber?props.machine.serialNumber:'',
            serialNumberScanned: "undefined" !== typeof props.machine.serialNumberScanned?props.machine.serialNumberScanned:'',
            serialNumberPhoto: "undefined" !== typeof props.machine.serialNumberPhoto?props.machine.serialNumberPhoto:null,
            serialNumberPhotos: "undefined" !== typeof props.machine.serialNumberPhotos?props.machine.serialNumberPhotos:[],
            isSerialNumberValid: "undefined" !== typeof props.machine.isSerialNumberValid?props.machine.isSerialNumberValid:false,
            isSerialNumberFilled: "undefined" !== typeof props.machine.isSerialNumberFilled?props.machine.isSerialNumberFilled:false,
            proofOfPurchasePhoto: "undefined" !== typeof props.machine.proofOfPurchasePhoto?props.machine.proofOfPurchasePhoto:null,
            proofOfPurchasePhotoSource: "undefined" !== typeof props.machine.proofOfPurchasePhotoSource?props.machine.proofOfPurchasePhotoSource:null,
            machinePhoto: "undefined" !== typeof props.machine.machinePhoto?props.machine.machinePhoto:null,

            serialNumberManual: typeof props.machine?.serialNumberManual !== "undefined" ? props.machine?.serialNumberManual : false,

            serialNumberTouched: "undefined" !== typeof props.machine.serialNumberTouched?props.machine.serialNumberTouched:false,

            serialNumberPhotoUploadProgress: 0,
            proofOfPurchasePhotoUploadProgress: 0,

            serialNumberPopup: null,
            serialNumberScannedPopup: null,
            serialNumberPhotoPopup: null,
            isSerialNumberValidPopup: null,
            isSerialNumberFilledPopup: null,
            proofOfPurchasePhotoPopup: null,


            serialNumberPhotoLoading: false,
            proofOfPurchasePhotoLoading: false,

            serialEnabled: "undefined" !== typeof props.machine.serialEnabled?props.machine.serialEnabled:true,
            serialInvalidFeedback: 'to_short',
            serialNumberValid: "undefined" !== typeof props.machine.serialNumberValid?props.machine.serialNumberValid:false,

            usingPromocode: "undefined" !== typeof props.machine.usingPromocode?props.machine.usingPromocode:false,
            promotionCode: "undefined" !== typeof props.machine.promotionCode?props.machine.promotionCode:'',
            promotionCodeValid: "undefined" !== typeof props.machine.promotionCodeValid?props.machine.promotionCodeValid:false,
            promotionCodeChecked: "undefined" !== typeof props.machine.promotionCodeChecked?props.machine.promotionCodeChecked:false,
            promotionCodeInvalidFeedback: "undefined" !== typeof props.machine.promotionCodeInvalidFeedback?props.machine.promotionCodeInvalidFeedback:t('s_machine.promocode_invalid'),

            promotionCodeInfo: "undefined" !== typeof props.machine.promotionCodeInfo?props.machine.promotionCodeInfo:false,

            showSerialNumberPopup: false,
            showProofOfPurchasePopup: false,
            showEditSerialNumberPopup: false,

            purchase: "undefined" !== typeof props.machine.purchase?props.machine.purchase:{
                forceTouched: false,
                date: null,
                tradePartner: null,
                retailerStore: null,
                invoiceNumber: '',
                invoiceTotalValue: '',
                scanned: {},
            },

            purchaseValidate: {
                date: false,
                tradePartner: false,
                retailerStore: false,
                invoiceNumber: false,
                invoiceTotalValue: false,
                promoCodeId: 0
            },

            shopList: [],
            tradePartners: [],
            retailerStores: [],

            gearName: props.gearName,

            logs: typeof props.ocrLogs !== "undefined" ? props.ocrLogs : {
                serialNumber: [],
                proofOfPurchaseManual: {
                    date: [],
                    tradePartner: [],
                    retailerStore: [],
                    invoiceNumber: [],
                    invoiceTotalValue: [],
                },
                proofOfPurchaseOcr: [],
            },
        }

        this.serialNumberRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.gearName != this.state.gearName) {
            this.setState({
                gearName: this.props.gearName
            })
        }

        if(prevState !== this.state) {
            this.props.keepSession();
        }
    }

    componentDidMount() {
        actions.getShops().then(r => {
            if(r.status == 'success') {
                this.setState({
                    shops: r.data
                }, () => {
                    let tradePartners = [];
                    for(let i in this.state.shops) {
                        tradePartners.push( {
                                value: i,
                                label: this.state.shops[i].group.name
                            }
                        );


                        tradePartners.sort((a,b) => a.label.toLowerCase() > b.label.toLowerCase()?1:-1)
                        tradePartners.sort((a,b) => b.value == 7 ? -1 : null)
                    }
                    this.setState({
                        tradePartners: tradePartners
                    }, () => {
                        if (this.state.purchase.tradePartner?.value) {
                            this.loadShopList();
                        }
                    })
                })
            }
        })
    }


    onSerialNumberPhotoUpload(e) {
        if(e.target.files[0] && !e.target.files[0].name.toString().toLowerCase().match(/\.pdf$/)) {

            let reader = new FileReader();
            reader.onload = () => {

                this.setState({
                    serialNumber: '',
                    serialNumberPhoto: reader.result,
                    serialNumberPhotoLoading: true,
                }, () => {
                    const config = {
                        onUploadProgress: e => {
                            let progress = Math.round((e.loaded * 100) / e.total);
                            this.setState({
                                serialNumberPhotoUploadProgress: progress,
                            })
                        }
                    }

                    const items = [...this.state.serialNumberPhotos];
                    let photo = {
                        img: this.state.serialNumberPhoto,
                        valid: false,
                        time: Date.now(),
                    }

                    actions.axiosSerialNumberOcr(this.state.serialNumberPhoto, config)
                        .then(res => {
                            const r = res.data;
                            if(r.status == 'success') {
                                let serial = r.data.serial.toString();
                                if(serial.length === 19 && serial[0] == 4 || serial[0] == 7) {
                                    serial = 1 + serial.substr(1, serial.length);
                                }

                                photo.valid = true;

                                this.setState({
                                    serialNumber: serial,
                                    serialNumberScanned: serial,
                                    serialNumberPhotoLoading: false,
                                }, () => {
                                    this.validateSerialNumber(() => {
                                        if(this.state.isSerialNumberFilled)
                                            this.setState({
                                                // serialNumberFeedback: t('serial.serialnumber_to_short_after_ocr')
                                                serialInvalidFeedback: 'serialnumber_to_short_after_ocr'
                                            })

                                        this.addLogSerialNumber('ocr')
                                    });
                                })
                            }
                            else {
                                this.setState({
                                    serialNumberPhotoLoading: false,
                                    serialNumberValid: false,
                                    serialInvalidFeedback: r.message,
                                    serialNumberTouched: true,
                                }, () => {
                                    this.addLogSerialNumber('ocr')
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        })
                        .finally(() => {
                            items.push(photo);

                            this.setState({
                                serialNumberPhotos: items,
                                serialNumberPhotoUploadProgress: 0,
                            })
                        })


                })
            };

            reader.readAsDataURL(e.target.files[0]);

        }
    }

    onSerialNumberPhotoUploadUploadBox(files) {
        if(files[0] && !files[0].name.toString().toLowerCase().match(/\.pdf$/)) {
            let reader = new FileReader();
            reader.onload = () => {
                this.setState({
                    serialNumber: '',
                    serialNumberPhoto: reader.result,
                    serialNumberPhotoLoading: true,
                }, () => {
                    const config = {
                        onUploadProgress: e => {
                            let progress = Math.round((e.loaded * 100) / e.total);
                            this.setState({
                                serialNumberPhotoUploadProgress: progress,
                            })
                        }
                    }

                    const items = [...this.state.serialNumberPhotos];
                    let photo = {
                        img: this.state.serialNumberPhoto,
                        valid: false,
                        time: Date.now(),
                    }

                    actions.axiosSerialNumberOcr(this.state.serialNumberPhoto, config)
                        .then(res => {
                            const r = res.data;
                            if(r.status == 'success') {
                                let serial = r.data.serial.toString();
                                if(serial.length === 19 && serial[0] == 4 || serial[0] == 7) {
                                    serial = 1 + serial.substr(1, serial.length);
                                }

                                photo.valid = true;

                                this.setState({
                                    serialNumber: serial,
                                    serialNumberScanned: serial,
                                    serialNumberPhotoLoading: false,
                                }, () => {
                                    this.validateSerialNumber(() => {
                                        if(this.state.isSerialNumberFilled)
                                            this.setState({
                                                // serialNumberFeedback: t('serial.serialnumber_to_short_after_ocr')
                                                serialInvalidFeedback: 'serialnumber_to_short_after_ocr'
                                            })

                                        this.addLogSerialNumber('ocr')
                                    });
                                })
                            }
                            else {
                                this.setState({
                                    serialNumberPhotoLoading: false,
                                    serialNumberValid: false,
                                    serialInvalidFeedback: r.message,
                                    serialNumberTouched: true,
                                }, () => {
                                    this.addLogSerialNumber('ocr')
                                })
                            }
                        })
                        .catch(err => {
                            this.setState({
                                serialNumberPhoto: null,
                            })
                        })
                        .finally(() => {
                            items.push(photo);

                            this.setState({
                                serialNumberPhotos: items,
                                serialNumberPhotoUploadProgress: 0,
                                serialNumberPhotoLoading: false,
                            })
                        })
                })
            };

            this.setState({
                serialNumberManual: false,
            })
            reader.readAsDataURL(files[0]);

        }
    }

    onProofOfPurchasePhotoUpload(files) {
        this.setState({
            proofOfPurchasePhotoLoading: true,
            proofOfPurchasePhotoUploadProgress: 0,
        })

        const timeout = setTimeout(() => {
            this.setState({
                proofOfPurchasePhotoLoading: false,
                purchase: {
                    ...this.state.purchase,
                    forceTouched: true,
                }
            })
        }, 5000)

        if(files[0]) {
            if(files[0].name.toString().toLowerCase().match(/\.pdf$/)) {
                let reader = new FileReader();
                reader.onload = () => {
                    const config = {
                        onUploadProgress: e => {
                            let progress = Math.round((e.loaded * 100) / e.total);
                            this.setState({
                                proofOfPurchasePhotoUploadProgress: progress,
                            })
                        }
                    };
                    actions.pdfOcrAxios(reader.result, config)
                        .then(res => {
                            const r = res.data;
                            if(r.status == 'success') {
                                this.parseOcrResponse(r)
                            }
                        })
                        .catch(err => {
                            this.setState({
                                proofOfPurchasePhotoLoading: false,
                                purchase: {
                                    ...this.state.purchase,
                                    forceTouched: true,
                                }
                            })

                            clearTimeout(timeout);
                        })
                };

                reader.readAsDataURL(files[0]);
            }else{
                let reader = new FileReader();
                reader.onload = () => {
                    this.setState({
                        proofOfPurchasePhoto: reader.result,
                    }, () => {
                        const config = {
                            onUploadProgress: e => {
                                let progress = Math.round((e.loaded * 100) / e.total);
                                this.setState({
                                    proofOfPurchasePhotoUploadProgress: progress,
                                })
                            }
                        };
                        actions.invoiceOcrAxios(this.state.proofOfPurchasePhoto, config)
                            .then(res => {
                                const r = res.data;
                                if(r.status == 'success') {
                                    this.parseOcrResponse(r)
                                }
                            })
                            .catch(err => {
                                this.setState({
                                    proofOfPurchasePhotoLoading: false,
                                    purchase: {
                                        ...this.state.purchase,
                                        forceTouched: true,
                                    }
                                })

                                clearTimeout(timeout);
                            })
                    })
                };

                reader.readAsDataURL(files[0]);
            }
        }
    }

    parseOcrResponse(r) {
        let response = this.state;

        if("undefined" != typeof r.image) {
            response.proofOfPurchasePhoto = r.image
        }

        response.purchase.scanned = r.data;

        // console.log(r.data.date)
        if("undefined" != typeof r.data.date) {
            response.purchase.date = r.data.date;
        }else{
            response.purchase.date = '';
        }

        if("undefined" != typeof r.data.invoice_no) {
            response.purchase.invoiceNumber = r.data.invoice_no
        }else{
            response.purchase.invoiceNumber = '';
        }

        if("undefined" != typeof r.data.total) {
            response.purchase.invoiceTotalValue = r.data.total
        }else{
            response.purchase.invoiceTotalValue = '';
        }

        if("undefined" != typeof r.data.id_shop_group) {
            let mainShop = this.state.shops[r.data.id_shop_group];
            if(typeof mainShop != "undefined") {
                response.purchase.tradePartner = {
                    value: r.data.id_shop_group,
                    label: this.state.shops[r.data.id_shop_group].group.name
                }
            }

            if("undefined" != typeof r.data.id_shop && "undefined" != typeof mainShop.shops[r.data.id_shop]) {
                response.purchase.retailerStore = {
                    value: r.data.id_shop,
                    label: this.state.shops[r.data.id_shop_group].shops[r.data.id_shop].name
                }
            }
        }

        // proofOfPurchasePhotoSource
        if("undefined" != typeof r.source_file) {
            response.proofOfPurchasePhotoSource = r.source_file
        }else{
            response.proofOfPurchasePhotoSource = null;
        }

        this.addLogProofOfPurchaseOcr();


        this.setState(response, () => {
            this.loadShopList()
            this.validateStep()
            this.props.setMachine(this.state)
        });


    }

    validatePromotionCode(callback = null) {
        actions.validatePromotionCode(this.state.promotionCode, this.props.promotion.id_shop_promotion).then(d => {
            if(d.status == 'success') {
                this.setState({
                    promotionCodeChecked: true,
                    promotionCodeInfo: d.data,
                    promotionCodeValid: true,
                }, () => {

                    let purchase = this.state.purchase;
                    purchase.promoCodeId = this.state.promotionCodeInfo.obj.id_promo_code;
                    purchase.date = this.state.promotionCodeInfo.date;
                    purchase.tradePartner = {label: this.state.promotionCodeInfo.shopGroup.title, value: this.state.promotionCodeInfo.shopGroup.id_shop_promotion_shop_group};
                    purchase.retailerStore = this.state.promotionCodeInfo.shop?{label: this.state.promotionCodeInfo.shop.name, value: this.state.promotionCodeInfo.shop.id_shop_promotion_shop}:false;
                    purchase.invoiceNumber = 'n/a';
                    purchase.invoiceTotalValue = -1;

                    this.setState({purchase}, () => {
                        this.props.setMachine(this.state);
                        this.validateStep();
                    })


                });
            }else{
                this.setState({
                    promotionCodeChecked: false,
                    promotionCodeValid: false,
                    promotionCodeInvalidFeedback: t('s_machine.' + d.message),
                    promotionCodeInfo: false
                }, () => {

                    this.setState({purchase: {
                            forceTouched: false,
                            date: null,
                            tradePartner: null,
                            retailerStore: null,
                            invoiceNumber: '',
                            invoiceTotalValue: '',
                            scanned: {}
                        }}, () => {
                        this.props.setMachine(this.state)
                    })

                    this.props.setMachine(this.state)
                })
            }
        })
    }

    validateSerialNumber(callback = null) {
        actions.validateSerialNumber(this.state.serialNumber).then(d => {
            if(d.status == 'error') {
                this.setState({
                    serialEnabled: true,
                    serialInvalidFeedback: d.message,
                    serialNumberValid: false,
                    serialNumberTouched: true,
                })
            }else{
                // let machinePhoto = require('../../assets/images/machine.png')
                let machinePhoto = ''
                if(d.data.gear.filename) {
                    machinePhoto = d.data.gear.filename;
                }

                this.setState({
                    serialNumberValid: true,
                    serialNumberTouched: true,
                    machinePhoto
                }, () => {
                    this.props.setShopGear(d.data.gear.id_shop_gear, d.data.gear.name);
                    this.props.setMachine(this.state)
                })


            }
        }).finally(() => {
            if(typeof callback === 'function')
                callback();
        });
    }


    handleConfirmSerialNumber() {
        this.setState({
            serialEnabled: false,
            serialInvalidFeedback: '',
        })
    }

    loadShopList() {
        let shopList = [];

        if(!this.state.purchase.tradePartner || !this.state.purchase.tradePartner.value)return;

        for(let i in this.state.shops[parseInt(this.state.purchase.tradePartner.value)].shops) {
            shopList.push({
                value: i,
                label: this.state.shops[parseInt(this.state.purchase.tradePartner.value)].shops[i].name
            })

        }

        shopList = shopList.sort((a, b) => {return a.label.toLowerCase() > b.label.toLowerCase()?1:-1;});

        // console.log('shops: ', shopList)

        this.setState({
            retailerStores: shopList,
            purchase: {...this.state.purchase, retailerStore: null}
        })
    }

    validateStep() {

        let valid = true;
        let p = this.state.purchase;

        if(this.POP_VALIDATION_OCR.options.trade_partner.mandatory == 'on'  && !p.tradePartner) {
            valid = false;
            // this.setState({purchaseValidate: {...this.state.purchaseValidate, tradePartner: false}})
        }
        if(this.POP_VALIDATION_OCR.options.retailer_store.mandatory == 'on' && this.POP_VALIDATION_OCR.options.retailer_store.mandatory == 'on'  && !p.retailerStore) {
            valid = false;
            // this.setState({purchaseValidate: {...this.state.purchaseValidate, tradePartner: false}})
        }
        if(this.POP_VALIDATION_OCR.options.date.mandatory == 'on'  && !p.date || p.date.length == 0) {
            valid = false;
        }
        if(this.POP_VALIDATION_OCR.options.invoice_number.mandatory == 'on'  && !p.invoiceNumber) {
            valid = false;
        }
        if(this.POP_VALIDATION_OCR.options.machine_price.mandatory == 'on' && !p.invoiceTotalValue) {
            valid = false;
        }

        this.props.setPurchase(this.state.purchase);

        this.props.setMachine(this.state)

        this.setState({
            proofOfPurchasePhotoLoading: false,
            purchase: {
                ...this.state.purchase,
                forceTouched: true
            }
        })

        if(valid) {
            this.props.setLastValidStep(this.props.stepNo);
        }

        return valid;
    }

    nextStep(step = this.props.stepNo + 1) {
        if (this.state.usingPromocode) {
            if(this.state.promotionCodeValid) {
                // this.props.nextStep(step);
                this.props.showStep(step);
            }
        }
        else {
            if(this.validateStep()) {
                // this.props.nextStep(step);
                this.props.showStep(step);
            }
        }
    }

    clearSerialNumber() {
        this.setState({
            serialInvalidFeedback: 'to_short',
            serialNumberValid: false,
            serialNumber: '',
            serialNumberPhoto: null,
            serialNumberPhotoLoading: false,
        })
    }

    clearPurchase() {
        this.setState({
            purchase: {
                forceTouched: false,
                date: null,
                tradePartner: null,
                retailerStore: null,
                invoiceNumber: '',
                invoiceTotalValue: '',
                scanned: {}
            },
            proofOfPurchasePhotoLoading: false,
            proofOfPurchasePhoto: null,
            // serialEnabled: true,
        })
    }

    handleChangePromotionCode(value) {
        if(value.toString().length <= this.promoCodeLength && (!value || value.match(/^[0-9a-zA-Z\-]+$/))) {
            this.setState({
                promotionCode: value,
                promotionCodeChecked: false,
                promotionCodeValid: false,
                // promotionCodeValid: !!(value && value.match(/^[0-9a-zA-Z\-]+$/)),
                // promotionCodeInvalidFeedback: t('s_machine.promocode_invalid')
            }, () => {
                this.props.setMachine(this.state)
                if(this.state.promotionCode.length === this.promoCodeLength) {
                    this.validatePromotionCode();
                }
            });
        }
    }

    handleChangeSerialNumber(value, isFilled = false) {
        this.setState({
            serialNumber: value,
            // serialNumberValid: isFilled,
        }, () => {
            if(!isFilled)
                this.setState({
                    serialNumberValid: false,
                    serialInvalidFeedback: 'to_short'
                })
            else {
                this.validateSerialNumber(() => {
                    this.addLogSerialNumber('manual', this.state.serialNumberValid)
                });
            }


        })
    }

    showEditSerialNumberPopup() {
        this.setState({
            serialNumberPopup: this.state.serialNumber,
            serialNumberScannedPopup: this.state.serialNumberScanned,
            serialNumberPhotoPopup: this.state.serialNumberPhoto,
            isSerialNumberValidPopup: this.state.isSerialNumberValid,
            isSerialNumberFilledPopup: this.state.isSerialNumberFilled,
            proofOfPurchasePhotoPopup: this.state.proofOfPurchasePhoto,

            showEditSerialNumberPopup: true,
            serialEnabled: true,
        }, () => {
            this.setState({
                serialNumberScanned: this.state.serialNumber,
            })
        })
    }

    onCloseEditSerialNumberPopup() {
        this.setState({
            serialNumber: this.state.serialNumberPopup,
            serialNumberScanned: this.state.serialNumberScannedPopup,
            serialNumberPhoto: this.state.serialNumberPhotoPopup,
            isSerialNumberValid: this.state.isSerialNumberValidPopup,
            isSerialNumberFilled: this.state.isSerialNumberFilledPopup,
            proofOfPurchasePhoto: this.state.proofOfPurchasePhotoPopup,

            showEditSerialNumberPopup: false,
            serialEnabled: false,
        }, () => {
            this.validateSerialNumber(() => {
                this.handleConfirmSerialNumber()
            })
        })
    }

    handleHidePromo() {
        this.setState({
            usingPromocode: false,
            promotionCode: '',
            promotionCodeChecked: false,
            promotionCodeInfo: false,
            promotionCodeInvalidFeedback: '',
            promotionCodeValid: false,

            proofOfPurchasePhoto: null,

            purchase: {
                ...this.state.purchase,
                forceTouched: false,
                date: null,
                tradePartner: null,
                retailerStore: null,
                invoiceNumber: '',
                invoiceTotalValue: '',
                scanned: {}
            }
        })
    }

    handleShowPromo() {
        this.setState({
            usingPromocode: true
        })
    }

    addLogSerialNumber(type) {
        const oldArr = this.state.logs.serialNumber;
        if(oldArr[oldArr.length-1]?.value === this.state.serialNumber)
            return;

        this.state.logs.serialNumber.push({
            date: new Date(),
            type,
            value: this.state.serialNumber,
            invalidFeedback: this.state.serialNumberValid?null:this.state.serialInvalidFeedback,
            valid: this.state.serialNumberValid
        })
        const arr = this.state.logs.serialNumber;
        this.setState({
            logs: {
                ...this.state.logs,
                serialNumber: arr
            }
        }, () => {
            this.props.setOcrLogs(this.state.logs);
        })
    }

    addLogProofOfPurchaseManual(field) {
        this.state.logs.proofOfPurchaseManual[field].push({
            date: new Date(),
            field,
            value: this.state.purchase[field]
        })
        const arr = this.state.logs.proofOfPurchaseManual[field];
        this.setState({
            logs: {
                ...this.state.logs,
                proofOfPurchaseManual: {
                    ...this.state.logs.proofOfPurchaseManual,
                    [field]: arr,
                }
            }
        }, () => {
            this.props.setOcrLogs(this.state.logs);
        })
    }

    addLogProofOfPurchaseOcr() {
        this.state.logs.proofOfPurchaseOcr.push({
            date: new Date(),
            fields: {
                date: this.state.purchase.date,
                tradePartner: this.state.purchase.tradePartner,
                retailerStore: this.state.purchase.retailerStore,
                invoiceNumber: this.state.purchase.invoiceNumber,
                invoiceTotalValue: this.state.purchase.invoiceTotalValue,
            }
        })
        const arr = this.state.logs.proofOfPurchaseOcr;
        this.setState({
            logs: {
                ...this.state.logs,
                proofOfPurchaseOcr: arr
            }
        }, () => {
            this.props.setOcrLogs(this.state.logs);
        })
    }

    isContinueDisabled() {
        if(this.state.usingPromocode) {
            return !(this.state.promotionCode.length > 0);
        }
        else {
            // return !(this.state.proofOfPurchasePhoto != null || this.props.config.pop_validation.value == 'manual');
            let valid = true;
            let p = this.state.purchase;

            if(this.POP_VALIDATION_OCR.options.trade_partner.mandatory == 'on'  && !p.tradePartner) {
                valid = false;
                // this.setState({purchaseValidate: {...this.state.purchaseValidate, tradePartner: false}})
            }
            if(this.POP_VALIDATION_OCR.options.trade_partner.mandatory == 'on' && this.POP_VALIDATION_OCR.options.retailer_store.mandatory == 'on'  && !p.retailerStore) {
                valid = false;
                // this.setState({purchaseValidate: {...this.state.purchaseValidate, tradePartner: false}})
            }
            if(this.POP_VALIDATION_OCR.options.date.mandatory == 'on'  && !p.date || p.date.length == 0) {
                valid = false;
            }
            if(this.POP_VALIDATION_OCR.options.invoice_number.mandatory == 'on'  && !p.invoiceNumber) {
                valid = false;
            }
            if(this.POP_VALIDATION_OCR.options.machine_price.mandatory == 'on' && !p.invoiceTotalValue) {
                valid = false;
            }
            return !valid;
        }
        // return !(this.state.proofOfPurchasePhoto != null || this.props.config.pop_validation.value == 'manual' || this.state.promotionCode.length > 0);
    }

    getMaxPurchasingDate() {
        const dateToText = this.props.promotion.purchase_date_to;
        if(dateToText && !dateToText.match(/^0000-00-00$/)) {
            return Date.parse(dateToText);
        }
        return new Date();
    }

    render() {
        let thumbClasses = 'thumb';
        if(this.state.isSerialNumberValid)
            thumbClasses += ' -disableEdit';

        let serialNumberGridClass = '';
        if(!this.state.serialEnabled)
            serialNumberGridClass += 'grid -serialNumber';


        let promotionCodeInfo;

        if(this.state.promotionCodeInfo) {

            let shopInfo;

            if(this.state.promotionCodeInfo.shop) {
                shopInfo = <>
                    <p className="machineRegistration__promoCodeInfoHeader">
                        {t('s_machine.retailer_store')}:
                    </p>
                    <p className="machineRegistration__promoCodeInfoValue">
                        {this.state.promotionCodeInfo.shop.name}
                    </p>
                </>
            }

            promotionCodeInfo = <div className="machineRegistration__promoCodeInfoDescription">
                {/*<div className="grid -fromXs">*/}
                {/*    <div>*/}
                {/*        {shopInfo}*/}
                {/*    </div>*/}
                {/*    <img className="machineRegistration__promoCodeInfoImg" src={this.state.promotionCodeInfo.shopGroup.filename} />*/}
                {/*</div>*/}

                <p className="machineRegistration__promoCodeInfoHeader">
                    {t('s_machine.purchasing_date')}:
                </p>
                <p className="machineRegistration__promoCodeInfoValue">
                    {this.state.promotionCodeInfo.date}
                </p>
                <p className="machineRegistration__promoCodeInfoHeader">
                    {t('s_machine.promo_name')}:
                </p>
                <p className="machineRegistration__promoCodeInfoValue">
                    {this.props.promotion.name}
                </p>
                <p className="machineRegistration__promoCodeInfoHeader">
                    {t('s_machine.promo_dates')}:
                </p>
                <p className="machineRegistration__promoCodeInfoValue">
                    {new Date(this.props.promotion.date_from).getDate()}/{((new Date(this.props.promotion.date_from).getMonth()+1)+"").padStart(2,'0')}/{new Date(this.props.promotion.date_from).getFullYear()}
                    &nbsp;-&nbsp;
                    {new Date(this.props.promotion.date_to).getDate()}/{((new Date(this.props.promotion.date_to).getMonth()+1)+"").padStart(2,'0')}/{new Date(this.props.promotion.date_to).getFullYear()}
                </p>
            </div>
        }

        return (
            <StepCustom label={t('s_machine._title')}
                        stepNo={this.props.stepNo}
                        currentStep={this.props.currentStep}
                        showStep={step => this.nextStep(step)}
                  // nextStep={step => this.nextStep(step)}
            >

                <div className="machineRegistration">
                    <div className="grid">
                        <div className="col">
                            <p className="par-1 --semiBold" dangerouslySetInnerHTML={{__html: t('s_machine.text1')}}/>
                            {!this.state.serialEnabled ? <p className="par-2 --semiBold">{t('s_machine.text3', 10)}</p> : null}
                        </div>
                    </div>
                    <div className="grid">
                        <div className={serialNumberGridClass}>
                            <div className="col">
                                {this.state.machinePhoto ? (
                                    <div className="machineThumb" hidden={this.state.serialEnabled}>
                                        <img src={this.state.machinePhoto} alt={t('s_machine.text3')} />
                                    </div>
                                ) : null}
                            </div>
                            <div className="col">
                                {/*{this.state.serialEnabled ? <p className="par-2 --semiBold">{t('s_machine.text2')}</p>: null}*/}
                                {!this.state.serialEnabled ? <p className="machineRegistration__machineName">{this.state.gearName}</p> : null}


                                {this.state.serialNumberManual ? (
                                    <div className="--mb2">
                                        {this.state.serialEnabled && (
                                            <p className="par-2 --semiBold">
                                                <span dangerouslySetInnerHTML={{__html: t('s_machine.serial_number_manual_text')}}/>
                                                <button className="link -normalSize -primary --semiBold"
                                                        onClick={e => this.setState({serialNumberManual: false})}>{t('s_machine.serial_number_manual_text2')}</button>
                                            </p>
                                        )}
                                        {/*<p className="par-2">*/}
                                        {/*    <span dangerouslySetInnerHTML={{__html: t('s_machine.serial_number_manual_text')}}/>*/}
                                        {/*    /!*<button title={t('s_machine.use_promo_code')} className="link -primary --1em"*!/*/}
                                        {/*    /!*        onClick={() => this.handleShowPromo()}>*!/*/}
                                        {/*    /!*    {t('s_machine.use_promo_code')}*!/*/}
                                        {/*
                                            /!*</button>*!/*/}
                                        {/*    /!*{t('s_machine.use_promo_code')}*!/*/}
                                        {/*</p>*/}
                                        <UploadBox file={this.state.serialNumberPhoto}
                                                   loading={this.state.serialNumberPhotoLoading}
                                                   onFileUpload={e => this.onSerialNumberPhotoUploadUploadBox(e)}
                                                   onClose={() => this.clearSerialNumber()}
                                                   progress={this.state.serialNumberPhotoUploadProgress}
                                                   text={t('upload_box.text2')}
                                                   withPDF={false}
                                        />
                                        {/*<div hidden={!(this.state.proofOfPurchasePhoto == null)}>*/}
                                        {/*    <button className="link -primary" onClick={() => this.setState({showProofOfPurchasePopup: true})}>{t('s_machine.text11')}</button>*/}
                                        {/*</div>*/}
                                    </div>
                                ) : (
                                    <>
                                        {this.state.serialEnabled && <p className="par-2 --semiBold">{t('s_machine.text2')}</p>}
                                        <FormInputWithFile label={t('s_machine.serial_number')} type="text"
                                                           value={this.state.serialNumber}
                                                           invalidFeedback={t('serial.'+this.state.serialInvalidFeedback)}
                                                           isValid={this.state.serialNumberValid}
                                                           disabled={!this.state.serialEnabled}
                                                           loading={this.state.serialNumberPhotoLoading}
                                                           onChange={(v, isFilled) => this.handleChangeSerialNumber(v, isFilled)}
                                                           onFileUpload={e => this.onSerialNumberPhotoUpload(e)}
                                                           enableFile={this.props.config.msn_obligatory_validation_type.value == 'ocr'}
                                                           scannedValue={this.state.serialNumberScanned}
                                                           progress={this.state.serialNumberPhotoUploadProgress}
                                                           touched={this.state.serialNumberTouched}
                                        />

                                        {!this.state.serialNumberValid && this.state.serialInvalidFeedback === 'serialnumber_incorrect' ? (
                                            <p className="par-2 --small --success1" dangerouslySetInnerHTML={{__html: t('serial.help')}}/>
                                        ) : null}
                                    </>
                                )}

                                {/*<FormInputWithFile label={t('s_machine.serial_number')} type="text"*/}
                                {/*                   value={this.state.serialNumber}*/}
                                {/*                   invalidFeedback={t('serial.'+this.state.serialInvalidFeedback)}*/}
                                {/*                   isValid={this.state.serialNumberValid}*/}
                                {/*                   disabled={!this.state.serialEnabled}*/}
                                {/*                   loading={this.state.serialNumberPhotoLoading}*/}
                                {/*                   onChange={(v, isFilled) => this.handleChangeSerialNumber(v, isFilled)}*/}
                                {/*                   onFileUpload={e => this.onSerialNumberPhotoUpload(e)}*/}
                                {/*                   enableFile={this.props.config.msn_obligatory_validation_type.value == 'ocr'}*/}
                                {/*                   scannedValue={this.state.serialNumberScanned}*/}
                                {/*                   touched={this.state.serialNumberTouched}*/}
                                {/*                   progress={this.state.serialNumberPhotoUploadProgress}*/}
                                {/*/>*/}

                                {this.state.serialEnabled ? (
                                    <div>
                                        <div className="confirmRow -mb">
                                            <div className="confirmRow__info">
                                                {/*<p className="par-3">{t('s_machine.text4')}</p>*/}
                                                <button title={t('s_machine.text5')} className="link -primary"
                                                        onClick={() => this.setState({showSerialNumberPopup: true})}>
                                                    {t('s_machine.text5')}
                                                    <img src={require('../../assets/images/icons/arrow-right-brown.svg')} alt=">"/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="confirmRow ">
                                            <div className="confirmRow__info">
                                                <Button class="-primary" label={t('confirm')} disabled={!this.state.serialNumberValid} onClick={() => this.handleConfirmSerialNumber()}/>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="confirmRow -mb -pl0">
                                        <div className="confirmRow__info">
                                            <div className="confirmRow__edit">
                                                <Edit onClick={() => this.showEditSerialNumberPopup()}/>
                                            </div>
                                            <p className="par-4">{t('s_machine.text6')}</p>
                                        </div>
                                    </div>
                                )}





                            </div>
                        </div>
                        <div className="col">
                            <div className={thumbClasses} hidden={!this.state.serialEnabled || this.state.serialNumberPhoto == null}>
                                <p className="par-2">{t('s_machine.text7')}</p>
                                <div className="thumb__box">
                                    <ButtonClose positionClass="-topRight" onClick={() => this.clearSerialNumber()}/>
                                    <img src={this.state.serialNumberPhoto} alt={this.state.serialNumberPhoto} className="thumb__img"/>
                                </div>
                                {/*<div className="thumb__edit">*/}
                                {/*    <Edit onClick={() => this.serialNumberRef.current.fileInput.current.click()}/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col">
                            {!this.state.serialEnabled && this.state.usingPromocode ? (
                                <div className="machineRegistration__promoCodeInfo">

                                    <p className="par-2 --semiBold">{t('s_machine.enter_promo_code')}</p>

                                    <FormInput label={t('s_machine.promo_code')} type="text"
                                               value={this.state.promotionCode}
                                               invalidFeedback={this.state.promotionCodeInvalidFeedback}
                                               isValid={this.state.promotionCode && this.state.promotionCodeValid}
                                               onChange={e => this.handleChangePromotionCode(e)}
                                    />

                                    <div className="confirmRow">
                                        <div className="confirmRow__info">
                                            <button title={t('s_machine.no_promo_code')} className="link -primary"
                                                    onClick={() => this.handleHidePromo()}>
                                                {t('s_machine.no_promo_code')}
                                                <img src={require('../../assets/images/icons/arrow-right-brown.svg')} alt=">"/>
                                            </button>
                                        </div>
                                        {/*<Button class="-primary -md" label={t('confirm')} disabled={!this.state.promotionCode.match(/^[0-9a-zA-Z\-]+$/)} onClick={() => this.validatePromotionCode()}/>*/}
                                    </div>

                                    {promotionCodeInfo}

                                </div>
                            ) : null}

                            {!this.state.serialEnabled && !this.state.usingPromocode ? (
                                <div className="machineRegistration__uploadBox" >


                                    {this.props.config.pop_validation.value == 'ocr' ? (
                                        <>
                                            <p className="par-2">
                                                <span dangerouslySetInnerHTML={{__html: t('s_machine.text8')}}/>
                                                {/*<button title={t('s_machine.use_promo_code')} className="link -primary --1em"*/}
                                                {/*        onClick={() => this.handleShowPromo()}>*/}
                                                {/*    {t('s_machine.use_promo_code')}*/}
                                                {/*</button>*/}
                                                {/*{t('s_machine.use_promo_code')}*/}
                                            </p>
                                            <UploadBox file={this.state.proofOfPurchasePhoto}
                                                       loading={this.state.proofOfPurchasePhotoLoading}
                                                       onFileUpload={e => this.onProofOfPurchasePhotoUpload(e)}
                                                       onClose={() => this.clearPurchase()}
                                                       progress={this.state.proofOfPurchasePhotoUploadProgress}
                                            />
                                            <div hidden={!(this.state.proofOfPurchasePhoto == null)}>
                                                <button className="link -primary" onClick={() => this.setState({showProofOfPurchasePopup: true})}>{t('s_machine.text11')}</button>
                                            </div>
                                        </>
                                    ) : null}

                                    {this.props.config.pop_validation.value == 'manual' || this.state.proofOfPurchasePhoto ? (
                                        <div>
                                            <p></p>
                                            {this.props.config.pop_validation.value == 'ocr' ? (
                                                <p className="par-2">{t('s_machine.text9')}</p>
                                            ) : (
                                                <p className="par-2">
                                                    <span dangerouslySetInnerHTML={{__html: t('s_machine.text12')}}/>
                                                    <button title={t('s_machine.use_promo_code')} className="link -primary --1em"
                                                            onClick={() => this.setState({usingPromocode: true})}>
                                                        {t('s_machine.use_promo_code')}
                                                    </button>
                                                </p>
                                            )}
                                            {this.POP_VALIDATION_OCR.value == 'ocr' || this.POP_VALIDATION_OCR.options.date.display == 'on' ? (
                                                <FormDatePicker name="purchasing_date" label={t('s_machine.purchasing_date')}
                                                                isValid={this.state.purchase.date}
                                                                invalidFeedback={t('purchase.cannot_read1')}
                                                                value={this.state.purchase.date}
                                                                selected={this.state.purchase.date}
                                                                maxDate={this.getMaxPurchasingDate()}
                                                                touched={this.state.purchase.forceTouched}
                                                                loading={this.state.proofOfPurchasePhotoLoading}
                                                                onBlur={e => this.addLogProofOfPurchaseManual('date')}
                                                                onChange={e => this.setState({purchase: {...this.state.purchase, date: e}})}
                                                                required={this.POP_VALIDATION_OCR.options.date.mandatory == 'on'}
                                                />
                                            ) : null}
                                            {this.POP_VALIDATION_OCR.value == 'ocr' || this.POP_VALIDATION_OCR.options.trade_partner.display == 'on' ? (
                                                <FormSelect name="trade_partner" label={t('s_machine.trade_partner')}
                                                            isValid={this.state.purchase.tradePartner != null}
                                                            invalidFeedback={t('purchase.cannot_read1')}
                                                            options={this.state.tradePartners}
                                                            touched={this.state.purchase.forceTouched}
                                                            selected={this.state.purchase.tradePartner}
                                                            loading={this.state.proofOfPurchasePhotoLoading}
                                                            isSearchable={true}
                                                            onBlur={e => this.addLogProofOfPurchaseManual('tradePartner')}
                                                            onChange={e => this.setState({purchase:{...this.state.purchase, tradePartner: e}}, this.loadShopList.bind(this))}
                                                            required={this.POP_VALIDATION_OCR.options.trade_partner.mandatory == 'on'}
                                                />
                                            ) : null }
                                            {this.POP_VALIDATION_OCR.value == 'ocr' || this.POP_VALIDATION_OCR.options.trade_partner.display == 'on' && this.POP_VALIDATION_OCR.options.retailer_store.display == 'on' ? (
                                                <FormSelect name="retailer_store" label={t('s_machine.retailer_store')}
                                                            isValid={this.state.purchase.retailerStore != null}
                                                            invalidFeedback={t('purchase.cannot_read1')}
                                                            options={this.state.retailerStores}
                                                            touched={this.state.purchase.forceTouched}
                                                            selected={this.state.purchase.retailerStore}
                                                            loading={this.state.proofOfPurchasePhotoLoading}
                                                            isSearchable={true}
                                                            onBlur={e => this.addLogProofOfPurchaseManual('retailerStore')}
                                                            onChange={e => this.setState({purchase:{...this.state.purchase, retailerStore: e}})}
                                                            required={this.POP_VALIDATION_OCR.options.trade_partner.mandatory == 'on'}
                                                />
                                            ) : null }
                                            {this.POP_VALIDATION_OCR.value == 'ocr' || this.POP_VALIDATION_OCR.options.invoice_number.display == 'on' ? (
                                                <FormInput name="invoice_number" label={t('s_machine.invoice_number')} type="text"
                                                           value={this.state.purchase.invoiceNumber}
                                                           isValid={!!this.state.purchase.invoiceNumber}
                                                           invalidFeedback={t('purchase.cannot_read1')}
                                                           touched={this.state.purchase.forceTouched}
                                                           loading={this.state.proofOfPurchasePhotoLoading}
                                                           onBlur={e => this.addLogProofOfPurchaseManual('invoiceNumber')}
                                                           onChange={e => this.setState({purchase:{...this.state.purchase, invoiceNumber: e}})}
                                                           required={this.POP_VALIDATION_OCR.options.invoice_number.mandatory == 'on'}
                                                           min={0}
                                                />
                                            ) : null }
                                            {this.POP_VALIDATION_OCR.value == 'ocr' || this.POP_VALIDATION_OCR.options.machine_price.display == 'on' ? (
                                                <FormInput name="invoice_total_value" label={t('s_machine.invoice_total_value')} type="text"
                                                           value={this.state.purchase.invoiceTotalValue}
                                                           isValid={!!this.state.purchase.invoiceTotalValue}
                                                           invalidFeedback={t('purchase.cannot_read1')}
                                                           touched={this.state.purchase.forceTouched}
                                                           loading={this.state.proofOfPurchasePhotoLoading}
                                                           onBlur={e => this.addLogProofOfPurchaseManual('invoiceTotalValue')}
                                                           onChange={e => this.setState({purchase:{...this.state.purchase, invoiceTotalValue: e}})}
                                                           required={this.POP_VALIDATION_OCR.options.machine_price.mandatory == 'on'}
                                                           min={0}
                                                />
                                            ) : null }

                                            <p className="par-2">{t('s_machine.retailers_info1')}<a href={t('s_machine.retailers_info_link')} target="_blank" className="--brown">{t('s_machine.retailers_info2')}</a>{t('s_machine.retailers_info3')}</p>

                                        </div>
                                    ) : null}
                                </div>

                            ) : null}




                            {/*<div className="machineRegistration__nextStep" hidden={this.state.serialEnabled}>*/}
                            {/*    <Button class="-primary" label="continue" disabled={!(this.state.proofOfPurchasePhoto != null)} onClick={() => this.nextStep()}/>*/}
                            {/*</div>*/}

                            {!this.state.serialEnabled ? (
                                <div className="step__nav">
                                    {/*<Button class="-primary -outline -sm" label={t('back')} disabled={false} onClick={() => this.clearPurchase()}/>*/}

                                    {/*<Button class="-primary" label={t('continue')} disabled={!(this.state.proofOfPurchasePhoto != null || this.props.config.pop_validation.value == 'manual') && !(this.state.promotionCodeChecked && this.state.promotionCode.length > 0)} onClick={() => this.nextStep()}/>*/}
                                    <Button class="-primary" label={t('continue')} disabled={this.isContinueDisabled()} onClick={() => this.nextStep()}/>
                                </div>
                            ) : null}

                        </div>
                        <div className="col"/>
                    </div>


                    <ProofOfPurchasePopup show={this.state.showProofOfPurchasePopup} onClose={() => this.setState({showProofOfPurchasePopup: false})}/>

                    <Popup show={this.state.showEditSerialNumberPopup}
                           onClose={() => this.onCloseEditSerialNumberPopup()}>
                        <div className="popup__header">
                            <h3 className="popup__title">{t('s_machine.text10')}</h3>
                        </div>

                        <div className="popup__body">
                            <div className="machineRegistration__serialNumberPopup">
                                {this.state.showEditSerialNumberPopup ? (
                                    <FormInputWithFile label={t('s_machine.serial_number')} type="text"
                                                       value={this.state.serialNumber}
                                                       invalidFeedback={t('serial.'+this.state.serialInvalidFeedback)}
                                                       isValid={this.state.serialNumberValid}
                                                       disabled={false}
                                                       loading={this.state.serialNumberPhotoLoading}
                                                       onChange={(v, isFilled) => this.handleChangeSerialNumber(v, isFilled)}
                                                       onFileUpload={e => this.onSerialNumberPhotoUpload(e)}
                                                       enableFile={this.props.config.msn_obligatory_validation_type.value == 'ocr'}
                                                       scannedValue={this.state.serialNumberScanned}
                                                       touched={this.state.serialNumberTouched}
                                                       progress={this.state.serialNumberPhotoUploadProgress}
                                                       ref={this.serialNumberRef}
                                    />
                                ) : null}


                                {this.state.serialEnabled ? (
                                    <div>
                                        <div className="confirmRow -mb">
                                            <div className="confirmRow__info">
                                                {/*<p className="par-3">{t('s_machine.text4')}</p>*/}
                                                <button title={t('s_machine.text5')} className="link -primary"
                                                        onClick={() => this.setState({showSerialNumberPopup: true})}>
                                                    {t('s_machine.text5')}
                                                    <img src={require('../../assets/images/icons/arrow-right-brown.svg')} alt=">"/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="confirmRow ">
                                            <div className="confirmRow__info">
                                                <Button class="-primary" label={t('confirm')} disabled={!this.state.serialNumberValid}
                                                        onClick={() => {
                                                            this.validateSerialNumber(() => {
                                                                if(this.state.serialNumberValid)
                                                                    this.setState({showEditSerialNumberPopup: false}, () => {
                                                                        this.handleConfirmSerialNumber()
                                                                    })
                                                            })
                                                        }}/>

                                            </div>
                                        </div>
                                    </div>
                                ) : null}

                                {/*<div className="confirmRow" hidden={!this.state.serialEnabled}>*/}
                                {/*    <div className="confirmRow__info">*/}
                                {/*        <p className="par-3">{t('s_machine.text4')}</p>*/}
                                {/*        <button title={t('s_machine.text5')} className="link -primary"*/}
                                {/*                onClick={() => this.setState({showSerialNumberPopup: true})}>{t('s_machine.text5')}</button>*/}
                                {/*    </div>*/}
                                {/*    <Button class="-primary -md" label={t('confirm')} disabled={!this.state.serialNumberValid} onClick={() => {*/}
                                {/*        this.validateSerialNumber(() => {*/}
                                {/*            if(this.state.serialNumberValid)*/}
                                {/*                this.setState({showEditSerialNumberPopup: false}, () => {*/}
                                {/*                    this.handleConfirmSerialNumber()*/}
                                {/*                })*/}
                                {/*        })*/}
                                {/*    }}/>*/}
                                {/*</div>*/}

                                <div className={thumbClasses} hidden={!this.state.serialEnabled || this.state.serialNumberPhoto == null}>
                                    <p className="par-2">{t('s_machine.text7')}</p>
                                    <div className="thumb__box">
                                        <ButtonClose positionClass="-topRight" onClick={() => this.clearSerialNumber()}/>
                                        <img src={this.state.serialNumberPhoto} alt={this.state.serialNumberPhoto} className="thumb__img"/>
                                    </div>
                                    {/*<div className="thumb__edit">*/}
                                    {/*    <Edit onClick={() => this.serialNumberRef.current.fileInput.current.click()}/>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </Popup>

                    <FindSerialNumberPopup show={this.state.showSerialNumberPopup} onClose={() => this.setState({showSerialNumberPopup: false})}/>

                </div>
            </StepCustom>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MachineRegistration);
