import React, {Component} from "react";
import * as actions from '../../actions/actions';
import {connect} from "react-redux";
import {clearStore, setCsAuthorization, setLastValidStep, translate as t} from "../../actions/store";
import {Button, FormInput, ProofOfPurchasePopup, StepCustom} from "../index";
import './CorpSubscriptionAuthorization.css';


const mapDispatchToProps = (dispatch) => ({
    setLastValidStep: step => dispatch(setLastValidStep(step)),
    setCsAuthorization: (csAuthorization) => dispatch(setCsAuthorization(csAuthorization)),
    clearStore: () => dispatch(clearStore()),
})


const mapStateToProps = (state) => ({
    csAuthorization: state.csAuthorization,
    lang: state.lang,
})


class CorpSubscriptionAuthorization extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: props.csAuthorization?.email ?? '',
            isEmailValid: !!props.csAuthorization?.isEmailValid,
            emailInvalidFb: t('cs_authorization.email_invalid'),
            emailAccepted: !!props.csAuthorization,

            code: props.csAuthorization?.code ?? '',
            isCodeValid: !!props.csAuthorization?.isCodeValid,
            codeInvalidFb: t('cs_authorization.code_invalid'),

            token: props.csAuthorization?.token ?? '',
            hash: props.csAuthorization?.hash ?? '',
        };

        // console.log('props: ', props)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.email !== this.state.email) {
            this.validateEmail();
        }
        if(prevState.code !== this.state.code) {
            this.validateCode();
        }

        // todo: session
    }

    validateEmail() {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.setState({
            isEmailValid: this.state.email.match(re),
        })
    }

    validateCode() {
        let valid = false;

        if(this.state.code?.length === 6) {
            valid = true;
        }

        this.setState({
            isCodeValid: valid,
        }, () => {
            this.props.setLastValidStep(this.props.stepNo);
        })
    }

    submitEmail(e) {
        e.preventDefault();

        this.props.clearStore();

        if(this.state.isEmailValid) {
            actions.csCheckEmail(this.state.email, this.props.lang).then(res => {
                if(res.status.toString() === 'success') {
                    this.setState({
                        emailAccepted: true,
                        hash: res.data.hash,
                    }, () => {
                        this.props.setCsAuthorization(this.state);
                    })
                }
                else {
                    const errorMessage = res.message === 'order_with_this_email_exist' ? 'order_with_this_email_exist' : 'email_invalid';
                    this.setState({
                        isEmailValid: false,
                        emailInvalidFb: t(`cs_authorization.${errorMessage}`)
                    })
                }
            })
        }
    }


    submitCode(e) {
        e.preventDefault();

        if(this.state.isCodeValid) {
            actions.csCheckCode(this.state.code).then(res => {
                if(res.status.toString() === 'success') {
                    this.setState({
                        token: res.data.token,
                    }, () => {
                        this.props.setCsAuthorization(this.state);
                        this.props.showStep(this.props.stepNo + 1)
                    })
                }
                else {
                    this.setState({
                        isCodeValid: false,
                    })
                }
            })
        }
    }


    backToEmail(e) {
        e.preventDefault();

        this.setState({
            code: '',
            emailAccepted: false,
        }, () => {
            this.props.setCsAuthorization(this.state);
        })
    }


    render() {
        return (
            <StepCustom label={t('cs_authorization._title')}
                        stepNo={this.props.stepNo}
                        currentStep={this.props.currentStep}
                        showStep={step => this.props.showStep(step)}
                // nextStep={step => this.nextStep(step)}
            >
                <div className="csAuthorization">
                    {!this.state.emailAccepted && (
                        <div className="csAuthorization__grid --mb3">
                            <div className="csAuthorization__col">
                                <>
                                    <img src={require('../../assets/images/stay-home-about.jpg')} alt="" className="csAuthorization__thumb"/>
                                </>
                            </div>
                            <div className="csAuthorization__about">
                                <p className="par-1 --mb2" dangerouslySetInnerHTML={{__html: t('cs_authorization.about')}}/>
                            </div>
                        </div>
                    )}
                    <div className="csAuthorization__grid">
                        <div>
                            {!this.state.emailAccepted && (
                                <>
                                    <p className="par-2 --mb2">{t('cs_authorization.text1')}</p>
                                </>
                            )}


                            {!this.state.emailAccepted ? (
                                <form onSubmit={e => this.submitEmail(e)}>
                                    <FormInput label={t('cs_authorization.email')}
                                               type="email"
                                               isValid={this.state.isEmailValid}
                                               invalidFeedback={this.state.emailInvalidFb}
                                               value={this.state.email}
                                               onChange={v => this.setState({email: v})}
                                    />

                                    <Button class="-primary"
                                            onClick={e => this.submitEmail(e)}
                                            disabled={!this.state.isEmailValid}>{t('cs_authorization.continue')}</Button>
                                </form>
                            ) : (
                                <div>
                                    <p className="par-2 --mb2" style={{letterSpacing: '1px'}} dangerouslySetInnerHTML={{__html: t('cs_authorization.code_sent')}}/>
                                    <form onSubmit={e => this.submitCode(e)}>
                                        <FormInput label={t('cs_authorization.code')}
                                                   type="email"
                                                   isValid={this.state.isCodeValid}
                                                   invalidFeedback={this.state.codeInvalidFb}
                                                   value={this.state.code}
                                                   onChange={v => this.setState({code: v})}
                                        />

                                        <div className="step__nav">
                                            <Button class="-primary -outline"
                                                    onClick={e => this.backToEmail(e)}>{t('back')}</Button>
                                            <Button class="-primary"
                                                    onClick={e => this.submitCode(e)}
                                                    disabled={!this.state.isCodeValid}>{t('cs_authorization.continue')}</Button>
                                        </div>
                                    </form>
                                </div>
                            )}



                        </div>
                        <div/>
                    </div>
                </div>
            </StepCustom>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CorpSubscriptionAuthorization);
