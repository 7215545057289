import React, {Component} from 'react';
import './Utils.css';
import {translate as t} from "../../actions/store";

class Edit extends Component {
    render() {
        return (
          <button className="edit" onClick={() => this.props.onClick()}>{t('edit')} <img src={require('../../assets/images/icons/edit.svg')} alt=""/></button>
        );
    }
}

export default Edit;
