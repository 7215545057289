import React from "react";
import {translate as t} from '../../actions/store';
import './errorPage.css';

export default function Error404() {
    return (
        <main className="main">
            <div className="wrapper-bg">
                <div className="error">
                    <h1 className="error__code">404</h1>
                    <h2 className="error__text">{t('page_error.404')}</h2>
                </div>
            </div>
        </main>
    );
}