import React, {Component} from 'react';
import {translate as t} from "../../actions/store";

class ValuesTooltip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
            customValue: null,
        }

        this.inputRef = new React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.value != this.state.value)
            this.setState({value: this.props.value});
    }

    render() {
        return (
            <div className="valuesTooltip">
                <div className="valuesTooltip__items">
                    {this.props.options.map((option, key) => {
                        let active = option === this.state.value ? '-active' : null;
                        return <button key={key} className={active} onClick={() => this.props.onChange(option)}>{option}</button>;
                    })}
                </div>
                <div className="valuesTooltip__form">
                    <label onClick={() => this.inputRef.current.focus()}>{t('values_tooltip.text')}</label>
                    <input type="number" ref={this.inputRef} onChange={e => e.target.value % 10 === 0 ? this.setState({customValue: parseInt(e.target.value)}) : null}/>
                    <button onClick={() => this.props.onChange(this.state.customValue > 0 ? this.state.customValue : this.state.value)}>{t('values_tooltip.confirm')}</button>
                </div>
            </div>
        )
    }
}

export default ValuesTooltip;
