import React, {Component} from 'react';
import './Utils.css';
import {showPopup, translate as t} from "../../actions/store";
import {connect} from "react-redux";

const mapDispatchToProps = (dispatch) => ({
    showPopup: (popup, show) => dispatch(showPopup(popup, show))
})

class GetInTouch extends Component {
    render() {
        return (
          <span className="getInTouch">
              <span className="getInTouch__text">{t('need_help')}</span>
              <button className="getInTouch__link" onClick={() => this.props.showPopup('getInTouchPopup', true)}>{t('get_in_touch')}</button>
          </span>
        );
    }
}

export default connect(null, mapDispatchToProps)(GetInTouch);
