import React, {Component} from 'react';
import './CorpSubscriptionSelectOffer.css';
import {Link} from "react-router-dom";
import {StepCustom, Alert, Button, CoffeeItem, FilterPopup, Step, CartValidationAlert} from "../index";
import { connect } from 'react-redux';
import {getCsProducts, getProducts} from "../../actions/actions";
import {allProducts, updateCart, translate as t, setLastValidStep} from "../../actions/store";

const mapStateToProps = (state) => ({
    purchase: state.purchase,
    id_shop_gear: state.id_shop_gear,
    promotion: state.promotion,
    cart: state.cart,
    cartSum: state.cartSum,
    lang: state.lang,
    csGear: state.csGear,
    allProducts: state.allProducts,
})

const mapDispatchToProps = (dispatch) => ({
    allProducts: (products, autoAdded) => dispatch(allProducts(products, autoAdded)),
    updateCart: (cart) => dispatch(updateCart(cart)),
    setLastValidStep: step => dispatch(setLastValidStep(step)),
})

class CorpSubscriptionSelectOffer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cart: props.cart,
            cartSum: props.cartSum,
            filters: {},
            sort: {
                label: 'Recommended',
                value: 'recommended',
            },
            simpleFilter: null,
            type: null,

            showFilterPopup: false,
            products: [],
            allProducts: {},
            autoAdded: [],
            productsToShow: [],
            constraints: [],
            canFinish: false,

            showCoffees: false,
        }

        this.constraints = [];

        this.filterOptions = [
            {
                name: t('filter.intensity._title'),
                value: 'intensity',
                options: [
                    {
                        name: t('filter.intensity.1_4') + ' (1-4)',
                        value: '1-4',
                    },
                    {
                        name: t('filter.intensity.5_8') + ' (5-8)',
                        value: '5-8',
                    },
                    {
                        name: t('filter.intensity.9_15') + ' (9-15)',
                        value: '9-15',
                    },
                ]
            },
            {
                name: t('filter.cups_sizes._title'),
                value: 'cups_sizes',
                options: [
                    {
                        name: t('filter.cups_sizes.xs'),
                        value: 'xs',
                        img: '',
                    },
                    {
                        name: t('filter.cups_sizes.s'),
                        value: 's',
                        img: '',
                    },
                    {
                        name: t('filter.cups_sizes.m'),
                        value: 'm',
                        img: '',
                    },
                    {
                        name: t('filter.cups_sizes.milk'),
                        value: 'milk',
                        img: '',
                    },
                ]
            },
            {
                name: t('filter.aromatic_profile._title'),
                value: 'aromatic_profile',
                options: [
                    {
                        name: t('filter.aromatic_profile.fruity_flowery'),
                        value: 'fruity_flowery',
                    },
                    {
                        name: t('filter.aromatic_profile.balanced'),
                        value: 'balanced',
                    },
                    {
                        name: t('filter.aromatic_profile.intense'),
                        value: 'intense',
                    },
                ]
            },
        ];

        this.sortOptions = [
            {
                label: 'Recommended',
                value: 'recommended',
            },
            {
                label: 'Lorem ipsum1',
                value: 'lorem_ipsum1',
            },
            {
                label: 'Lorem ipsum2',
                value: 'lorem_ipsum2',
            },
            {
                label: 'Lorem ipsum3',
                value: 'lorem_ipsum3',
            },
        ];

        this.categoriesRef = React.createRef();
        this.categoriesMobileRef = React.createRef();
    }

    componentDidMount() {
        // console.log(this.props.id_shop_gear, this.props.promotion, this.props.purchase);

        this.getAllProducts();

        if(this.props.cart.items.length > 0) {
            this.setState({showCoffees: true})
        }

        this.stickyEventListener = window.addEventListener('scroll', () => this.handleStickyEventListener(), false)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.stickyEventListener, false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.lang != prevProps.lang) {
            this.setState({
                lang: this.props.lang
            }, () => {
                this.getAllProducts();
            })
        }

        if(this.props.cartSum != this.state.cartSum) {
            this.setState({
                cartSum: this.props.cartSum,
                cart: this.props.cart,
                canFinish: this.canFinish(),
            });

            this.getConstraints();
        }

        if(prevState !== this.state) {
            this.props.keepSession();
        }
    }

    getAllProducts() {
        getCsProducts(this.props.lang).then(res => {
            if(res.status === 'success') {
                this.props.allProducts(res.data.allProducts, [])


                this.setState({
                    allProducts: res.data.allProducts,
                    products: res.data.categories,
                }, () => {
                    this.setState({
                        canFinish: this.canFinish(),
                    })
                })
            }
        }).catch(error => console.error(error))
    }

    canFinish() {
        let value = 0;
        this.props.cart.items.map(i => {
                value += i.value;
        })

        const valid = value >= this.props.csGear.corporate_subscription_plan_subrebate_price;
        if(valid) {
            this.props.setLastValidStep(this.props.stepNo);
        }

        return valid;
    }

    getConstraints() {
        let constraints = [];
        this.state.cart.items.map(item => {
            constraints.push(...this.constraints.filter(c => c.id_shop_product_src === item.itemId)
                .filter(c => !constraints.includes(c))
                .map(c => c.id_shop_product_dst))
        })
        this.setState({
            constraints,
        })
    }

    handleStickyEventListener() {
        const header = document.querySelector('.header');
        const cartBox = document.querySelector('.cartBox');

        let height = header.scrollHeight;
        if(cartBox) {
            height -= cartBox.scrollHeight + 1;
        }

        if(this.categoriesRef.current) {
            this.categoriesRef.current.style.top = height + 'px';

            if(this.categoriesRef.current.getBoundingClientRect().top <= height) {
                this.categoriesRef.current.classList.add('-sticky');
            }
            else {
                this.categoriesRef.current.classList.remove('-sticky');
            }
        }

        if(this.categoriesMobileRef.current) {
            this.categoriesMobileRef.current.style.top = height + 'px';

            if(this.categoriesMobileRef.current.getBoundingClientRect().top <= height) {
                this.categoriesMobileRef.current.classList.add('-sticky');
            }
            else {
                this.categoriesMobileRef.current.classList.remove('-sticky');
            }
        }

        // const typesSection = document.querySelector('.selectOffer__types');
        // const header = document.querySelector('.header');
        // const cartBox = document.querySelector('.cartBox');
        //
        // if(!typesSection) {
        //     return;
        // }
        //
        // let height = header.scrollHeight;
        // if(cartBox)
        //     height -= cartBox.scrollHeight + 1;
        //
        // if(document.querySelector('body').offsetWidth <= 992) {
        //     height -= 45;
        // }
        //
        // if(typesSection.getBoundingClientRect().top <= height) {
        //     typesSection.classList.add('-sticky');
        //
        //     typesSection.style.top = height + 'px';
        //     document.querySelector('.wrapper-bg').style.overflowX = 'unset';
        //     document.querySelector('body').style.overflowX = 'hidden';
        // }
        // else {
        //     typesSection.classList.remove('-sticky');
        //     typesSection.style.top = null;
        //     document.querySelector('.wrapper-bg').style.overflowX = null;
        //     document.querySelector('body').style.overflowX = null;
        // }
    }

    getProductsCount() {
        let count = 0;

        let isFiltered = false;

        Object.keys(this.state.filters).map(filter => {
            if(this.state.filters[filter].length > 0)
                isFiltered = true;
        })

        if(isFiltered) {
            count = this.state.productsToShow.length;
        }
        else {
            Object.keys(this.state.products).map(masterCategory => {
                const products = this.state.products[masterCategory];
                if(products.categories === undefined) {
                    return null;
                }
                products.categories.map(cat => {
                    count += cat.products.length;
                })

            })
        }


        return count;
    }

    modifyCartItem(id, value) {
        const order = {
            itemId: id,
            value: value,
        }

        let cart = this.state.cart;
        let index = this.state.cart.items.findIndex(i => i.itemId === id);
        if (index > -1) {

            if (value < 10)
                cart.items.splice(index, 1);
            else
                cart.items[index] = order;

        } else {
            cart.items.push(order);

        }
        this.setState({cart: cart}, () => {
            this.props.updateCart(this.state.cart)
        })
    }

    handleType(option) {
        this.setState({type: option}, () => {
            const el = document.getElementById(option);

            const typesSection = document.querySelector('.categories');
            const header = document.querySelector('.header');

            let top = el.getBoundingClientRect().top + document.documentElement.scrollTop;
            top -= typesSection.scrollHeight + header.scrollHeight + 15;

            window.scrollTo({
                top: top,
                left: 0,
                behavior: 'smooth',
            })
        });
    }

    handleSimpleFilter(option) {
        this.setState({simpleFilter: option});
    }

    handleFilter(filter, option) {
        let filters = this.state.filters;
        if(!filters[filter]) {
            filters[filter] = [];
            filters[filter].push(option);
        }
        else {
            let index = filters[filter].indexOf(option);
            if(index !== -1) {
                filters[filter].splice(index, 1);
            }
            else {
                filters[filter].push(option);
            }
        }

        this.setState({filters: filters}, () => {
            const filters = this.state.filters;
            let productsToShow = [];
            let items = [];
            // let oldItems = [];

            let i = 0;
            Object.keys(this.state.filters).map(filter => {
                if(filters[filter].length > 0) {
                    const values = filters[filter];
                    switch (filter) {
                        case 'intensity': {
                            Object.keys(this.state.products).map(masterCategory => {
                                if(this.state.products[masterCategory].categories) {
                                    this.state.products[masterCategory].categories.map((category, i) => {
                                        productsToShow = productsToShow.concat(category.products.filter(productId => {
                                            const product = this.state.allProducts[productId];
                                            let valid = false;
                                            values.map(value => {
                                                const [min, max] = value.toString().split('-');
                                                if (product.intensity >= min && product.intensity <= max)
                                                    valid = true;
                                            })

                                            return valid;
                                        }))
                                    })
                                }
                            })
                            break;
                        }
                        case 'cups_sizes': {
                            Object.keys(this.state.products).map(masterCategory => {
                                if(this.state.products[masterCategory].categories) {
                                    this.state.products[masterCategory].categories.map((category, i) => {
                                        productsToShow = productsToShow.concat(category.products.filter(productId => {
                                            const product = this.state.allProducts[productId];
                                            let valid = false;

                                            const cupSizes = product.cupsSizes.toString().split(' ');

                                            if(values.includes('milk')) {
                                                valid = values.find(value => {
                                                    return cupSizes.includes(value) && (product.coffeeType === 'milk' || product.coffeeType === 'double_serving')
                                                })
                                            }
                                            else {
                                                valid = values.find(value => cupSizes.includes(value) && (product.coffeeType === 'nomilk' || product.coffeeType === 'double_serving'))
                                            }

                                            // if(values.includes('milk')) {
                                            //     if(product.coffeeType === 'milk') {
                                            //         valid = true;
                                            //     }
                                            // }
                                            // else {
                                            //     const cupSizes = product.cupsSizes.toString().split(' ');
                                            //     valid = values.find(value => cupSizes.includes(value))
                                            // }


                                            return valid;
                                        }))
                                    })
                                }
                            })
                            break;
                        }
                        case 'aromatic_profile': {
                            Object.keys(this.state.products).map(masterCategory => {
                                if(this.state.products[masterCategory].categories) {
                                    this.state.products[masterCategory].categories.map((category, i) => {
                                        productsToShow = productsToShow.concat(category.products.filter(productId => {
                                            const product = this.state.allProducts[productId];
                                            let valid = false;
                                            let v = product.aromaticProfile.toString().toLowerCase();
                                            v = v.replaceAll(' ', '').replaceAll('/','_');

                                            values.map(value => {
                                                if (value === v)
                                                    valid = true;
                                            })
                                            return valid;
                                        }))
                                    })
                                }
                            })
                            break;
                        }
                    }

                    if(i > 0) {
                        // console.log('product to show: ', productsToShow)
                        items = items.filter(v => productsToShow.includes(v));
                        // console.log('new items: ', items);
                    }
                    else {
                        items = productsToShow;
                        // console.log('items: ', items)
                    }

                    productsToShow = [];
                    i++;
                }
            })

            // console.log('show: ', items)

            this.setState({
                productsToShow: items,
            })
        })
    }


    handleSort(option) {
        this.setState({sort: option})
    }

    handleReset() {
        this.setState({
            sort: this.sortOptions[0],
            filters: [],
            productsToShow: [],
        })
    }

    handleFilterSubmit() {
        this.setState({
            showFilterPopup: false,
            // products
        }, () => {
            // ...
        })
    }

    hasPackets() {
        return !!Object.keys(this.state.products).find(id => this.state.products[id].category.product_interval === 1)
    }

    render() {
        return (
            <StepCustom label={t('cs_s_offer._title')}
                        stepNo={this.props.stepNo}
                        currentStep={this.props.currentStep}
                        showStep={step => this.props.showStep(step)}
                        // nextStep={step => this.props.nextStep(step)}
            >

                <div className="selectOffer">

                    {/*<div className="selectOffer__banner">*/}
                    {/*    <img src={require('../../assets/images/banner.jpg')} alt="Banner"/>*/}
                    {/*</div>*/}

                    {this.state.products.map(mainCategory => (
                        <div key={mainCategory.category.id_shop_category} className="categories step__box" ref={this.categoriesRef}>
                            <button className="categories__filterBtn"
                                    disabled={!this.state.showCoffees}
                                    onClick={() => this.setState({showFilterPopup: true})}
                                    style={{opacity: this.state.showCoffees ? 1 : 0}}
                            >
                                <img src={require('../../assets/images/icons/filter.svg')} alt="Type"/>
                            </button>
                            <div className="categories__items">
                                {mainCategory.subcategories?.map(category => !!category.products.length && (
                                    <div key={category.category.title} className={`categories__item ${this.state.type === category.category.title ? '-active' : ''}`} onClick={() => this.handleType(category.category.id_shop_category)}>
                                        <div className="categories__thumb">
                                            {/*<img src={require('../../assets/images/type-bg.png')} alt={container.category.title} className="categories__bg"/>*/}
                                            <img src={category.category.filename ?? require('../../assets/images/type-bg2.png')} alt={category.category.title} className="categories__bg"/>
                                            <img src={category.products[0]?.img} alt={category.category.title} className="categories__img"/>
                                        </div>
                                        <div className="categories__label">{category.category.title}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}


                    {!this.state.products || this.state.products.length === 0 ? (
                        <div className="preloader">
                            <img src={require('../../assets/images/icons/preloader2.gif')} alt="..."/>
                        </div>
                    ) : null}


                    {this.state.products.map(mainCategory => (
                        <div key={mainCategory.category.id_shop_category} className="categoriesMobile step__box" ref={this.categoriesMobileRef}>
                            <div className="categoriesMobile__scroll">
                                <div className="categoriesMobile__items">
                                    {mainCategory.subcategories?.map(category => !!category.products.length && (
                                        <div key={category.category.title} className={`categories__item ${this.state.type === category.category.title ? '-active' : ''}`} onClick={() => this.handleType(category.category.id_shop_category)}>
                                            <div className="categories__thumb">
                                                {/*<img src={require('../../assets/images/type-bg.png')} alt={container.category.title} className="categories__bg"/>*/}
                                                <img src={category.category.filename ?? require('../../assets/images/type-bg2.png')} alt={category.category.title} className="categories__bg"/>
                                                <img src={category.products[0]?.img} alt={category.category.title} className="categories__img"/>
                                            </div>
                                            <div className="categories__label">{category.category.title}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}


                    {this.state.products.map(mainCategory => (
                        <div key={mainCategory.category.id_shop_category} className="selectOffer__category">
                            {mainCategory.subcategories?.map(category => !!category.products.length && (
                                <div key={category.category.id_shop_category}>
                                    <div id={category.category.id_shop_category} className="selectOffer__categoryTitle">{category.category.title}</div>
                                    {!!category.description && (
                                        <div className="selectOffer__header">
                                            <div className="selectOffer__headerText">
                                                header text
                                            </div>
                                        </div>
                                    )}
                                    <div className="selectOffer__items">
                                        {category.products.map(product => {
                                            let itemCart = this.state.cart.items.find(i => i.itemId === product.id);
                                            const itemCartConfig = {
                                                value: itemCart ? itemCart.value : 0,
                                                step: product.step,
                                                min: 10,
                                                max: product.maxQty,
                                                options: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 150, 200, 250, 300],
                                            };
                                            return (
                                                <CoffeeItem key={product.id}
                                                            item={product}
                                                            itemCartConfig={itemCartConfig}
                                                            disabled={true}
                                                            onChange={(id, val) => this.modifyCartItem(id, val)}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}



                </div>


                <CartValidationAlert cart={this.state.cart} csGear={this.props.csGear}/>


                <div className="step__nav">
                    <Button class="-primary -outline" label={t('back')} disabled={false} onClick={() => this.props.showStep(this.props.stepNo - 1)}/>

                    <Button class="-primary" label={t('continue')} disabled={!this.state.canFinish} onClick={() => this.props.showStep(this.props.stepNo + 1)}/>
                </div>


                <FilterPopup show={this.state.showFilterPopup}
                             onClose={() => this.handleFilterSubmit()}
                             filterOptions={this.filterOptions}
                             sortOptions={this.sortOptions}
                             filters={this.state.filters}
                             sort={this.state.sort}
                             resultsCount={this.getProductsCount()}
                             onClickReset={() => this.handleReset()}
                             onChangeSort={option => this.handleSort(option)}
                             onChangeFilter={(filter, option) => this.handleFilter(filter, option)}
                />
            </StepCustom>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorpSubscriptionSelectOffer);
