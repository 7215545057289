import React, {Component} from 'react';
import './Popups.css';
import {Button, GetInTouch, PlusMinusButtons, Popup} from "../index";
import { connect } from 'react-redux';
import {translate as t} from "../../actions/store";

const mapStateToProps = (state) => ({
    allProducts: state.allProducts,
    currency: state.currency,
})

class CoffeePopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inCartValue: props.inCartValue,
        }

        this.comparableItems = [];

        for(let i in props.item.comparableItems) {
            let id = parseInt(props.item.comparableItems[i]);
            if("undefined" !== typeof props.allProducts[id]) {
                this.comparableItems.push(props.allProducts[id]);
            }
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.itemCartConfig.value !== this.state.inCartValue)
            this.setState({inCartValue: this.props.itemCartConfig.value})
    }

    toggleSection(element) {
        const section = element.target.closest('.coffeePopup__section')
        const sectionBody = section.querySelector('.coffeePopup__sectionBody')
        if(section.classList.contains('-active')) {
            section.classList.remove('-active');
            sectionBody.style.maxHeight = 0;
        }
        else {
            section.classList.add('-active');
            sectionBody.style.maxHeight = sectionBody.scrollHeight + 'px';
        }
    }

    render() {
        const item = this.props.item;
        const itemCartConfig = this.props.itemCartConfig;

        let intensityBox = [];
        for (let i = 0; i < 13; i++) {
            let active = i < item.intensity ? '-active' : '';
            intensityBox.push(<span key={i} className={active}/>);
        }

        let cupSizes = [];
        item.cupsSizes.toString().split(' ').map(size => {
            switch(size) {
                case 'xs':
                    cupSizes.push({
                        img: require('../../assets/images/icons/cup-25.svg'),
                        label: 25,
                    });
                    return;
                case 's':
                    cupSizes.push({
                        img: require('../../assets/images/icons/cup-40.svg'),
                        label: 40,
                    });
                    return;
                case 'm':
                    cupSizes.push({
                        img: require('../../assets/images/icons/cup-110.svg'),
                        label: 110,
                    });
                    return;
            }
        })

        return (
            <Popup show={this.props.show} onClose={() => this.props.onClose()} showGetInTouch={false}>
                <div className="coffeePopup">
                    <div className="coffeePopup__col -center --toMdHide">
                        <div className="coffeePopup__thumb">
                            <img src={item.img} alt={item.title}/>
                        </div>
                        <div className="coffeeItem__intensity">
                            <div className="coffeeItem__intensityBox">
                                {intensityBox.map(item => item)}
                            </div>
                            <span className="coffeeItem__intensityText">{t('coffee.intensity')} {item.intensity}</span>
                        </div>
                        <h5 className="coffeePopup__cupSizesTitle">{t('coffee.cup_size')}:</h5>
                        <div className="coffeePopup__cupSizes">
                            {cupSizes.map((size, key) => (
                                <div key={key} className="coffeePopup__cupSize">
                                    <img src={size.img} alt={size.label}/>
                                    <span>{size.label} ml</span>
                                </div>
                            ))}
                        </div>
                        {this.comparableItems ? (
                            <>
                                <h5 className="coffeePopup__similarItemsTitle">{t('coffee.comparable')}:</h5>
                                <div className="coffeePopup__similarItems">
                                    {this.comparableItems.map(item => (
                                        <div key={item.id} className="coffeePopup__similarItem">
                                            <img src={item.img} alt={item.title} />
                                            <h6 className="coffeePopup__similarItemTitle">{item.title}</h6>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : <></>}
                    </div>
                    <div className="coffeePopup__col">
                        <div className="coffeePopup__thumb --fromMdHide">
                            <img src={item.img} alt={item.title}/>
                        </div>
                        <h2 className="coffeePopup__title">{item.title}</h2>
                        <h3 className="coffeePopup__subtitle">{item.subtitle}</h3>
                        <div className="coffeePopup__price"><span>{this.props.currency.prefix}{item.price}{this.props.currency.postfix}</span></div>
                        <div className="coffeePopup__button">
                            {this.state.inCartValue >= itemCartConfig.min ? (
                                <PlusMinusButtons id={item.id} />
                            ) : (
                                <Button class="-primary -sm" disabled={this.props.disableAdd} label={t('buy_now')} onClick={() => this.props.onChange(item.step)}/>
                            )}
                        </div>
                        <div className="coffeeItem__intensity --fromMdHide">
                            <div className="coffeeItem__intensityBox">
                                {intensityBox.map(item => item)}
                            </div>
                            <span className="coffeeItem__intensityText">{t('coffee.intensity')} {item.intensity}</span>
                        </div>
                        <div className="coffeePopup__lead">
                            <div className="coffeePopup__par1" dangerouslySetInnerHTML={{__html: item.text}}/>
                        </div>

                        <div className="--fromMdHide">
                            <h5 className="coffeePopup__cupSizesTitle">{t('coffee.cup_size')}:</h5>
                            <div className="coffeePopup__cupSizes">
                                {cupSizes.map((size, key) => (
                                    <div key={key} className="coffeePopup__cupSize">
                                        <img src={size.img} alt={size.label}/>
                                        <span>{size.label} ml</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="coffeePopup__sections">
                            {item.sections.map((section, key) => (
                                <div key={key} className="coffeePopup__section">
                                    <button className="coffeePopup__sectionHeader" onClick={o => this.toggleSection(o)}>
                                        <span>{t('coffee.section.title.' + section.title)}</span>
                                        <img src={require('../../assets/images/icons/arrow-down.svg')} alt={t('expand')} className="coffeePopup__sectionIcon"/>
                                    </button>
                                    <div className="coffeePopup__sectionBody">
                                        <div className="coffeePopup__par1" dangerouslySetInnerHTML={{__html: section.text}}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/*<GetInTouch onClick={() => null}/>*/}
                    </div>
                </div>
            </Popup>
        );
    }
}

export default connect(mapStateToProps)(CoffeePopup);
