import React, {Component} from 'react';
import './Popups.css';
import {Button, GetInTouch, Popup} from "../index";

class OrderSuccessPopup extends Component {

    render() {
        return (
            <Popup show={this.props.show}
                   onClose={() => this.props.onClose()}>
                <div className="popup__header">
                    <h3 className="popup__title">Thank you for placing your order</h3>
                </div>
                <div className="popup__body">
                    <div className="orderSuccessPopup">
                        <p className="orderSuccessPopup__text">We deliver you order to your chosen address as soon as
                            possible.</p>
                        <p className="orderSuccessPopup__text">Do not forget to activate your Nespresso account via the
                            link in your mail. With this you receive the same nice extras from the <br/>
                            <a href="">Nespresso&More program</a></p>
                        <p className="orderSuccessPopup__text">Download the <b>Nespresso</b> app here, order your
                            favorite coffees the next time in just few clicks.</p>
                        <div className="orderSuccessPopup__grid">
                            <a href="#" className="orderSuccessPopup__link">
                                <img src={require('../../assets/images/icons/apple-store.svg')} alt=""/>
                            </a>
                            <a href="#" className="orderSuccessPopup__link">
                                <img src={require('../../assets/images/icons/google-play.png')} alt=""/>
                            </a>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default OrderSuccessPopup;
