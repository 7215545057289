import React, {Component} from 'react';
import { browserHistory } from 'react-router';
import {
    Step,
    MachineRegistration,
    SelectOffer,
    CustomerRegistration,
    GetInTouch,
    GetInTouchPopup, OrderSummary
} from '../../components/index';
import './Funnel.css';

import {setStep, setCurrency, showPopup, translate as t} from "../../actions/store";
import {connect} from "react-redux";
import * as actions from "../../actions/actions";

const mapDispatchToProps = (dispatch) => ({
    showPopup: (popup, show) => dispatch(showPopup(popup, show)),
    setStep: (step) => dispatch(setStep(step)),
    setCurrency: (currency) => dispatch(setCurrency(currency)),
})

const mapStateToProps = (state) => ({
    step: state.step,
    promotion: state.promotion,
    lang: state.lang,
})

class Funnel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            step: props.step?props.step:1,
            showGetInTouchPopup: false,
        };

        if(!props.promotion) {
            document.location='/';
        }
    }

    componentDidMount() {
        this.manageSteps();

        actions.getCurrency(this.props.lang).then((r) => {
            if(r.status == 'success') {
                this.props.setCurrency(r.data);
            }
        })

        this.backListener = browserHistory.listen(location => {
            if (location.action === "POP") {
                // console.log('hek!', location)
                let stepNo = 1;
                const stepName = location.pathname.toString().split('/')[2];
                // console.log('stepname: ', stepName)
                switch (stepName) {
                    case 'machine-registration': stepNo = 1; break;
                    case 'choose-your-coffee': stepNo = 2; break;
                    case 'customer-registration': stepNo = 3; break;
                    case 'order-summary': stepNo = 4; break;
                }

                this.nextStep(stepNo);
            }
        });
    }

    componentWillUnmount() {
        this.backListener();
    }

    nextStep(step = null) {
        this.setState({
            step: step ? step: this.state.step+1
        }, () => {
            this.props.setStep(this.state.step)
            this.manageSteps()
            let stepName = '';
            switch (this.state.step) {
                case 1: stepName = 'machine-registration'; break;
                case 2: stepName = 'choose-your-coffee'; break;
                case 3: stepName = 'customer-registration'; break;
                case 4: stepName = 'order-summary'; break;
            }
            window.history.pushState(null, null, '/shop/' + stepName)
        })
    }

    manageSteps() {
        document.querySelectorAll('.step').forEach(step => {
            if(step.getAttribute('data-step-no') == this.state.step) {
                step.classList.add('-active');
                let stepBody = step.querySelector('.step__body');
                let bodyHeight = stepBody.scrollHeight;
                stepBody.style.maxHeight = bodyHeight + 'px';
            }
            else {
                step.classList.remove('-opened');
                step.classList.remove('-active');
                step.querySelector('.step__body').style.maxHeight = null;
            }
        })


        let step = document.querySelector('.step.-active');
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        setTimeout(() => {
            step.classList.add('-opened');
        }, 200);
    }

    render() {
        return (
            <main className="funnel main">
                <div className="wrapper-bg">
                    <div className="formContainer">
                        <div className="wrapper">


                            <div className="steps">
                                {this.state.step>=1
                                    ?<MachineRegistration currentStep={this.state.step} stepNo={1} nextStep={step => this.nextStep(step)}/>
                                    :<Step label={t('s_machine._title')}  stepNo={1} currentStep={this.state.step} nextStep={() => {}}></Step>}
                                {this.state.step>=2
                                    ?<SelectOffer currentStep={this.state.step} stepNo={2} nextStep={step => this.nextStep(step)}/>
                                    :<Step label={t('s_offer._title')}  stepNo={2} currentStep={this.state.step} nextStep={() => {}}></Step>}
                                {this.state.step>=3
                                    ?<CustomerRegistration currentStep={this.state.step} stepNo={3} nextStep={step => this.nextStep(step)}/>
                                    :<Step label={t('s_customer._title')}  stepNo={3} currentStep={this.state.step} nextStep={() => {}}></Step>}
                                {this.state.step>=4
                                    ?<OrderSummary {...this.props} currentStep={this.state.step} stepNo={4} nextStep={step => this.nextStep(step)}/>
                                    :<Step label={t('s_summary._title')}  stepNo={4} currentStep={this.state.step} nextStep={() => {}}></Step>}
                            </div>

                            {this.state.step < 4 ? (
                                <div className="funnel__getInTouch">
                                    <GetInTouch onClick={() => this.props.showPopup('getInTouchPopup', true)} />
                                </div>
                            ) : <></>}


                        </div>
                    </div>
                </div>

            </main>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Funnel);
