import React, {Component} from 'react';
import './Popups.css';
import {Button, GetInTouch, Popup} from "../index";
import {translate as t} from "../../actions/store";
import {removeNbsp} from "../../actions/utils";

class ProofOfPurchasePopup extends Component {

    render() {
        return (
            <Popup show={this.props.show} onClose={() => this.props.onClose()}>
                <div className="popup__header">
                    <h3 className="popup__title">{t('p_proof.title')}</h3>
                    {!!removeNbsp(t('p_proof.list')) && (
                        <div className="popup__list">
                            <ul dangerouslySetInnerHTML={{__html: t('p_proof.list')}}/>
                        </div>
                    )}
                </div>

                <div className="popup__body">
                    <div className="proofOfPurchasePopup">
                        <div className="proofOfPurchasePopup__grid">
                            <div className="proofOfPurchasePopup__thumb">
                                <img src={require('../../assets/images/ticket.png')} alt={t('p_proof.cash_receipt.title')}/>
                            </div>
                            <div className="proofOfPurchasePopup__text">
                                {!!removeNbsp(t('p_proof.cash_receipt.label')) && (
                                    <h4 className="proofOfPurchasePopup__title"
                                        dangerouslySetInnerHTML={{__html: t('p_proof.cash_receipt.label')}}/>
                                )}
                                <p className="par-2" dangerouslySetInnerHTML={{__html: t('p_proof.cash_receipt.text')}}/>
                            </div>
                        </div>
                        <div className="proofOfPurchasePopup__grid">
                            <div className="proofOfPurchasePopup__thumb">
                                <img src={require('../../assets/images/receipt.png')} alt={t('p_proof.paper_receipt.title')}/>
                            </div>
                            <div className="proofOfPurchasePopup__text">
                                <p className="par-2" dangerouslySetInnerHTML={{__html: t('p_proof.paper_receipt.text')}}/>
                            </div>
                        </div>
                        <div className="proofOfPurchasePopup__grid">
                            <div className="proofOfPurchasePopup__thumb">
                                <img src={require('../../assets/images/email.png')} alt={t('p_proof.email_order.title')}/>
                            </div>
                            <div className="proofOfPurchasePopup__text">
                                <p className="par-2" dangerouslySetInnerHTML={{__html: t('p_proof.email_order.text')}}/>
                            </div>
                        </div>
                        <div className="proofOfPurchasePopup__grid">
                            <div className="proofOfPurchasePopup__thumb">
                                <img src={require('../../assets/images/document.png')} alt={t('p_proof.any_document.title')}/>
                            </div>
                            <div className="proofOfPurchasePopup__text">
                                <p className="par-2" dangerouslySetInnerHTML={{__html: t('p_proof.any_document.text')}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default ProofOfPurchasePopup;
