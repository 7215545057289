import React, {Component} from 'react';
import './Utils.css';
import Edit from "./Edit";
import {ButtonClose} from "../index";
import {translate as t} from "../../actions/store";
import Dropzone from "react-dropzone";

class UploadBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file: props.file,
            isHovered: false,
        }

        this.fileInput = React.createRef();


        this.acceptFileTypes = `image/png,image/jpg,image/jpeg,image/bmp${props.withPDF ? ',application/pdf' : ''}`;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.file !== this.state.file) {
            this.setState({
                file: this.props.file
            })
        }
    }

    onDrop = (files) => {
        const file = files[0];
        if(file?.type && this.acceptFileTypes.includes(file.type)) {
            this.props.onFileUpload(files)
        }
    }

    render() {
        return (
            <div className="uploadBox">
                {this.state.file != null ? (
                    <div className="uploadBox__close">
                        <ButtonClose positionClass="-topRight" onClick={() => this.props.onClose()}/>
                    </div>
                ) : <></>}
                <div className="uploadBox__area">
                    {this.state.file != null ? (
                        <div>
                            <img src={this.state.file} alt={t('upload_box.file')} className="uploadBox__uploadedImg"/>
                        </div>
                    ) : (
                        <Dropzone onDrop={this.onDrop}
                                  onDragEnter={() => this.setState({isHovered: true})}
                                  onDragLeave={() => this.setState({isHovered: false})}
                                  onDropAccepted={() => this.setState({isHovered: false})}
                                  onDropRejected={() => this.setState({isHovered: false})}
                        >
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps({
                                    className: 'uploadBox__content ' + (this.state.isHovered ? '-hover' : '')
                                })}>
                                    <input
                                        {...getInputProps()}
                                        accept={this.acceptFileTypes}
                                        multiple={false}
                                    />
                                    <div className="uploadBox__areaImg">
                                        {this.props.loading ? (
                                            <img src={require('../../assets/images/icons/preloader2.gif')} alt="..."/>
                                        ) : (
                                            <img src={require('../../assets/images/icons/camera.svg')} alt="Upload"/>
                                        )}
                                    </div>
                                    <span className="uploadBox__areaText" dangerouslySetInnerHTML={{__html: t('upload_box.text')}}/>
                                </div>
                            )}
                        </Dropzone>
                    )}
                    {this.props.progress ? (
                        <div className="uploadBox__progress" style={{
                            width: this.props.progress + '%',
                            opacity: this.props.progress === 100 ? 0 : 1,
                        }} />
                    ) : null}
                </div>
                {this.state.file == null ? (
                    <div className="uploadBox__caption">
                        <div>{t('upload_box.compatible_types')}: JPG, JPEG, PNG, PDF</div>
                        <div>{t('upload_box.max_file_size')}: 8MB</div>
                    </div>
                ) : null}

                {/*<input type="file" ref={this.fileInput} onChange={e => this.props.onFileUpload(e)} hidden/>*/}
            </div>
            // <button className="edit" onClick={() => this.props.onClick()}>Edit <img src={require('../../assets/images/icons/edit.svg')} alt=""/></button>
        );
    }
}


UploadBox.defaultProps = {
    withPDF: true,
}

export default UploadBox;
