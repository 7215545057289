import React, {Component} from 'react';
import './Popups.css';
import {Button, GetInTouch, PlusMinusButtons, Popup} from "../index";
import {connect} from 'react-redux';
import {translate as t} from "../../actions/store";

const mapStateToProps = (state) => ({
    // allProducts: state.allProducts,
    currency: state.currency,
    lang: state.lang,
})

class GearPopup extends Component {
    constructor(props) {
        super(props);

        // this.state = {
        //     inCartValue: props.inCartValue,
        // }
        //
        // this.comparableItems = [];
        //
        // for(let i in props.item.comparableItems) {
        //     let id = parseInt(props.item.comparableItems[i]);
        //     if("undefined" !== typeof props.allProducts[id]) {
        //         this.comparableItems.push(props.allProducts[id]);
        //     }
        // }

    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if(this.props.itemCartConfig.value !== this.state.inCartValue)
    //         this.setState({inCartValue: this.props.itemCartConfig.value})
    // }
    //
    // toggleSection(element) {
    //     const section = element.target.closest('.coffeePopup__section')
    //     const sectionBody = section.querySelector('.coffeePopup__sectionBody')
    //     if(section.classList.contains('-active')) {
    //         section.classList.remove('-active');
    //         sectionBody.style.maxHeight = 0;
    //     }
    //     else {
    //         section.classList.add('-active');
    //         sectionBody.style.maxHeight = sectionBody.scrollHeight + 'px';
    //     }
    // }

    getText(str) {
        try {
            const obj = JSON.parse(str);
            const key = Object.keys(obj).find(key => key.startsWith(this.props.lang))

            let text = '';
            if(key) {
                text = obj[key];
            }
            return text;
        }
        catch (Exception) {
            return str;
        }
    }

    render() {
        const item = this.props.item;

        item.text = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam commodi consectetur consequatur debitis distinctio dolorum eum ipsa, libero, nulla officiis quas quod reiciendis sapiente sequi tempore ullam voluptatum. Aperiam, ullam.'

        return (
            <Popup show={this.props.show} onClose={() => this.props.onClose()} showGetInTouch={false}>
                <div className="coffeePopup gearPopup">
                    <div className="coffeePopup__col -center --toMdHide">
                        <div className="coffeePopup__thumb">
                            {!!this.props.gear && <img src={this.props.gear.filename} alt={this.props.gear.name}/>}
                        </div>
                        {/*<div className="coffeeItem__intensity">*/}
                        {/*    <div className="coffeeItem__intensityBox">*/}
                        {/*        {intensityBox.map(item => item)}*/}
                        {/*    </div>*/}
                        {/*    <span className="coffeeItem__intensityText">{t('coffee.intensity')} {item.intensity}</span>*/}
                        {/*</div>*/}
                        {/*<h5 className="coffeePopup__cupSizesTitle">{t('coffee.cup_size')}:</h5>*/}
                        {/*<div className="coffeePopup__cupSizes">*/}
                        {/*    {cupSizes.map((size, key) => (*/}
                        {/*        <div key={key} className="coffeePopup__cupSize">*/}
                        {/*            <img src={size.img} alt={size.label}/>*/}
                        {/*            <span>{size.label} ml</span>*/}
                        {/*        </div>*/}
                        {/*    ))}*/}
                        {/*</div>*/}
                        {/*{this.comparableItems ? (*/}
                        {/*    <>*/}
                        {/*        <h5 className="coffeePopup__similarItemsTitle">{t('coffee.comparable')}:</h5>*/}
                        {/*        <div className="coffeePopup__similarItems">*/}
                        {/*            {this.comparableItems.map(item => (*/}
                        {/*                <div key={item.id} className="coffeePopup__similarItem">*/}
                        {/*                    <img src={item.img} alt={item.title} />*/}
                        {/*                    <h6 className="coffeePopup__similarItemTitle">{item.title}</h6>*/}
                        {/*                </div>*/}
                        {/*            ))}*/}
                        {/*        </div>*/}
                        {/*    </>*/}
                        {/*) : <></>}*/}
                    </div>
                    <div className="coffeePopup__col">
                        <div className="coffeePopup__thumb --fromMdHide">
                            {!!this.props.gear && <img src={this.props.gear.filename} alt={this.props.gear.name}/>}
                        </div>
                        <h2 className="coffeePopup__title --mb3">{item.corporate_subscription_plan_name}</h2>
                        {/*{!!this.props.gear ? (*/}
                        {/*    <h3 className="coffeePopup__subtitle">{this.props.gear.name}</h3>*/}
                        {/*) : (*/}
                        {/*    <h3 className="coffeePopup__subtitle">{this.getText(item.corporate_subscription_plan)}</h3>*/}
                        {/*)}*/}
                        {/*<div className="coffeePopup__price">*/}
                        {/*    <span>{this.props.currency.prefix}{item.price}{this.props.currency.postfix}</span></div>*/}
                        {/*<div className="coffeePopup__button">*/}
                        {/*    {this.state.inCartValue >= itemCartConfig.min ? (*/}
                        {/*        <PlusMinusButtons id={item.id} />*/}
                        {/*    ) : (*/}
                        {/*        <Button class="-primary -sm" disabled={this.props.disableAdd} label={t('buy_now')} onClick={() => this.props.onChange(item.step)}/>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                        {/*<div className="coffeeItem__intensity --fromMdHide">*/}
                        {/*    <div className="coffeeItem__intensityBox">*/}
                        {/*        {intensityBox.map(item => item)}*/}
                        {/*    </div>*/}
                        {/*    <span className="coffeeItem__intensityText">{t('coffee.intensity')} {item.intensity}</span>*/}
                        {/*</div>*/}
                        <div className="coffeePopup__lead">
                            <div className="coffeePopup__par1"
                                 dangerouslySetInnerHTML={{__html: this.getText(item.corporate_subscription_plan_description)}}
                            />
                            <div className="coffeePopup__par1" dangerouslySetInnerHTML={{__html: this.getText(item.corporate_subscription_plan_bullets2) ?? ''}}/>
                        </div>

                        {/*<div className="--fromMdHide">*/}
                        {/*    <h5 className="coffeePopup__cupSizesTitle">{t('coffee.cup_size')}:</h5>*/}
                        {/*    <div className="coffeePopup__cupSizes">*/}
                        {/*        {cupSizes.map((size, key) => (*/}
                        {/*            <div key={key} className="coffeePopup__cupSize">*/}
                        {/*                <img src={size.img} alt={size.label}/>*/}
                        {/*                <span>{size.label} ml</span>*/}
                        {/*            </div>*/}
                        {/*        ))}*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="coffeePopup__sections">*/}
                        {/*    <div className="coffeePopup__section">*/}
                        {/*        <div className="coffeePopup__sectionHeader">*/}
                        {/*            <span>{t('gear.specification')}</span>*/}
                        {/*        </div>*/}
                        {/*        <div className="coffeePopup__sectionBody">*/}
                        {/*            <div className="coffeePopup__par1" dangerouslySetInnerHTML={{__html: item.corporate_subscription_plan_description}}/>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </Popup>
        );
    }
}

export default connect(mapStateToProps)(GearPopup);
