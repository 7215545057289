import React, {Component} from "react";
import {connect} from 'react-redux';
import './OrderFinalized.css';
import {Button, GetInTouch} from "../index";
import * as actions from '../../actions/actions';
import {clearStore, endPromo, setPromo, translate as t} from "../../actions/store";

const mapStateToProps = (state) => ({
    lastOrderTotal: state.lastOrderTotal,
    currency: state.currency,
    lang: state.lang,
})

const mapDispatchToProps = (dispatch) => ({
    clearPromo: () => dispatch(endPromo()),
    clearStore: (lang) => dispatch(clearStore(lang)),
})

class OrderFinalized extends Component {
    constructor(props) {
        super(props);

        this.state = {
            texts: null,
        }
    }

    componentDidMount() {
        this.props.clearPromo();
        this.props.clearStore();

        const data = {
            lang: this.props.lang,
            name: 'order_thankyou_page_wfh',
        }

        this.getTexts(data);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(this.props.lang !== prevProps.lang) {
            const data = {
                lang: this.props.lang,
                name: 'order_thankyou_page_wfh',
            }

            this.getTexts(data);
        }
    }

    getTexts(data) {
        actions.getShopText(data)
            .then(res => {
                this.setState({
                    texts: res.data.data
                })
            })
    }


    render() {
        return (
            <main className="funnel main">
                <div className="wrapper-bg">
                    <div className="orderFinalized">

                        <link rel="stylesheet" href={require('../../assets/css/vendors/skeleton.css')}/>



                        <div className="wrapper">
                            {this.state.texts?.map(({text}) => (
                                <div dangerouslySetInnerHTML={{__html: text}}/>
                            ))}

                            {/*<div className="orderFinalized__top">*/}
                            {/*    <div className="orderFinalized__thumb">*/}
                            {/*        <img src={require('../../assets/images/order-finalize.jpg')} alt=""/>*/}
                            {/*    </div>*/}
                            {/*    <div className="orderFinalized__content">*/}
                            {/*        <div className="orderFinalized__texts">*/}
                            {/*            {this.state.texts?.map(({text}) => (*/}
                            {/*                <div dangerouslySetInnerHTML={{__html: text}}/>*/}
                            {/*            ))}*/}
                            {/*        </div>*/}
                            {/*        <GetInTouch onClick={() => this.props.showPopup('getInTouchPopup', true)} />*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="orderFinalized__bottom">*/}
                            {/*    <div>*/}
                            {/*        <Button class="-primary -outline -sm" label={t('back')}*/}
                            {/*                onClick={() => this.props.history.push('/')}/>*/}
                            {/*    </div>*/}
                            {/*    <div className="orderFinalized__summary">*/}
                            {/*        <h3 className="orderFinalized__name">{t('total')}</h3>*/}
                            {/*        <h4 className="orderFinalized__value">{this.props.currency.prefix}{this.props.lastOrderTotal.toFixed(2)}{this.props.currency.postfix}</h4>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                        </div>


                        {/*<div className="wrapper">*/}
                        {/*    <div className="orderFinalized__top">*/}
                        {/*        <div className="orderFinalized__thumb">*/}
                        {/*            <img src={require('../../assets/images/order-finalize.jpg')} alt=""/>*/}
                        {/*        </div>*/}
                        {/*        <div className="orderFinalized__content">*/}
                        {/*            <h1 className="orderFinalized__title">{t('order_finalized.title')}</h1>*/}
                        {/*            <p className="orderFinalized__text">{t('order_finalized.text1')}</p>*/}
                        {/*            <p className="orderFinalized__text">{t('order_finalized.text2')} <br/>*/}
                        {/*                <a href="">{t('order_finalized.link_text')}</a></p>*/}
                        {/*            <p className="orderFinalized__text" dangerouslySetInnerHTML={{__html: t('order_finalized.text3')}}/>*/}
                        {/*            <div className="orderFinalized__links">*/}
                        {/*                <a href="#" className="orderFinalized__link">*/}
                        {/*                    <img src={require('../../assets/images/icons/apple-store.svg')} alt=""/>*/}
                        {/*                </a>*/}
                        {/*                <a href="#" className="orderFinalized__link">*/}
                        {/*                    <img src={require('../../assets/images/icons/google-play.png')} alt=""/>*/}
                        {/*                </a>*/}
                        {/*            </div>*/}
                        {/*            <GetInTouch onClick={() => this.props.showPopup('getInTouchPopup', true)} />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*    /!*<div className="orderFinalized__bottom">*!/*/}
                        {/*    /!*    <div>*!/*/}
                        {/*    /!*        <Button class="-primary -outline -sm" label={t('back')}*!/*/}
                        {/*    /!*                onClick={() => this.props.history.push('/')}/>*!/*/}
                        {/*    /!*    </div>*!/*/}
                        {/*    /!*    <div className="orderFinalized__summary">*!/*/}
                        {/*    /!*        <h3 className="orderFinalized__name">{t('total')}</h3>*!/*/}
                        {/*    /!*        <h4 className="orderFinalized__value">{this.props.currency.prefix}{this.props.lastOrderTotal.toFixed(2)}{this.props.currency.postfix}</h4>*!/*/}
                        {/*    /!*    </div>*!/*/}
                        {/*    /!*</div>*!/*/}

                        {/*</div>*/}
                    </div>
                </div>
            </main>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderFinalized);
