import React, {Component} from 'react';
import './payment.css'


class Payment extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const id = this.props.match.params.id;

        const apiUrl = process.env.REACT_APP_API_URL.toString().replace(/api\//, '');

        return (
            <main className="funnel main">
                <div className="wrapper-bg">
                    <div className="payment">

                        <div className="wrapper">
                            <iframe className="payment__iframe"
                                    width="100%"
                                    height="100%"
                                    src={`${apiUrl}payment/${id}`}/>
                        </div>

                    </div>
                </div>
            </main>
        );
    }
}

export default Payment;
