import React, {Component} from "react";
import './alert.css';

class Alert extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let className = 'alert ' + this.props.className ?? '';
        return (
            <div className={className}>
                {this.props.children}
            </div>
        );
    }
}

export default Alert;
