import React, {Component} from 'react';
import './Forms.css';

class FormInput extends Component {
    constructor(props) {
        super(props);

        let valid = true;

        if(props.isValid === false)
            valid = false

        let disabled = false;
        if(props.disabled === true)
            disabled = true;

        let invalidFeedback = props.invalidFeedback;
        if(!invalidFeedback)
            invalidFeedback = props.label + ' is required';

        this.state = {
            class: 'formGroup' + (this.props.disabled ? ' -disabled' : ''),
            value: props.value,
            invalidFeedback,
            isValid: valid,
            disabled,
            touched: props.value ? true : false,
            loading: props.loading ?? false,
            required: props.required ?? true,
        };

        this.input = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.invalidFeedback != this.state.invalidFeedback) {
            if(this.props.invalidFeedback) {
                this.setState({
                    invalidFeedback: this.props.invalidFeedback
                })
            }

        }

        if(this.props.isValid != this.state.isValid) {
            this.setState({
                isValid: this.props.isValid
            })
        }

        if(this.props.disabled != this.state.disabled) {
            this.setState({
                disabled: this.props.disabled
            })
        }

        if(this.props.value != this.state.value) {
            this.setState({
                value: this.props.value,
                touched: true,
            })
        }

        if(this.props.touched != this.state.touched) {
            if(this.props.touched) {
                this.setState({
                    touched: this.props.touched,
                })
            }

        }

        if(this.props.loading != this.state.loading) {
            this.setState({
                loading: this.props.loading
            });
        }

    }

    onFocusInput(addClass) {
        let classes = 'formGroup';
        if(this.state.disabled)
            classes += ' -disabled';

        this.setState({ class: addClass ? this.state.class + ' -focused' : classes})

        if(this.props.onFocus)
            this.props.onFocus();
    }

    onBlurInput() {
        this.onFocusInput(false);
        if(this.props.onBlur)
            this.props.onBlur();
        this.setState({touched: true});
    }

    onChangeInput(value) {
        if(typeof this.props.onChange === 'function') {
            this.setState({value: value});
            this.props.onChange(value);
        }
    }

    onClickGroup() {
        this.input.current.focus();
    }

    render() {

        let formGroupClasses = this.state.class;
        if(this.state.disabled)
            formGroupClasses += ' -disabled';

        if(!this.state.isValid && this.state.touched)
            formGroupClasses += ' -invalid';

        if(this.state.isValid && this.state.touched && this.input.current?.validity.valid && this.state.required)
            formGroupClasses += ' -valid';

        if(this.state.value?.length > 0 || this.state.touched)
            formGroupClasses += ' -active';

        return (
            <div className={formGroupClasses}>
                <div className="formGroup__field" onClick={() => this.onClickGroup()}>
                    <label htmlFor={this.props.name}>{this.props.label}</label>
                    <input id={this.props.name} name={this.props.name} type={this.props.type}
                           value={this.props.value}
                           ref={this.input}
                           disabled={this.props.disabled}
                           onChange={e => this.onChangeInput(e.target.value)}
                           onBlur={() => this.onBlurInput()}
                           onFocus={() => this.onFocusInput(true)}
                           min={this.props.min ?? null}
                           max={this.props.max ?? null}
                           step={this.props.step ?? null}
                    />

                    {this.state.loading ? (
                        <div className="formGroup__loading">
                            <img src={require('../../assets/images/icons/preloader2.gif')} alt="..."/>
                        </div>
                    ) : null}
                </div>

                {!this.state.isValid?<span className="formGroup__invalidFeedback" dangerouslySetInnerHTML={{__html: this.state.invalidFeedback}}/>:<></>}


            </div>
        );
    }
}

export default FormInput;
