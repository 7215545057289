import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './CustomerRegistration.css';
import {StepCustom, Button, FormCheckbox, FormInput, FormSelect, Step} from "../index";
import { connect } from 'react-redux';
import {updateCustomer, translate as t, setLastValidStep} from "../../actions/store";
import {createMask, createRegex} from "../../actions/utils";
import IMask from "imask";
import * as actions from '../../actions/actions';

const mapDispatchToProps = (dispatch) => ({
    updateCustomer: (customer) => dispatch(updateCustomer(customer)),
    setLastValidStep: step => dispatch(setLastValidStep(step)),
})

const mapStateToProps = (state) => ({
    customer: state.customer,
    lang: state.lang,
    config: state.config,
})

class CustomerRegistration extends Component {
    constructor(props) {
        super(props);

        this.zipCode = {
            pattern: createRegex(props.config.custom_postcode_format.options.format),
            mask: createMask(props.config.custom_postcode_format.options.format),
            length: props.config.custom_postcode_format.options.format.length,
        };
        this.phone = {
            pattern: createRegex(props.config.custom_phone_number_format.value),
            mask: createMask(props.config.custom_phone_number_format.value),
            length: props.config.custom_phone_number_format.value.length,
        };

        this.flatNumber = {
            pattern: createRegex(props.config.custom_flat_number.options.format),
            mask: createMask(props.config.custom_flat_number.options.format),
            length: props.config.custom_flat_number.options.format.length,
        }
        this.floor = {
            pattern: createRegex(props.config.custom_floor_number.options.format),
            mask: createMask(props.config.custom_floor_number.options.format),
            length: props.config.custom_floor_number.options.format.length,
        }

        // console.log(this.zipCode);


        this.flatNumberRef = new React.createRef();
        this.flatNumberMask = null;
        this.floorRef = new React.createRef();
        this.floorMask = null;
        this.phoneRef = new React.createRef();
        this.phoneMask = null;
        this.zipCodeRef = new React.createRef();
        this.zipCodeMask = null;

        this.deliveryZipCodeRef = new React.createRef();
        this.deliveryZipCodeMask = null;
        this.deliveryFlatNumberRef = new React.createRef();
        this.deliveryFlatNumberMask = null;
        this.deliveryFloorRef = new React.createRef();
        this.deliveryFloorMask = null;
        this.deliveryPhoneRef = new React.createRef();
        this.deliveryPhoneMask = null;


        this.state = props.customer?props.customer:{
            companyName: '',
            taxId: '',
            title: null,
            name: '',
            surname: '',
            zipCode: '',
            city: '',
            street: '',
            flatNumber: '',
            floor: '',
            country: null,
            phone: '',
            email: '',
            reEmail: '',

            delivery: {
                companyAddress: false,
                companyName: '',
                title: null,
                name: '',
                surname: '',
                zipCode: '',
                city: '',
                street: '',
                flatNumber: '',
                floor: '',
                phone: '',
            },

            zipCodeInvalidFeedback: t('s_customer.zip_code') + t('is_required'),

            companyAddress: false,
            customDeliverAddress: false,

            receiveBenefitsEmail: false,
            receiveBenefitsPhone: false,
            termsAndConditions: false,
            privacyPolicy: false,

            receivePost: true,

            forceTouched: false,
        }

        this.titleOptions = [
            {
                label: t('male_title'),
                value: 'M',
            },
            {
                label: t('female_title'),
                value: 'F',
            },
        ]

        this.countries = [
            // {
            //     label: 'Poland',
            //     value: 'pl',
            // },
            // {
            //     label: 'Germany',
            //     value: 'de',
            // },
            {
                label: t('country.netherlands'),
                value: 'nl',
            },
        ]
    }

    componentDidMount() {
        // if(this.flatNumberRef) {
        //     const maskOptions = {
        //         mask: this.flatNumber.mask,
        //         lazy: false,
        //         overwrite: true,
        //         autofix: true,
        //     };
        //     this.flatNumberMask = IMask(this.flatNumberRef.current.input.current, maskOptions);
        //     this.flatNumberMask.on('accept', () => {
        //         this.setState({flatNumber: this.flatNumberMask.value})
        //     })
        // }
        // if(this.floorRef) {
        //     const maskOptions = {
        //         mask: this.floor.mask,
        //         lazy: false,
        //         overwrite: true,
        //         autofix: true,
        //     };
        //     this.floorMask = IMask(this.floorRef.current.input.current, maskOptions);
        //     this.floorMask.on('accept', () => {
        //         this.setState({floor: this.floorMask.value})
        //     })
        // }
        if(this.phoneRef) {
            const maskOptions = {
                mask: this.phone.mask,
                lazy: false,
                overwrite: true,
                autofix: true,
            };
            this.phoneMask = IMask(this.phoneRef.current.input.current, maskOptions);
            this.phoneMask.on('accept', () => {
                this.setState({phone: this.phoneMask.value})
            })
        }
        if(this.zipCodeRef) {
            const maskOptions = {
                mask: this.zipCode.mask,
                lazy: false,
                overwrite: true,
                autofix: true,
            };
            this.zipCodeMask = IMask(this.zipCodeRef.current.input.current, maskOptions);
            this.zipCodeMask.on('accept', () => {
                this.setState({zipCode: this.zipCodeMask.value}, () => {
                    if(this.state.zipCode.match(this.zipCode.pattern)) {
                        try {
                            actions.getPostCodeDetails(this.state.zipCode)
                                .then(res => {
                                    if(res.status === 200) {
                                        this.setState({
                                            city: res.data.city.label,
                                            street: res.data.streets[0],
                                        })
                                        // console.log(res.data.stree)
                                    }
                                })
                                .catch(err => {
                                    // console.log(err.response.status)
                                    // throw new Error('test err')
                                })
                        }
                        catch (e) {
                            console.log('not found!', e)
                        }


                    }
                })
            })
        }

        // delivery
        // if(this.deliveryFlatNumberRef) {
        //     const maskOptions = {
        //         mask: this.flatNumber.mask,
        //         lazy: false,
        //         overwrite: true,
        //         autofix: true,
        //     };
        //     this.deliveryFlatNumberMask = IMask(this.deliveryFlatNumberRef.current.input.current, maskOptions);
        //     this.deliveryFlatNumberMask.on('accept', () => {
        //         this.setState({
        //             delivery: {
        //                 ...this.state.delivery,
        //                 flatNumber: this.deliveryFlatNumberMask.value
        //             }
        //         })
        //     })
        // }
        // if(this.deliveryFloorRef) {
        //     const maskOptions = {
        //         mask: this.floor.mask,
        //         lazy: false,
        //         overwrite: true,
        //         autofix: true,
        //     };
        //     this.deliveryFloorMask = IMask(this.deliveryFloorRef.current.input.current, maskOptions);
        //     this.deliveryFloorMask.on('accept', () => {
        //         this.setState({
        //             delivery: {
        //                 ...this.state.delivery,
        //                 floor: this.deliveryFloorMask.value
        //             }
        //         })
        //     })
        // }
        if(this.deliveryZipCodeRef) {
            const maskOptions = {
                mask: this.zipCode.mask,
                lazy: false,
                overwrite: true,
                autofix: true,
            };
            this.deliveryZipCodeMask = IMask(this.deliveryZipCodeRef.current.input.current, maskOptions);
            this.deliveryZipCodeMask.on('accept', () => {
                this.setState({
                    delivery: {
                        ...this.state.delivery,
                        zipCode: this.deliveryZipCodeMask.value
                    }
                }, () => {
                    if(this.state.delivery.zipCode.match(this.zipCode.pattern)) {
                        try {
                            actions.getPostCodeDetails(this.state.delivery.zipCode)
                                .then(res => {
                                    if(res.status === 200) {
                                        this.setState({
                                            delivery: {
                                                ...this.state.delivery,
                                                city: res.data.city.label,
                                                street: res.data.streets[0],
                                            }
                                        })
                                        // console.log(res.data.stree)
                                    }
                                })
                                .catch(err => {
                                    // console.log(err.response.status)
                                    // throw new Error('test err')
                                })
                        }
                        catch (e) {
                            console.log('not found!', e)
                        }


                    }
                })
                // this.setState({
                //     delivery: {
                //         ...this.state.delivery,
                //         zipCode: this.deliveryZipCodeMask.value
                //     }
                // })
            })
        }
        if(this.deliveryPhoneRef) {
            const maskOptions = {
                mask: this.phone.mask,
                lazy: false,
                overwrite: true,
                autofix: true,
            };
            this.deliveryPhoneMask = IMask(this.deliveryPhoneRef.current.input.current, maskOptions);
            this.deliveryPhoneMask.on('accept', () => {
                this.setState({
                    delivery: {
                        ...this.state.delivery,
                        phone: this.deliveryPhoneMask.value
                    }
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.updateCustomer(this.state);

        if(prevState !== this.state) {
            this.props.keepSession();
        }
    }


    nextStep() {
        if(this.validateStep()) {
            console.log('valid=next step')
            this.props.showStep(this.props.stepNo + 1)
        }
    }

    validateStep() {
        let valid = true;

        if(this.state.companyAddress) {
            if(!this.state.companyName) {
                valid = false;
                console.log('not valuid company name')

            }
            if(!this.state.taxId) {
                valid = false;
                console.log('not valuid taxid')

            }
        }
        else {
        }

        if(!this.state.title) {
            valid = false;
            console.log('not valuid title')
        }
        if(!this.state.name) {
            valid = false;
            console.log('not valuid name')

        }
        if(!this.state.surname ) {
            valid = false;
            console.log('not valuid surname')

        }

        if(!this.state.zipCode || !this.state.zipCode.match(this.zipCode.pattern)) {
            valid = false;
            console.log('invalid zipcode')
        }
        if(!this.state.city) {
            valid = false;
            console.log('not valuid city')

        }
        if(!this.state.street ) {
            valid = false;
            console.log('not valuid street')

        }
        if(this.props.config.custom_flat_number.options.obligatory == 'on' && !this.state.flatNumber) {
            valid = false;
            console.log('not valuid flatnumber')

        }
        if(this.props.config.custom_floor_number.options.obligatory == 'on' && !this.state.floor ) {
            valid = false;
            console.log('not valuid floor')

        }
        if(!this.state.country ) {
            valid = false;
            console.log('not valuid country')

        }
        if(!this.state.phone || !this.state.phone.match(this.phone.pattern)) {
            valid = false;
            console.log('not valuid phone')

        }
        if(!this.state.email) {
            valid = false;
            console.log('not valuid email')
        }
        if(this.props.config.additional_email.value == 'on' && this.state.reEmail !== this.state.email) {
            valid = false;
            console.log('not valuid email')

        }
        if(!this.state.termsAndConditions) {
            valid = false;
        }
        if(!this.state.privacyPolicy) {
            valid = false;
        }


        // delivery
        if(this.state.customDeliverAddress) {
            // console.log('not valuid customdevl')

            if(this.state.delivery.companyAddress) {
                if(!this.state.delivery.companyName) {
                    valid = false;
                }
            }
            else {
                if(!this.state.delivery.title) {
                    valid = false;
                }
            }


            if(!this.state.delivery.name) {
                valid = false;
            }
            if(!this.state.delivery.surname) {
                valid = false;
            }
            if(!this.state.delivery.zipCode || !this.state.delivery.zipCode.match(this.zipCode.pattern)) {
                valid = false;
            }
            if(!this.state.delivery.city) {
                valid = false;
            }
            if(!this.state.delivery.street ) {
                valid = false;
            }
            if(this.props.config.custom_floor_number.options.obligatory == 'on' && !this.state.delivery.floor) {
                valid = false;
            }
            if(this.props.config.custom_flat_number.options.obligatory == 'on' && !this.state.delivery.flatNumber) {
                valid = false;
            }
            if(!this.state.delivery.phone || !this.state.delivery.phone.match(this.phone.pattern)) {
                valid = false;
            }
        }

        if(valid) {
            this.props.setLastValidStep(this.props.stepNo);
        }

        // this.setState({forceTouched: true})


        return valid;
    }

    isFlatNumberValid() {
        if(this.state.flatNumber.replaceAll('_', '').replaceAll('-', '').length > 0) {
            return this.state.flatNumber.match(this.flatNumber.pattern);
        }
        else {
            return !(this.props.config.custom_flat_number.options.obligatory == 'on');
        }
    }

    isFloorValid() {
        if(this.state.floor.replaceAll('_', '').replaceAll('-', '').length > 0) {
            return this.state.floor.match(this.floor.pattern);
        }
        else {
            return !(this.props.config.custom_floor_number.options.obligatory == 'on');
        }
    }

    isDeliveryFlatNumberValid() {
        if(this.state.delivery.flatNumber.replaceAll('_', '').replaceAll('-', '').length > 0) {
            return this.state.delivery.flatNumber.match(this.flatNumber.pattern);
        }
        else {
            return !(this.props.config.custom_flat_number.options.obligatory == 'on');
        }
    }

    isDeliveryFloorValid() {
        if(this.state.delivery.floor.replaceAll('_', '').replaceAll('-', '').length > 0) {
            return this.state.delivery.floor.match(this.floor.pattern);
        }
        else {
            return !(this.props.config.custom_floor_number.options.obligatory == 'on');
        }
    }

    render() {
        return (
            <StepCustom label={t('s_customer._title')}
                        stepNo={this.props.stepNo}
                        currentStep={this.props.currentStep}
                        showStep={step => this.props.showStep(step)}
                        // nextStep={step => this.props.nextStep(step)}
            >

                <div className="customerRegistration">
                    <div className="customerRegistration__header">
                        <p className="par-2 --semiBold">{t('s_customer.text1')}</p>
                    </div>

                    <form action="">
                        <div className="grid -mb4">
                            <FormCheckbox value={this.state.companyAddress}
                                          isValid={true}
                                          onChange={v => this.setState({companyAddress: v})}>
                                {t('s_customer.companyAddress')}
                            </FormCheckbox>
                        </div>

                        {this.state.companyAddress ? (
                            <>
                                <div className="grid">
                                    <FormInput name="company_name" label={`* ${t('s_customer.company_name')}`} type="text"
                                               value={this.state.companyName}
                                               isValid={this.state.companyName.length > 0}
                                               touched={this.state.forceTouched}
                                               onChange={v => this.setState({companyName: v})}
                                    />
                                </div>
                                <div className="grid">
                                    <FormInput name="tax_id" label={`* ${t('s_customer.tax_id')}`} type="text"
                                               value={this.state.taxId}
                                               isValid={this.state.taxId.length > 0}
                                               touched={this.state.forceTouched}
                                               onChange={v => this.setState({taxId: v})}
                                    />
                                </div>
                            </>
                        ) : null}

                        <div className="grid">
                            <FormSelect name="title" label={'* '+t('s_customer.title')}
                                        isValid={this.state.title?.value?.length > 0}
                                        invalidFeedback={t('is_required', t('s_customer.title')) }
                                        options={this.titleOptions}
                                        selected={this.state.title}
                                        touched={this.state.forceTouched}
                                        onChange={v => this.setState({title: v})}
                            />
                        </div>


                        <div className="grid">
                            <FormInput name="name" label={`* ${t('s_customer.name')}`} type="text"
                                       invalidFeedback={t('is_required', t('s_customer.name'))}
                                       value={this.state.name}
                                       isValid={this.state.name.length > 0}
                                       touched={this.state.forceTouched}
                                       // onChange={v => this.setState({name: v})}
                                       onChange={v => !v.match(/\d/) ? this.setState({name: v}) : null}
                            />
                            <FormInput name="surname" label={`* ${t('s_customer.surname')}`} type="text"
                                       invalidFeedback={t('is_required', t('s_customer.surname'))}
                                       value={this.state.surname}
                                       isValid={this.state.surname.length > 0}
                                       touched={this.state.forceTouched}
                                       // onChange={v => this.setState({surname: v})}
                                       onChange={v => !v.match(/\d/) ? this.setState({surname: v}) : null}
                            />
                        </div>

                        <div className="grid">
                            <FormInput name="zip_code" label={`* ${t('s_customer.zip_code')}`} type="text"
                                       value={this.state.zipCode}
                                       isValid={this.state.zipCode.length > 0 && this.state.zipCode.match(this.zipCode.pattern)}
                                       invalidFeedback={t('incorrect', t('s_customer.zip_code'))}
                                       touched={this.state.forceTouched}
                                       ref={this.zipCodeRef}
                            />
                            <FormInput name="city" label={`* ${t('s_customer.city')}`} type="text"
                                       invalidFeedback={t('is_required', t('s_customer.city'))}
                                       value={this.state.city}
                                       isValid={this.state.city.length > 0}
                                       touched={this.state.forceTouched}
                                       onChange={v => this.setState({city: v})}
                            />
                        </div>
                        <div className="grid">
                            <FormInput name="street" label={`* ${t('s_customer.street')}`} type="text"
                                       invalidFeedback={t('is_required', t('s_customer.street'))}
                                       value={this.state.street}
                                       isValid={this.state.street.length > 0}
                                       touched={this.state.forceTouched}
                                       onChange={v => this.setState({street: v})}
                            />
                            <div className="grid -fromXs">
                                {this.props.config.custom_flat_number.value == 'on' ? (
                                    <FormInput name="flat_number" label={'* '+t('s_customer.flat_number')} type="text"
                                               value={this.state.flatNumber}
                                               // isValid={this.isFlatNumberValid()}
                                               isValid={this.props.config.custom_flat_number.options.obligatory == 'on' && !!this.state.flatNumber}
                                               invalidFeedback={t('incorrect', t('s_customer.flat_number'))}
                                               touched={this.state.forceTouched}
                                               onChange={v => v.match(/^([a-zA-Z]|\d)*$/) && v.length <= this.flatNumber.length ? this.setState({flatNumber: v}) : null}
                                               // ref={this.flatNumberRef}
                                    />
                                ) : null}
                                {this.props.config.custom_floor_number.value == 'on' ? (
                                    <FormInput name="floor" label={t('s_customer.floor')} type="text"
                                               value={this.state.floor}
                                               // isValid={this.isFloorValid()}
                                               isValid={!!this.floor}
                                               invalidFeedback={t('incorrect', t('s_customer.floor'))}
                                               touched={this.state.forceTouched}
                                               required={false}
                                               onChange={v => v.match(/^([a-zA-Z]|\d)*$/) && v.length <= this.floor.length ? this.setState({floor: v}) : null}
                                        // ref={this.floorRef}
                                    />
                                ) : null}
                            </div>
                        </div>

                        <div className="grid -full">
                            <FormSelect name="country" label={'* '+t('s_customer.country')}
                                        isValid={this.state.country?.value?.length > 0}
                                        invalidFeedback={t('is_required', t('s_customer.country')) }
                                        isSearchable={true}
                                        options={this.countries}
                                        selected={this.state.country}
                                        touched={this.state.forceTouched}
                                        onChange={v => this.setState({country: v})}
                            />
                        </div>

                        <div className="grid -full">
                            <FormInput name="phone" label={`* ${t('s_customer.phone')}`} type="text"
                                       value={this.state.phone}
                                       isValid={this.state.phone.length > 0 && this.state.phone.match(this.phone.pattern)}
                                       invalidFeedback={t('incorrect', t('s_customer.phone')) }
                                       touched={this.state.forceTouched}
                                       ref={this.phoneRef}
                            />
                        </div>
                        <div className="grid -mb4">
                            <FormInput name="email" label={`* ${t('s_customer.email')}`} type="email"
                                       invalidFeedback={t('is_required', t('s_customer.email')) }
                                       value={this.state.email}
                                       isValid={this.state.email.length > 0}
                                       touched={this.state.forceTouched}
                                       onChange={v => this.setState({email: v})}
                            />
                            {this.props.config.additional_email.value == 'on' ? (
                                <FormInput name="re_email" label={`* ${t('s_customer.re_email')}`} type="email"
                                           invalidFeedback={t('is_required', t('s_customer.re_email')) }
                                           value={this.state.reEmail}
                                           isValid={this.state.reEmail.length > 0 && this.state.reEmail == this.state.email}
                                           touched={this.state.forceTouched}
                                           onChange={v => this.setState({reEmail: v})}
                                />
                            ) : null}
                        </div>


                        <div className="grid -mb4">
                            <FormCheckbox value={this.state.customDeliverAddress}
                                          isValid={true}
                                          onChange={v => this.setState({customDeliverAddress: v})}>
                                {t('s_customer.anotherAddress')}
                            </FormCheckbox>
                        </div>




                        <div hidden={!this.state.customDeliverAddress}>
                                <div className="grid -mb4">
                                    <FormCheckbox value={this.state.delivery.companyAddress}
                                                  isValid={true}
                                                  onChange={v => this.setState({
                                                      delivery: {
                                                          ...this.state.delivery,
                                                          companyAddress: v
                                                      }
                                                  })}>
                                        {t('s_customer.companyAddress')}
                                    </FormCheckbox>
                                </div>

                                {this.state.delivery.companyAddress ? (
                                    <>
                                        <div className="grid">
                                            <FormInput name="delivery_company_name" label={`* ${t('s_customer.company_name')}`} type="text"
                                                       value={this.state.delivery.companyName}
                                                       isValid={this.state.delivery.companyName.length > 0}
                                                       touched={this.state.forceTouched}
                                                       onChange={v => this.setState({
                                                           delivery: {
                                                               ...this.state.delivery,
                                                               companyName: v
                                                           }
                                                       })}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="grid">
                                            <FormSelect name="delivery_title" label={t('s_customer.title')}
                                                        isValid={this.state.delivery.title?.value?.length > 0}
                                                        // invalidFeedback="Trade partner is required"
                                                        options={this.titleOptions}
                                                        selected={this.state.delivery.title}
                                                        touched={this.state.forceTouched}
                                                        onChange={v => this.setState({
                                                            delivery: {
                                                                ...this.state.delivery,
                                                                title: v
                                                            }
                                                        })}
                                            />
                                        </div>
                                    </>
                                )}

                                <div className="grid">
                                    <FormInput name="delivery_name" label={`* ${t('s_customer.name')}`} type="text"
                                               value={this.state.delivery.name}
                                               isValid={this.state.delivery.name.length > 0}
                                               touched={this.state.forceTouched}
                                               onChange={v => this.setState({
                                                   delivery: {
                                                       ...this.state.delivery,
                                                       name: v
                                                   }
                                               })}
                                    />
                                    <FormInput name="delivery_surname" label={`* ${t('s_customer.surname')}`} type="text"
                                               value={this.state.delivery.surname}
                                               isValid={this.state.delivery.surname.length > 0}
                                               touched={this.state.forceTouched}
                                               onChange={v => this.setState({
                                                   delivery: {
                                                       ...this.state.delivery,
                                                       surname: v
                                                   }
                                               })}
                                    />
                                </div>

                                <div className="grid">
                                    <FormInput name="delivery_zip_code" label={`* ${t('s_customer.zip_code')}`} type="text"
                                               value={this.state.delivery.zipCode}
                                               isValid={this.state.delivery.zipCode.length > 0 && this.state.delivery.zipCode.match(this.zipCode.pattern)}
                                               invalidFeedback={t('incorrect', t('s_customer.zip_code'))}
                                               touched={this.state.forceTouched}
                                               ref={this.deliveryZipCodeRef}
                                    />
                                    <FormInput name="delivery_city" label={`* ${t('s_customer.city')}`} type="text"
                                               value={this.state.delivery.city}
                                               isValid={this.state.delivery.city.length > 0}
                                               touched={this.state.forceTouched}
                                               onChange={v => this.setState({
                                                   delivery: {
                                                       ...this.state.delivery,
                                                       city: v
                                                   }
                                               })}
                                    />
                                </div>
                                <div className="grid">
                                    <FormInput name="delivery_street" label={`* ${t('s_customer.street')}`} type="text"
                                               value={this.state.delivery.street}
                                               isValid={this.state.delivery.street.length > 0}
                                               touched={this.state.forceTouched}
                                               onChange={v => this.setState({
                                                   delivery: {
                                                       ...this.state.delivery,
                                                       street: v
                                                   }
                                               })}
                                    />
                                    <div className="grid -fromXs">
                                        <FormInput name="delivery_flat_number" label={`* ${t('s_customer.flat_number')}`} type="text"
                                                   value={this.state.delivery.flatNumber}
                                                   // isValid={this.isDeliveryFlatNumberValid()}
                                                   isValid={!!this.state.delivery.flatNumber}
                                                   invalidFeedback={t('incorrect', t('s_customer.flat_number'))}
                                                   touched={this.state.forceTouched}
                                                   onChange={v => v.match(/^([a-zA-Z]|\d)*$/) && v.length <= this.flatNumber.length ? this.setState({
                                                       delivery: {
                                                           ...this.state.delivery,
                                                           flatNumber: v
                                                       }
                                                   }) : null}
                                            // ref={this.deliveryFlatNumberRef}
                                        />
                                        <FormInput name="delivery_floor" label={`* ${t('s_customer.floor')}`} type="text"
                                                   value={this.state.delivery.floor}
                                                   // isValid={this.isDeliveryFloorValid()}
                                                   isValid={!!this.floor}
                                                   invalidFeedback={t('incorrect', t('s_customer.floor'))}
                                                   touched={this.state.forceTouched}
                                                   // ref={this.deliveryFloorRef}
                                                   required={false}
                                                   onChange={v => v.match(/^([a-zA-Z]|\d)*$/) && v.length <= this.floor.length ? this.setState({
                                                       delivery: {
                                                           ...this.state.delivery,
                                                           floor: v
                                                       }
                                                   }) : null}
                                        />
                                    </div>
                                </div>
                                <div className="grid -full -mb4">
                                    <FormInput name="delivery_phone" label={'* '+t('s_customer.phone')} type="text"
                                               value={this.state.delivery.phone}
                                               isValid={this.state.delivery.phone.length > 0 && this.state.delivery.phone.match(this.phone.pattern)}
                                               invalidFeedback={t('incorrect') + t('s_customer.phone').toString().toLowerCase() }
                                               touched={this.state.forceTouched}
                                               ref={this.deliveryPhoneRef}
                                    />
                                </div>
                            </div>


                            <div className="customerRegistration__infoHeader">{t('s_customer.info_header')}</div>

                            <div className="customerRegistration__info">
                                <div className="customerRegistration__infoText">
                                    <img src={require('../../assets/images/icons/egifcard.png')} alt="-"/>
                                    <span dangerouslySetInnerHTML={{__html: t('s_customer.exclusive_offers')}}/>
                                </div>
                                <div className="customerRegistration__infoText">
                                    <img src={require('../../assets/images/icons/present.png')} alt="-"/>
                                    <span dangerouslySetInnerHTML={{__html: t('s_customer.gifts')}}/>
                                </div>
                                <div className="customerRegistration__infoText">
                                    <img src={require('../../assets/images/icons/beans.png')} alt="-"/>
                                    <span dangerouslySetInnerHTML={{__html: t('s_customer.latest_news')}}/>
                                </div>
                                <div className="customerRegistration__infoText">
                                    <img src={require('../../assets/images/icons/nieuws.png')} alt="-"/>
                                    <span dangerouslySetInnerHTML={{__html: t('s_customer.events')}}/>
                                </div>
                            </div>



                        {/*<div className="grid">*/}
                            <FormCheckbox value={this.state.receiveBenefitsEmail}
                                          customClass="-green -borderGreen"
                                          isValid={true}
                                          onChange={v => this.setState({receiveBenefitsEmail: v})}>
                                <div dangerouslySetInnerHTML={{__html: t('s_customer.text2')}}/>
                            </FormCheckbox>
                        {/*</div>*/}

                        {/*<div className="grid">*/}
                            <FormCheckbox value={this.state.receiveBenefitsPhone}
                                          customClass="-green -borderGreen"
                                          isValid={true}
                                          onChange={v => this.setState({receiveBenefitsPhone: v})}>
                                <div dangerouslySetInnerHTML={{__html: t('s_customer.text3')}}/>
                            </FormCheckbox>
                        {/*</div>*/}

                        {/*<div className="grid">*/}
                            <div className="customerRegistration__text">
                                <p dangerouslySetInnerHTML={{__html: t('s_customer.info1')}}/>
                                <p>
                                    <span dangerouslySetInnerHTML={{__html: t('s_customer.info2')}}/>
                                    {!this.state.receivePost ? (
                                        <p className="--brown">{t('s_customer.not_receive_post')}</p>
                                    ) : (
                                        <>
                                            &nbsp;<a href="" className="--brown"
                                                     onClick={e => {
                                                         e.preventDefault();
                                                         this.setState({receivePost: false})
                                                     }}>{t('click_here')}</a>
                                        </>
                                    )}
                                </p>

                                <p dangerouslySetInnerHTML={{__html: t('s_customer.info3')}}/>
                            </div>
                        {/*</div>*/}

                        {/*<div className="grid">*/}
                            <FormCheckbox value={this.state.termsAndConditions}
                                          customClass="-green"
                                          isValid={this.state.termsAndConditions}
                                          invalidFeedback={'Terms and conditions is required'}
                                          touched={this.state.forceTouched}
                                          onChange={v => this.setState({termsAndConditions: v})}>
                                <a href={t('terms_and_conditions_url')} target="_blank"
                                   onClick={e => e.stopPropagation()}
                                   className="--brown" dangerouslySetInnerHTML={{__html: t('s_customer.text4')}}/>
                            </FormCheckbox>
                        {/*</div>*/}
                        {/*<div className="grid -mb4">*/}
                            <FormCheckbox value={this.state.privacyPolicy}
                                          customClass="-green"
                                          isValid={this.state.privacyPolicy}
                                          invalidFeedback={'Privacy policy is required'}
                                          touched={this.state.forceTouched}
                                          onChange={v => this.setState({privacyPolicy: v})}>
                                <a href={t('legal_url')} target="_blank"
                                   onClick={e => e.stopPropagation()}
                                   className="--brown" dangerouslySetInnerHTML={{__html: t('s_customer.text5')}}/>
                            </FormCheckbox>
                        {/*</div>*/}

                        <input type="hidden" value={this.state.receivePost}/>


                        <div className="customerRegistration__requiredInfo">
                            <p className="par-4">{t('required_info')}</p>
                        </div>

                    </form>

                </div>

                <div className="step__nav">
                    <Button class="-primary -outline" label={t('back')} disabled={false} onClick={() => this.props.showStep(this.props.stepNo - 1)}/>

                    <Button class="-primary" label={t('continue')} disabled={!this.validateStep()} onClick={() => this.nextStep()}/>
                </div>


                {/*<button onClick={() => this.props.nextStep()}>continue</button>*/}

            </StepCustom>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRegistration);
