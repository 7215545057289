import React, {Component} from "react";
import * as actions from '../../actions/actions';
import {connect} from "react-redux";
import {setCsAuthorization, setCsGear, setCurrency, setLastValidStep, translate as t} from "../../actions/store";
import {
    Button,
    CoffeeItem,
    CoffeePopup,
    CoffeeSetPopup, CsOfferInfoPopup,
    FormInput,
    GearItem,
    PlusMinusButtons, ProofOfPurchasePopup,
    StepCustom
} from "../index";
import './CorpSubscriptionOffer.css';


const mapDispatchToProps = (dispatch) => ({
    setLastValidStep: step => dispatch(setLastValidStep(step)),
    setCsGear: (gear) => dispatch(setCsGear(gear)),
    setCurrency: (currency) => dispatch(setCurrency(currency)),
})


const mapStateToProps = (state) => ({
    csAuthorization: state.csAuthorization,
    csGear: state.csGear,
    isRegistered: state.isRegistered,
    lang: state.lang,
    currency: state.currency,
})


class CorpSubscriptionOffer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gears: [],
            text: '',
            isRegistered: !!props.isRegistered,
            showGears: true,

            gear: props.csGear ?? null,

            showInfoPopup: false,
        };
    }

    componentDidMount() {
        this.getGears();

        actions.getCurrency(this.props.lang ?? 'en').then((r) => {
            if (r.status == 'success') {
                // console.log('set currency: ', r.data)
                this.props.setCurrency(r.data);
            }
        })

        if(this.state.gear) {
            this.props.setLastValidStep(this.props.stepNo);
        }
    }

    getGears() {
        actions.getCsGear(this.props.csAuthorization.token, this.props.lang).then(res => {
            if(res.status.toString() === 'success') {
                const text = res.data.text[Object.keys(res.data.text).find(k => k.startsWith(this.props.lang))] ?? '';
                this.setState({
                    gears: res.data.items,
                    text,
                })
            }
        })
    }

    setGear(gear) {
        // const gear = {...this.state.gears.find(g => g.id_shop_gear === id)}
        // gear.color_mask = color;

        this.setState({
            gear,
        }, () => {
            this.props.setLastValidStep(this.props.stepNo);
            this.props.setCsGear(this.state.gear);
        })
    }

    nextStep(isRegistered = false) {
        if(this.state.gear || isRegistered) {
            const nextStep = this.props.stepNo + 1;
            this.props.showStep(nextStep);
        }
    }

    nextStepWithoutGear() {
        this.setState({
            isRegistered: true,
            gear: null,
        }, () => {
            this.props.setLastValidStep(this.props.stepNo);
            this.props.setCsGear(this.state.gear);
        })
    }

    render() {
        return (
            <StepCustom label={t('cs_offer._title')}
                        stepNo={this.props.stepNo}
                        currentStep={this.props.currentStep}
                        showStep={step => this.props.showStep(step)}
            >
                {!this.state.isRegistered && !this.state.showGears && (
                    <div className="csOffer">
                        {/*<p className="par-2 --semiBold --mb4">*/}
                        {/*    <span dangerouslySetInnerHTML={{__html: t('cs_offer.is_registered.label')}} />*/}
                        {/*    <button title={t('s_machine.text5')} className="link -primary"*/}
                        {/*            onClick={() => this.setState({showInfoPopup: true})}>*/}
                        {/*        {t('s_machine.text5')}*/}
                        {/*        <img src={require('../../assets/images/icons/arrow-right-brown.svg')} alt=">"/>*/}
                        {/*    </button>*/}
                        {/*</p>*/}

                        <p className="par-2 --semiBold --mb4">
                            <span dangerouslySetInnerHTML={{__html: t('cs_offer.is_registered.label1')}}/>
                            <button className="link -normalSize -primary --semiBold -underline"
                                    onClick={e => this.setState({showInfoPopup: true})}>{t('cs_offer.is_registered.label2')}</button>
                            <span dangerouslySetInnerHTML={{__html: t('cs_offer.is_registered.label3')}}/>
                        </p>



                        <div className="step__nav">
                            <Button class="-primary"
                                    onClick={() => this.nextStepWithoutGear()}>{t('cs_offer.is_registered.yes')}</Button>
                            <Button class="-primary"
                                    onClick={() => this.setState({
                                        showGears: true,
                                        gear: null,
                                    })}>{t('cs_offer.is_registered.no')}</Button>
                        </div>
                    </div>
                )}

                {this.state.showGears && (
                    <div className="csOffer">
                        <p className="par-2 --semiBold --mb4" dangerouslySetInnerHTML={{__html: this.state.text}} />

                        {this.state.gears?.length > 0 ? (
                            <div className="csOffer__items">
                                {this.state.gears.map(item => (
                                    <GearItem key={item.id_corporate_subscription_plan}
                                              item={item}
                                              gear={this.state.gear}
                                              // isSelected={this.state.gear?.id_shop_gear === item.id_shop_gear}
                                              setGear={gear => this.setGear({...item, ...gear})}/>
                                ))}
                            </div>
                        ) : (
                            <div className="preloader">
                                <img src={require('../../assets/images/icons/preloader2.gif')} alt="..."/>
                            </div>
                        )}



                        <div className="step__nav">
                            <Button class="-primary -outline"
                                    onClick={e => this.props.showStep(this.props.stepNo - 1)}>{t('back')}</Button>
                            <Button class="-primary" onClick={() => this.nextStep()}
                                    disabled={!this.state.gear}>{t('continue')}</Button>
                        </div>

                    </div>
                )}

                {!this.state.showGears && this.state.isRegistered && (
                    <div className="csOffer">
                        <p className="par-2 --red --semiBold --mb4"
                           dangerouslySetInnerHTML={{__html: t('cs_offer.is_registered.info')}} />

                        <div className="step__nav">
                            <Button class="-primary -outline"
                                    onClick={e => this.props.showStep(this.props.stepNo - 1)}>{t('back')}</Button>
                            <Button class="-primary"
                                    onClick={() => this.nextStep(true)}>{t('continue')}</Button>
                        </div>
                    </div>
                )}


                <CsOfferInfoPopup show={this.state.showInfoPopup} onClose={() => this.setState({showInfoPopup: false})}/>
            </StepCustom>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CorpSubscriptionOffer);
