import React, {Component} from 'react';
import {Button, CoffeePopup, CoffeeSetPopup, PlusMinusButtons} from "../index";
import './CoffeeItem.css';
import { connect } from 'react-redux';
import {updateCart, translate as t} from "../../actions/store";

const mapStateToProps = (state) => ({
    cart: state.cart,
    cartSum: state.cartSum,
    allProducts: state.allProducts,
    currency: state.currency,
})

const mapDispatchToProps = (dispatch) => ({
    updateCart: (cart) => dispatch(updateCart(cart))
})



class CoffeeItem extends Component {
    constructor(props) {
        super(props);

        this.index = -1;
        this.item = props.allProducts[props.item.id]

        if(this.item) {
            this.index = props.cart.items.findIndex(i => i.itemId === this.item.id)
        }

        this.allProducts = props.allProducts;

        this.state = {
            cart: props.cart,
            cartSum: props.cartSum,
            showCoffeePopup: false,
            index: this.index
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.allProducts != this.props.allProducts) {
            this.index = -1;
            this.item = this.props.allProducts[this.props.item.id]

            if(this.item) {
                this.index = this.props.cart.items.findIndex(i => i.itemId === this.item.id)
            }
        }

        if(this.props.cartSum != this.state.cartSum) {
            this.index = -1;
            if(this.item) {
                this.index = this.props.cart.items.findIndex(i => i.itemId === this.item.id);
            }
            this.setState({
                cart: this.props.cart,
                cartSum: this.props.cartSum,
                index: this.index
            })
        }
    }

    onChangeValue(v) {

        if(v > 0) {

            // console.log(this.item, this.props.item);

            if(this.state.index >= 0) {
                this.state.cart.items[this.state.index].value = v;
            }else{
                this.state.cart.items.push({
                    itemId: this.item.id,
                    value: v,
                    sku: this.item.sku,
                    price: this.item.price,
                });
            }

        }else{
            this.state.cart.items.splice(this.state.index, 1);
        }


        this.props.updateCart(this.state.cart);
    }

    render() {
        const item = this.props.item;

        const itemCartConfig = this.props.itemCartConfig;

        let intensityBox = [];
        for (let i = 0; i < 13; i++) {
            let active = i < item.intensity ? '-active' : '';
            intensityBox.push(<span key={i} className={active}/>);
        }

        const isMilkCoffee = item.cupsSizes.toString().split(' ').includes('milk');
        // console.log('img: ', item.img)

        return (
            <div className="coffeeItem">
                <div className="coffeeItem__header">
                    <div className="coffeeItem__thumb">
                        <img src={item.img} alt={item.title}/>
                    </div>
                    <h3 className="coffeeItem__title">{item.title}</h3>
                </div>
                <div className="coffeeItem__body">
                    <p className="coffeeItem__subtitle">{item.subtitle}</p>
                    <div className="coffeeItem__price">
                        {/*{item.step === 1 && item.promoPrice.promotion_price !== null ? (*/}
                        {/*    <span className="coffeeItem__promoPrice">{this.props.currency.prefix}{item.promoPrice?.promotion_price}{this.props.currency.postfix}</span>*/}
                        {/*) : null}*/}
                        <span>{this.props.currency.prefix}{item.price}{this.props.currency.postfix}</span>
                    </div>
                    {/*{item.step > 1 ? (*/}
                        <div className="coffeeItem__link">
                            <button className="link -primary" onClick={() => this.setState({showCoffeePopup: true})}>{t('read_more')}</button>
                        </div>
                    {/*) : null}*/}
                    {item.step > 1 && !isMilkCoffee && item.intensity > 0 ? (
                        <div className="coffeeItem__intensity">
                            <div className="coffeeItem__intensityBox">
                                {intensityBox.map(item => item)}
                            </div>
                            <span className="coffeeItem__intensityText">{t('coffee.intensity')} {item.intensity}</span>
                        </div>
                    ) : null}
                    <div className="coffeeItem__button">
                        {this.state.index >= 0 ? (
                            <PlusMinusButtons id={item.id} disabled={this.props.disableAdd}/>
                        ) : (
                            <Button class="-primary -sm" label={t('buy_now')}
                                    disabled={this.props.disableAdd}
                                    onClick={() => this.onChangeValue(item.step)}/>
                        )}
                    </div>
                </div>

                {item.new ? <span className="coffeeItem__new">{t('new')}</span> : <></>}

                {item.step > 1 ? (
                    <CoffeePopup
                        item={item}
                        inCartValue={this.state.inCartValue}
                        itemCartConfig={this.props.itemCartConfig}
                        onChange={v => this.onChangeValue(v)}
                        show={this.state.showCoffeePopup}
                        disableAdd={this.props.disableAdd}
                        onClose={() => this.setState({showCoffeePopup: false})}/>
                ) : (
                    <CoffeeSetPopup
                        item={item}
                        inCartValue={this.state.inCartValue}
                        itemCartConfig={this.props.itemCartConfig}
                        onChange={v => this.onChangeValue(v)}
                        show={this.state.showCoffeePopup}
                        disableAdd={this.props.disableAdd}
                        onClose={() => this.setState({showCoffeePopup: false})}/>
                )}

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoffeeItem);
