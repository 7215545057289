import React, {Component} from 'react';
import './Forms.css';

class FormCheckbox extends Component {
    constructor(props) {
        super(props);

        let valid = true;

        if(props.isValid === false)
            valid = false

        let disabled = false;
        if(props.disabled === true)
            disabled = true;

        let invalidFeedback = props.invalidFeedback;
        if(!invalidFeedback)
            invalidFeedback = props.label + ' is required';

        this.state = {
            class: 'formCheckbox' + (this.props.disabled ? ' -disabled' : ''),
            value: props.value,
            invalidFeedback,
            isValid: valid,
            disabled,
            touched: false,
        };

        this.input = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.invalidFeedback != this.state.invalidFeedback) {
            if(this.props.invalidFeedback) {
                this.setState({
                    invalidFeedback: this.props.invalidFeedback
                })
            }

        }

        if(this.props.isValid != this.state.isValid) {
            this.setState({
                isValid: this.props.isValid
            })
        }

        if(this.props.disabled != this.state.disabled) {
            this.setState({
                disabled: this.props.disabled
            })
        }

        if(this.props.touched != this.state.touched) {
            this.setState({
                touched: this.props.touched,
            })
        }

    }

    onFocusInput(addClass) {
        let classes = 'formCheckbox';
        if(this.state.disabled)
            classes += ' -disabled';

        this.setState({ class: addClass ? this.state.class + ' -focused' : classes})

        if(this.props.onFocus)
            this.props.onFocus();
    }

    onBlurInput() {
        this.onFocusInput(false);
        if(this.props.onBlur)
            this.props.onBlur();
        this.setState({touched: true});
    }

    onChangeInput() {
        this.setState({
            value: !this.state.value,
            touched: true,
        }, () => {
            this.props.onChange(this.state.value);
        });
    }

    onClickGroup() {
        // this.input.current.focus();
        this.onChangeInput();
    }

    render() {

        let formGroupClasses = this.state.class;
        if(this.state.disabled)
            formGroupClasses += ' -disabled';

        if(!this.state.isValid && this.state.touched)
            formGroupClasses += ' -invalid';

        formGroupClasses += this.props.customClass ? ' '+this.props.customClass : '';

        return (
            <div className={formGroupClasses} onClick={() => this.onClickGroup()}>
                <div className="formCheckbox__field">
                    <input id={this.props.name} name={this.props.name} type="checkbox"
                           value={this.props.value}
                           ref={this.input}
                           disabled={this.props.disabled}
                           hidden
                    />

                    <div className="formCheckbox__input"
                         data-checked={this.state.value}
                         onClick={() => this.onChangeInput()}
                    />
                    <label htmlFor={this.props.name}>
                        {this.props.children}
                    </label>
                </div>

                {!this.state.isValid?<div className="formGroup__invalidFeedback">{this.state.invalidFeedback}</div>:<></>}
            </div>
        );
    }
}

export default FormCheckbox;
