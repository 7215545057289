import React, {Component} from "react";
import './Footer.css';
import {connect} from 'react-redux';
import {translate as t} from "../../actions/store";

const mapStateToProps = (state) => ({
    lang: state.lang,
    promotion: state.promotion,
})

class Footer extends Component {
    constructor(props) {
        super(props);

        this.termsHref = t('terms_and_conditions_url');
        if (window.location.href.match(/\/workfromhome/)) {
            this.termsHref = t('cs_terms_and_conditions_url');
        }
        if(this.props.promotion?.id_shop_promotion === 31) {
            this.termsHref = t('terms_and_conditions_url_promo31');
        }

        this.legalUrl = 'promotie.nespresso.nl';
        this.termsUrl = 'promotie.nespresso.nl';
    }

    render() {
        return (
            <footer className="footer">
                <div className="wrapper-bg">
                    <div className="wrapper -xxl">
                        <div className="grid">
                            <div>&copy; Nespresso 2020</div>
                            <ul>
                                {/*<li>*/}
                                {/*    <a href={t('legal_url')} target="_blank">{t('legal')}</a>*/}
                                {/*</li>*/}
                                <li>
                                    <a href={this.termsHref} target="_blank">{t('terms_and_conditions')}</a>
                                </li>
                                {/*<li>*/}
                                {/*    <a href="#" target="_blank">{t('contact_us')}</a>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </div>
                </div>

            </footer>
        );
    }
}

export default connect(mapStateToProps)(Footer);

