import React, {Component} from 'react';
import './Buttons.css';

class ButtonClose extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let classes = 'btnClose ' + this.props.positionClass;
        return (
            <button className={classes} style={this.props.style}
                    disabled={this.props.disabled} onClick={() => this.props.onClick()}>
                <img src={require('../../assets/images/icons/close.svg')} alt="x" />
            </button>
        );
    }
}

export default ButtonClose;
