import React, {Component} from "react";
import './Menu.css';

class Menu extends Component {

    render() {
        const body = document.querySelector('body');
        let menuClass = 'menu';
        if(this.props.show) {
            menuClass += ' -active';
            body.classList.add('-overflow-hidden');
        }
        else {
            body.classList.remove('-overflow-hidden');
        }

        return (
            <div className={menuClass}>
                <div className="menu__overlay" onClick={() => this.props.onClose()}/>
                <div className="menu__content">
                    <div className="menu__header">
                        <a href="/" title={"Nespresso"} className="menu__logo">
                            <img src={require('../../assets/images/logo-black.svg')} alt="" className="header__logo"/>
                        </a>
                        <button className="menu__close" onClick={() => this.props.onClose()}>
                            <img src={require('../../assets/images/icons/close.svg')} alt="X"/>
                        </button>
                    </div>
                    <div className="menu__body">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">Home</a></li>
                        </ul>
                    </div>
                    <ul className="langNav -mobile">
                        {this.props.langs.map((lang, key) => {
                            let active = this.props.activeLang === lang ? '-active' : null;
                            return <li key={key} className={active}><button key={key} onClick={() => this.props.handleChangeLang(lang)}>{lang}</button></li>
                        })}
                    </ul>

                </div>
            </div>
        );
    }
}

export default Menu;
