
export const STEP_1 = 'machine-registration';
export const STEP_2 = 'choose-your-coffee';
export const STEP_3 = 'customer-registration';
export const STEP_4 = 'order-summary';


export const CORP_SUBSCRIPTION_STEP_1 = '/';
export const CORP_SUBSCRIPTION_STEP_2 = '/product';
export const CORP_SUBSCRIPTION_STEP_3 = '/offer';
export const CORP_SUBSCRIPTION_STEP_4 = '/register';
export const CORP_SUBSCRIPTION_STEP_5 = '/summary';


export const createRegex = (formatString) => {
    if(!formatString)
        return null;

    let regex = '';
    let r = {};

    const appendToRegex = (r) => {
        regex += r.prefix + '{' + r.count + '}'
        r = {};
    }

    const checkSign = (sign, prefix, i) => {
        if(r.prefix !== prefix) {
            r.prefix = prefix;
            r.count = 0;
        }
        if(r.prefix === prefix) {
            r.count += 1;
        }

        if(typeof formatString[i+1] !== "undefined" && formatString[i+1] !== sign)
            appendToRegex(r);
    }

    for(let i = 0; i < formatString.length; i++) {
        switch(formatString[i]) {
            case '0': {
                checkSign('0', '\\d', i)
                break;
            }
            case '-': {
                checkSign('-', '\\-', i)
                break;
            }
            case 'A': {
                checkSign('A', '[a-zA-Z]', i)
                break;
            }
        }
    }
    appendToRegex(r);

    return new RegExp("^" + regex + "$");
}


export const createMask = (formatString) => {
    if(!formatString)
        return null;

    // I-MASK
    // where definitions are:
    // 0 - any digit
    // a - any letter
    // * - any char other chars which is not in custom definitions supposed to be fixed
    // [] - make input optional
    // {} - include fixed part in unmasked value
    // ` - prevent symbols shift back

    let mask = '';

    for(let i = 0; i < formatString.length; i++) {
        switch(formatString[i]) {
            case '-': {
                mask += '`-';
                break;
            }
            default: {
                mask += formatString[i].toString().toLowerCase();
                break;
            }
        }
    }

    return mask;
}



export const removeNbsp = (text) => {
    return text.replace(/(?:^[\s\u00a0]+)|(?:[\s\u00a0]+$)|&nbsp;/g, '');
}


export const isEmailValid = (email: string) => {
    return email.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
}
