import React, {Component} from 'react';
import './Buttons.css';
import {ValuesTooltip} from "../index";
import { connect } from 'react-redux';
import {updateCart} from "../../actions/store";

const mapStateToProps = (state) => ({
    cart: state.cart,
    cartSum: state.cartSum,
    allProducts: state.allProducts
})

const mapDispatchToProps = (dispatch) => ({
    updateCart: (cart) => dispatch(updateCart(cart))
})

class PlusMinusButtons extends Component {
    constructor(props) {
        super(props);

        this.item = props.allProducts[props.id];
        this.index = -1;

        if(this.item) {
            this.index = props.cart.items.findIndex(i => i.itemId === this.item.id)
        }

        this.state = {
            value: this.index >= 0 ? props.cart.items[this.index].value : 0,
            step: this.item?this.item.step:0,
            min: this.item?this.item.min:0,
            max: this.item?this.item.maxQty:0,
            showValuesTooltip: false,
            cart: props.cart,
            cartSum: props.cartSum,
            index: this.index
        };

        this.options = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 150, 200, 250, 300];
        if(this.item && this.item.maxQty < 10) {
            this.options = false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if(this.props.value != this.state.value)
        //     this.setState({value: this.props.value});

        if(this.item) {
            this.index = this.props.cart.items.findIndex(i => i.itemId === this.item.id)

            if(this.props.cartSum != this.state.cartSum) {
                this.setState({
                    cart: this.props.cart,
                    cartSum: this.props.cartSum,
                    index: this.index,
                    value: this.index >= 0 ? this.props.cart.items[this.index].value : 0
                })
            }
        }


    }

    onClickPlus() {
        let newValue = this.state.value + this.state.step;
        if(this.state.max == null || newValue < this.state.max) {
            this.onChange(newValue)
        }
    }

    onClickMinus() {
        let newValue = this.state.value - this.state.step;
        this.onChange(newValue)
    }

    onChange(v) {
        // console.log(this.state.cart)

        if(v > 0) {
            if(this.state.index >= 0) {
                this.state.cart.items[this.state.index].value = v;
            }else{
                this.state.cart.items.push({
                    itemId: this.item.id,
                    value: v,
                    sku: this.item.sku,
                    price: this.item.price,
                });
            }

        }else{
            this.state.cart.items.splice(this.state.index, 1);
        }


        this.props.updateCart(this.state.cart);
    }

    render() {
        return (
            <div className="btnPlusMinus">
                <button className="btnPlusMinus__btn" onClick={() => this.onClickMinus()}>
                    <img src={require('../../assets/images/icons/minus-white.svg')} alt="-"/>
                </button>
                <div className="btnPlusMinus__value">
                    {this.options ? (
                        <div>
                            <button className="btnPlusMinus__valueBtn" onClick={() => this.setState({showValuesTooltip: true})}>{this.state.value}</button>
                            {this.state.showValuesTooltip ? (
                                <div>
                                    <div className="valuesTooltipOverlay" onClick={() => this.setState({showValuesTooltip: false})}/>
                                    <ValuesTooltip value={this.state.value} options={this.options} onChange={v => {
                                        this.setState({showValuesTooltip: false}, () => this.onChange(v))
                                    }}/>
                                </div>
                            ) : <></>}
                        </div>
                    ) : (
                        <span className="btnPlusMinus__valueBtn">{this.state.value}</span>
                    )}
                </div>
                <button className="btnPlusMinus__btn" disabled={this.props.disableAdd || this.state.value >= this.state.max} onClick={() => this.onClickPlus()}>
                    <img src={require('../../assets/images/icons/plus-white.svg')} alt="-"/>
                </button>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlusMinusButtons);
