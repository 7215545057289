import React, {Component} from 'react';
import './Header.css';
import {CartPopup, CartSummary, Menu, Alert} from "../index";
import {connect} from "react-redux";
import {setLang, translate as t} from "../../actions/store";

const mapDispatchToProps = (dispatch) => ({
    setLang: (lang) => dispatch(setLang(lang))
})

const mapStateToProps = (state) => ({
    lang: state.lang,
    cart: state.cart,
    cartSum: state.cartSum,
    promotion: state.promotion,
    config: state.config,
    allProducts: state.allProducts,
    csGear: state.csGear,
})

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCartBox: false,
            cart: props.cart,
            cartSum: props.cartSum,
            showMenu: false,
            languages: ['en'],
        }

        // this.langs = ['en'];
        // if(this.props.config.multilingual_front_office?.value == 'on') {
        //     if(this.props.config.multilingual_front_office.options.languages.length > 0)
        //         this.langs = this.props.config.multilingual_front_office.options.languages;
        // }

    }

    componentDidMount() {
        this.getLanguages();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.cartSum != this.state.cartSum) {
            this.setState({
                cart: this.props.cart,
                cartSum: this.props.cartSum
            })
        }

        if(prevProps.config !== this.props.config) {
            this.getLanguages();
        }

        if(this.props.lang !== prevProps.lang) {
            window.location.reload();
        }
    }

    getLanguages() {
        if(this.props.config.multilingual_front_office?.value == 'on') {
            if(this.props.config.multilingual_front_office.options.languages.length > 0) {
                this.setState({
                    languages: this.props.config.multilingual_front_office.options.languages,
                })
            }
        }
    }

    changeLang(lang) {
        this.props.setLang(lang);
    }

    toggleCartBox() {
        this.setState({showCartBox: !this.state.showCartBox})
    }

    onChangeValue(id, value) {
        let items = this.state.cart.items;
        let index = items.findIndex(i => i.id === id);

        if(index === -1)
            return;

        let item = items[index];
        item.value = value;

        items[index] = item;

        this.setState({
            cart: {
                ...this.state.cart,
                items: items,
            }
        })
    }

    cartValidation() {
        let value = 0;
        this.props.cart.items.map(i => {
            value += i.value;
        })

        return {
            isValid: value >= this.props.csGear.corporate_subscription_plan_subrebate_price,
            type: 'error',
            text: t('tip.capsules_minimum_count', this.props.csGear.corporate_subscription_plan_subrebate_price),
        };
    }

    getCapsulesCount() {
        let value = 0;
        this.state.cart.items.map(i => {
            const item = this.props.allProducts[i.itemId];
            if(item.step > 1) {
                value += i.value
            }
            else {
                console.log('else...', item)
                value += item.capsuleCount;
            }
        })
        return value;
    }

    render() {
        let isCartEmpty = true;
        if(this.state.cart.discounts.length > 0 || this.state.cart.items.length > 0)
            isCartEmpty = false;

        const cartValidation = this.cartValidation();
        let cartAlert = null;
        if(!cartValidation.isValid && this.state.cart.items.length > 0)
        {
            cartAlert = <div className="header__alert">
                <Alert className={`-${cartValidation.type}`}>{cartValidation.text}</Alert>
            </div>

        }

        return (
            <>
                <header className="header">
                    <div className="wrapper -xxl">
                        <div className="grid">
                            <div className="header__box -left">
                                {/*<span>test: {t('welcome.title')}</span>*/}
                                {/*<button className="header__toggleMenu" onClick={() => this.setState({showMenu: !this.state.showMenu})}>*/}
                                {/*    <img src={require('../../assets/images/icons/menu.svg')} alt="menu"/>*/}
                                {/*</button>*/}
                            </div>
                            <div className="header__box -center">
                                <a href="/" title={"Nespresso"}>
                                    <img src={require('../../assets/images/logo-black.svg')} alt="" className="header__logo"/>
                                </a>
                            </div>
                            <div className="header__box -right">
                                {cartAlert}

                                <div className="header__menu">
                                    {window.location.pathname.match(/^\/shop/) || window.location.pathname.match(/^\/workfromhome/) ? (
                                    <div className="basket">
                                        {this.props.promotion !== false || window.location.pathname.match(/^\/workfromhome/) ?(
                                            <button className="basket__info" onClick={() => this.toggleCartBox()}>
                                                <img src={require('../../assets/images/icons/basket.svg')} alt="Your basket" className="basket__infoThumb"/>
                                                <span className="basket__infoCount">{this.getCapsulesCount()}</span>
                                            </button>
                                        ):null}

                                        {this.state.showCartBox && document.querySelector('body').offsetWidth > 768 ? (
                                            <>
                                                <div className="cartBox__overlay" onClick={() => this.setState({showCartBox: false})}/>
                                                <div className="cartBox">
                                                    <div className="cartBox__header">
                                                        <span/>
                                                        <h4 className="cartBox__title">{t('basket')}</h4>
                                                        <button className="cartBox__close" onClick={() => this.toggleCartBox()}>
                                                            <img src={require('../../assets/images/icons/close.svg')} alt="x"/>
                                                        </button>
                                                    </div>
                                                    <CartSummary scrollable={true} onChange={(id, value) => this.onChangeValue(id, value)}/>
                                                </div>
                                            </>
                                        ) : (
                                            <CartPopup show={this.state.showCartBox} onClose={() => this.toggleCartBox()} showGetInTouch={isCartEmpty}>
                                                <CartSummary onChange={(id, value) => this.onChangeValue(id, value)}/>
                                            </CartPopup>
                                        )}
                                    </div>
                                    ) : <div/>}

                                    <ul className="langNav">
                                        {/*{this.langs.map((lang, key) => {*/}
                                        {/*    let active = this.props.lang === lang ? '-active' : null;*/}
                                        {/*    return <li key={key} className={active}><button key={key} onClick={() => this.changeLang(lang)}>{lang}</button></li>*/}
                                        {/*})}*/}
                                        {this.state.languages.map((lang, key) => {
                                            let active = this.props.lang === lang ? '-active' : null;
                                            return <li key={key} className={active}><button key={key} onClick={() => this.changeLang(lang)}>{lang}</button></li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <Menu show={this.state.showMenu}
                      onClose={() => this.setState({showMenu: false})}
                      langs={this.state.languages}
                      activeLang={this.props.lang}
                      handleChangeLang={lang => this.changeLang(lang)}
                />
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
