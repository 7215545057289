import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './CorpSubscriptionSummary.css';
import {StepCustom, Alert, Button, CartPopup, CartSummary, CartValidationAlert, OrderSuccessPopup, Step} from "../index";
import { connect } from 'react-redux';
import * as actions from "../../actions/actions";
import {clearStore, setLastOrderTotal} from "../../actions/store";
import {translate as t} from "../../actions/store";

const mapStateToProps = (state) => ({
    customer: state.customer,
    cart: state.cart,
    purchase: state.purchase,
    machine: state.machine,
    cartSum: state.cartSum,
    allProducts: state.allProducts,
    promotion: state.promotion,
    lang: state.lang,
    ocrLogs: state.ocrLogs,
    currency: state.currency,
    id_shop_gear: state.id_shop_gear,

    csAuthorization: state.csAuthorization,
    csGear: state.csGear,
})

const mapDispatchToProps = (dispatch) => ({
    setLastOrderTotal: (lastOrderTotal) => dispatch(setLastOrderTotal(lastOrderTotal)),
    clearStore: () => dispatch(clearStore()),
})

class CorpSubscriptionSummary extends Component {
    constructor(props) {
        super(props);

        let {sum, discount, productsOriginalPrice} = this.getTotalPrice(props.cart, props.allProducts);



        this.state = {
            customer: props.customer,
            showSuccessPopup: false,
            cart: props.cart,
            cartSum: props.cartSum,
            sum: 25,
            discount: 0,
            productsOriginalPrice: 25,
            canFinish: this.canFinish(),
            finished: false,
            error: false,
            errorMessage: '',
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.customer != this.state.customer) {
            this.setState({
                customer: this.props.customer
            })
        }

        if(this.props.cartSum != this.state.cartSum) {
            let {sum, discount, productsOriginalPrice} = this.getTotalPrice(this.props.cart, this.props.allProducts);
            this.setState({
                cart: this.props.cart,
                cartSum: this.props.cartSum,
                canFinish: this.canFinish()
            }, () => {
                // console.log('canf', this.state.canFinish);
                this.setState({
                    sum, discount, productsOriginalPrice
                })
            })
        }

        if(prevState !== this.state) {
            this.props.keepSession();
        }
    }

    canFinish() {
        // let value = this.getTotalPrice(this.props.cart, this.props.allProducts).sum;
        //
        // return value.toFixed(2) >= this.props.csGear.corporate_subscription_plan_subrebate_price;
        let value = 0;
        this.props.cart.items.map(i => {
            value += i.value;
        })

        return value >= this.props.csGear.corporate_subscription_plan_subrebate_price;
    }

    getTotalPrice(cart, products) {
        let sum = 0.00;
        let discount = 0.00;
        let productsOriginalPrice = 0.00;

        for(let i in cart.items) {
            const item = products[cart.items[i].itemId];
            const price = parseFloat(item.price);
            let originalPrice = price;

            if(item.promoPrice.promotion_price !== null) {
                originalPrice = parseFloat(item.promoPrice.promotion_price);
            }

            sum += (price * cart.items[i].value)
            productsOriginalPrice += (originalPrice * cart.items[i].value)
        }

        for(let i in cart.discounts) {
            let price = products[cart.discounts[i].itemId].price;
            discount += (price * cart.discounts[i].value)
        }

        return {sum, discount, productsOriginalPrice};
    }

    finalize() {

        // window.location.href = '/order-finalized';

        // this.props.clearStore();

        this.setState({
            finished: true,
            error: false,
            errorMessage: '',
        }, () => {
            let order = {
                authorization: this.props.csAuthorization,
                gear: this.props.csGear,
                customer: {
                    ...this.props.customer,
                    lang: this.props.lang,
                },
                // machine: this.props.machine,
                // purchase: this.props.purchase,
                cart: this.state.cart,
                // promotion: this.props.promotion,
                lang: this.props.lang,
                // ocrLogs: this.props.ocrLogs,
                // id_shop_gear: this.props.id_shop_gear
            }

            // console.log('order: ', order)

            actions.csFinalize(order, this.props.csAuthorization.token).then(r => {
                if(r.status == 'success') {
                    // this.props.setLastOrderTotal(r.data.total_value);
                    // this.props.history.push('/order-finalized');
                    // window.location.href = '/order-finalized';
                    // console.log('success!')

                    // window.location.href = '/order-finalized';

                    window.location.href = '/order-finalized';
                }
                else if(r.status == 'error') {
                    this.setState({
                        finished: false,
                        error: true,
                        errorMessage: r.message,
                    })
                }
            })
        })
    }

    getCartSum(withRebate = true) {
        // const value = this.getTotalPrice(this.props.cart, this.props.allProducts).sum
        //     - this.props.csGear?.corporate_subscription_plan_subrebate_price;
        // console.log('test', this.getTotalPrice(this.props.cart, this.props.allProducts).sum - )

        // let value = 0;
        // this.props.cart.items.map(i => {
        //     value += i.value;
        // })

        let price = 0;
        let value = 0;
        this.props.cart.items.map(i => {
            const item = this.props.allProducts[i.itemId];
            price += i.value * item.price;
            value += i.value;
        })

        // if(!withRebate && value >= this.props.csGear?.corporate_subscription_plan_subrebate_price) {
        //     price -= this.props.csGear?.corporate_subscription_plan_price;
        // }

        // value -= this.props.csGear?.corporate_subscription_plan_subrebate_price;

        // if(value >= 0) {
        //     return Number(value).toFixed(2)
        // }
        // return Number(0);

        return price.toFixed(2);
    }

    getTotalSum() {
        const value = (this.props.csGear?.corporate_subscription_plan_machine_price ?? 0)
            - (this.props.csGear?.corporate_subscription_plan_price ?? 0)
            + Number(this.getCartSum(false))
        return Number(value).toFixed(2);
    }

    getPriceText(price) {
        // const value = price > 0 ? Number(price).toFixed(2) : 0;
        return `${this.props.currency.prefix}${Number(price).toFixed(2)}${this.props.currency.postfix}`;
    }

    render() {

        let deliveryAddress = {};
        if(this.state.customer.customDeliverAddress) {
            if(this.state.customer.delivery.companyAddress) {
                deliveryAddress.company = this.state.customer.delivery.companyName;
            }
            deliveryAddress.name = this.state.customer.delivery.name;
            deliveryAddress.surname = this.state.customer.delivery.surname;
            deliveryAddress.zipCode = this.state.customer.delivery.zipCode;
            deliveryAddress.city = this.state.customer.delivery.city;
            deliveryAddress.address = this.state.customer.delivery.address;
            deliveryAddress.address2 = this.state.customer.delivery.address2;
            // deliveryAddress.street = this.state.customer.delivery.street;
            // deliveryAddress.flatNumber = this.state.customer.delivery.flatNumber;
            // deliveryAddress.floor = this.state.customer.delivery.floor;
            // deliveryAddress.country = this.state.customer.country?this.state.customer.country.label:'';
            deliveryAddress.lang = this.state.customer.lang?this.state.customer.lang.label:'';
        }else{
            if(this.state.customer.companyAddress) {
                deliveryAddress.company = this.state.customer.companyName;
                deliveryAddress.taxId = this.state.customer.taxId;
            }else{
                deliveryAddress.name = this.state.customer.name;
                deliveryAddress.surname = this.state.customer.surname;
            }
            deliveryAddress.zipCode = this.state.customer.zipCode;
            deliveryAddress.city = this.state.customer.city;
            deliveryAddress.address = this.state.customer.address;
            deliveryAddress.address2 = this.state.customer.address2;
            // deliveryAddress.street = this.state.customer.street;
            // deliveryAddress.flatNumber = this.state.customer.flatNumber;
            // deliveryAddress.floor = this.state.customer.floor;
            // deliveryAddress.country = this.state.customer.country?this.state.customer.country.label:'';
            deliveryAddress.lang = this.state.customer.lang?this.state.customer.lang.label:'';
        }

        return (
            <StepCustom label={t('cs_summary._title')}
                        stepNo={this.props.stepNo}
                        currentStep={this.props.currentStep}
                        showStep={step => this.props.showStep(step)}
                  // nextStep={step => this.props.nextStep(step)}
            >

                <div className="orderSummary">
                    <div className="grid -top -fromMdTop">
                        <div/>
                        <CartSummary additionalClass="-summaryStep" cart={this.state.cart}
                                     onChange={(id, value) => null} hideSummary={true}/>
                    </div>
                    <div className="grid -bottom -toMdReverse">
                        <div className="deliverySummary">
                            <h5 className="deliverySummary__title">{t('s_summary.text1')}</h5>
                            <div className="deliverySummary__grid -bottom">
                                {deliveryAddress.company?<div>
                                    <div className="deliverySummary__name">{t('s_customer.company_name')}</div>
                                    <div className="deliverySummary__value">{deliveryAddress.company}</div>
                                </div>:<></>}
                                {deliveryAddress.taxId?<div>
                                    <div className="deliverySummary__name">{t('s_customer.tax_id')}</div>
                                    <div className="deliverySummary__value">{deliveryAddress.taxId}</div>
                                </div>:<></>}
                                {deliveryAddress.name?<div>
                                    <div className="deliverySummary__name">{t('s_customer.name')}</div>
                                    <div className="deliverySummary__value">{deliveryAddress.name}</div>
                                </div>:<></>}
                                {deliveryAddress.surname?<div>
                                    <div className="deliverySummary__name">{t('s_customer.surname')}</div>
                                    <div className="deliverySummary__value">{deliveryAddress.surname}</div>
                                </div>:<></>}
                                <div>
                                    <div className="deliverySummary__name">{t('s_customer.zip_code2')}</div>
                                    <div className="deliverySummary__value">{deliveryAddress.zipCode}</div>
                                </div>
                                <div>
                                    <div className="deliverySummary__name">{t('s_customer.city2')}</div>
                                    <div className="deliverySummary__value">{deliveryAddress.city}</div>
                                </div>
                                <div>
                                    <div className="deliverySummary__name">{t('s_customer.address')}</div>
                                    <div className="deliverySummary__value">{deliveryAddress.address}</div>
                                </div>
                                <div>
                                    <div className="deliverySummary__name">{t('s_customer.address2')}</div>
                                    <div className="deliverySummary__value">{deliveryAddress.address2}</div>
                                </div>
                                {/*<div className="deliverySummary__grid -sm">*/}
                                {/*    <div>*/}
                                {/*        <div className="deliverySummary__name">{t('s_customer.address')}</div>*/}
                                {/*        <div className="deliverySummary__value">{deliveryAddress.flatNumber}</div>*/}
                                {/*    </div>*/}
                                {/*    <div>*/}
                                {/*        <div className="deliverySummary__name">{t('s_customer.floor')}</div>*/}
                                {/*        <div className="deliverySummary__value">{deliveryAddress.floor}</div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div>
                                    <div className="deliverySummary__name">{t('s_customer.country')}</div>
                                    <div className="deliverySummary__value">{deliveryAddress.lang}</div>
                                </div>
                                <div>
                                    <div className="deliverySummary__name">{t('s_customer.phone2')}</div>
                                    <div className="deliverySummary__value">{this.state.customer.phone}</div>
                                </div>
                                <div>
                                    <div className="deliverySummary__name">{t('s_customer.email')}</div>
                                    <div className="deliverySummary__value">{this.state.customer.email}</div>
                                </div>
                            </div>
                        </div>
                        <div className="summary">
                            {/*<div className="summary__grid">*/}
                            {/*    <span className="summary__name">{t('tax')}:</span>*/}
                            {/*    <span className="summary__value">{this.props.currency.prefix}{ (this.state.sum / 5).toFixed(2)}{this.props.currency.postfix}</span>*/}
                            {/*</div>*/}
                            <div className="summary__grid">
                                <span className="summary__name">{t('cs_summary.subtotal')}:</span>
                                <span className="summary__value">{this.getPriceText(this.props.csGear?.corporate_subscription_plan_machine_price)}</span>
                            </div>
                            <div className="summary__grid">
                                <span className="summary__name">{t('cs_summary.machine')}:</span>
                                <span className="summary__value">{this.getPriceText(-Number(this.props.csGear?.corporate_subscription_plan_price))}</span>
                            </div>
                            <div className="summary__grid">
                                <span className="summary__name">{t('cs_summary.cart')}:</span>
                                <span className="summary__value">{this.props.currency.prefix}{this.getCartSum()}{this.props.currency.postfix}</span>
                            </div>
                            <div className="summary__grid">
                                <span className="summary__name">{t('delivery')}:</span>
                                <span className="summary__value">{t('free')}</span>
                            </div>
                            {this.state.cart.discounts.length > 0?<div className="summary__grid -bold">
                                <span className="summary__name">{t('your_benefit')}:</span>
                                <span className="summary__value">{this.props.currency.prefix}{ ((Number(this.props.csGear?.corporate_subscription_plan_machine_price).toFixed(2) ?? 0) - (this.props.csGear?.corporate_subscription_plan_subrebate_price ?? 0) + (this.props.csGear?.corporate_subscription_plan_price ?? 0)).toFixed(2)}{this.props.currency.postfix}</span>
                            </div>:<></>}

                            <div className="summary__grid -total">
                                <span className="summary__name">{t('total')}</span>
                                <div>
                                    <div className="summary__value -price">{this.props.currency.prefix}{this.getTotalSum()}{this.props.currency.postfix}</div>
                                    {/*<div className="summary__value -small">{t('includes')} {this.props.currency.prefix}0,24 {t('ecotax')}</div>*/}
                                </div>
                            </div>
                        </div>

                        <div/>

                        <div className="summary__value -small">
                            <p></p>
                            <div dangerouslySetInnerHTML={{__html: t('s_summary.text2_cs')}}/>
                        </div>
                    </div>




                    <div className="orderSummary__submit">

                        <CartValidationAlert cart={this.state.cart} csGear={this.props.csGear}/>


                        <div className="step__nav">

                            <Button class="-primary -outline" label={t('back')} disabled={false}
                                    onClick={() => this.props.showStep(this.props.stepNo - 1)}/>



                            <Button class="-primary" disabled={!this.state.canFinish || this.state.finished}
                                    onClick={() => this.finalize()}>
                                {this.state.finished ? (
                                    <>
                                        {t('sending')}
                                        <img src={require('../../assets/images/icons/preloader2.gif')} alt="" className="btn__icon -ml"/>
                                    </>
                                ) : t('confirm')}
                            </Button>
                        </div>

                    </div>


                    {/*<div className="summary__value -small">*/}
                    {/*    <p></p>*/}
                    {/*    <div dangerouslySetInnerHTML={{__html: t('cs_summary.text1', `<a href="${t('legal_url')}" target="_blank" class="link -normalSize -primary -underline">${t('cs_summary.text1_1')}</a>`)}}/>*/}
                    {/*</div>*/}



                    {this.state.error ? (
                        <div className="orderSummary__info">
                            <Alert className="-error">{t('error.finalize_failed')} {/*<b><a href={t('send_report_url')} title={t('send_report')}>{t('send_report')}</a></b>*/}</Alert>
                        </div>
                    ) : null}

                </div>

                <OrderSuccessPopup show={this.state.showSuccessPopup}
                                   onClose={() => this.setState({showSuccessPopup: false})}/>
            </StepCustom>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorpSubscriptionSummary);
